import { AppEpic, EpicPayload, RootState } from '@store/configure-store'
import { concat, of } from 'rxjs'
import {
    filter,
    map,
    ignoreElements,
    mergeMap,
    distinctUntilChanged,
    debounceTime,
    switchMap,
    take,
    tap,
} from 'rxjs/operators'
import { store, uiSelectors } from '..'
import { getCropRatio, getCropStatus, getCropRatioKey } from '@features/toolsPanel/store/ui/crop/selectors'
import { onCropPanelClick, onResetEffectInMipl } from '@features/toolsPanel/store/actions'
import { store as toolsStore } from '@features/toolsPanel/store'
import { initialState } from '../store.ui'
import { adoptCropFromToRatio } from '@features/renderer/utilities'
import { CROP_FRAME_PRECISION_MARGIN } from '@features/renderer/constants'
import {
    calculateCenter,
    calculateScale,
    calculateScaledSize,
    getNewImagePositionByScale,
} from '@features/renderer/utilities/image'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import { ToolId } from '@domain/filters'
import { effectType } from '@features/toolsPanel/components/Tools/Crop/constants'
import { onCropChanged, onSubmitByKey } from '../actions'
import { NonNullableInterface } from '@shared/utility/type'

const startOnly$ = of(null)
const noop$ = startOnly$.pipe(take(1), ignoreElements())
const cropID: ToolId = 'MPCropToolFilter'

export const listenToolsStore: AppEpic = (actions$, $state, { mipl }) =>
    $state.pipe(
        filter(() => mipl.initialized && !!mipl.imageId),
        map(getCropStatus),
        distinctUntilChanged(),
        debounceTime(10),
        map(cropActivated => ({
            cropActivated,
            actions: cropActivated ? initCrop($state.value) : resetCropChanges($state.value),
        })),
        switchMap(({ cropActivated, actions }) => {
            const { setCropStatus, setRotation, setSize, setPosition, setFlip, setScale } = store.uiSlice.actions
            const getCrop = () => {
                const { crop, image } = mipl.getCropDescription()
                if (!crop || !image) return []

                const parameterRatioKey = getFormKey(effectType, 'Ratio')

                return [
                    onCropChanged(),
                    toolsStore.toolsState.actions.updateFormState({
                        values: { [parameterRatioKey]: { id: crop.ratio } },
                        meta: { name: parameterRatioKey, id: cropID },
                    }),
                    setRotation({ type: 'image', rotation: image.rotation }),
                    setPosition({ type: 'image', position: image.position }),
                    setScale({ type: 'image', scale: image.scale }),
                    setFlip(image.flip),
                    setCropStatus(cropActivated),
                    setSize({ type: 'crop', size: crop.size }),
                    setPosition({ type: 'crop', position: crop.position }),
                ]
            }

            return concat(
                of(setCropStatus(cropActivated), ...actions),
                !cropActivated && mipl.isCropApplied()
                    ? startOnly$.pipe(
                          map(() => mipl.getCropDescription()),
                          filter(
                              (payload): payload is NonNullableInterface<typeof payload> =>
                                  !!payload.crop && !!payload.image,
                          ),
                          mergeMap(payload => mipl.applyCrop(payload)),
                          switchMap(() => actions$.pipe(filter(store.uiSlice.actions.reset.match))),
                          debounceTime(0),
                          mergeMap(() => [
                              setCropStatus(false),
                              setRotation({ type: 'image', rotation: 0 }),
                              setFlip({ x: 1, y: 1 }),
                          ]),
                      )
                    : noop$,
                cropActivated && mipl.isCropApplied()
                    ? startOnly$.pipe(
                          mergeMap(() => mipl.fetchUncroppedImage()),
                          switchMap(() => actions$.pipe(filter(store.uiSlice.actions.reset.match))),
                          debounceTime(0),
                          mergeMap(getCrop),
                      )
                    : noop$,
            )
        }),
    )

export const listenCropActions: AppEpic = (actions$, state$, services) => {
    let cropKey: string
    return actions$.pipe(
        filter(onCropPanelClick.match),
        map(status => status.payload.element),
        switchMap(element => {
            const { setRotation, setFlip } = store.uiSlice.actions
            const imageFlip = uiSelectors.getImageFlip(state$.value)
            const payload: EpicPayload = { actions$, state$, services }

            switch (element) {
                case 'rotate':
                    getCropRatioKey(state$.value) !== 'transposed' && (cropKey = getCropRatioKey(state$.value))
                    return rotateWithStep(payload, cropKey)
                case 'halignment':
                    return of(setRotation({ type: 'image', rotation: initialState.image.rotation }))
                case 'hflip':
                    return of(setFlip({ ...imageFlip, x: -imageFlip.x }))
                case 'vflip':
                    return of(setFlip({ ...imageFlip, y: -imageFlip.y }))
                case 'submit':
                    return applyCrop({ actions$, state$, services })
                default:
                    return of(null).pipe(ignoreElements())
            }
        }),
    )
}

export const listenCropReset: AppEpic = (actions$, state$, { mipl }) =>
    actions$.pipe(
        filter(onResetEffectInMipl.match),
        filter(({ payload }) => payload === 'MPCropToolFilter'),
        mergeMap(() => of(mipl.isCropApplied() && mipl.resetCrop())),
        mergeMap(() => resetCropChanges(state$.value)),
    )

export const listenCropSubmitByKeyPress: AppEpic = (actions$, state$, services) =>
    actions$.pipe(
        filter(onSubmitByKey.match),
        filter(() => getCropStatus(state$.value)),
        switchMap(() => applyCrop({ state$, actions$, services })),
    )

const rotateWithStep = ({ state$ }: EpicPayload, previousCrop = 'original') => {
    const { setRotation, setScale, setPosition } = store.uiSlice.actions
    const currentImageRotation = uiSelectors.getImageRotation(state$.value)
    const imageOriginSize = uiSelectors.getImageOriginSize(state$.value)
    const imagePosition = uiSelectors.getImagePosition(state$.value)
    const imageOldScale = uiSelectors.getImageScale(state$.value)
    const containerSize = uiSelectors.getContainerSize(state$.value)
    const newRotation = currentImageRotation - 90
    const shouldBeScaled = (Math.abs(newRotation) % 180) / 90 === 1
    const newScale = calculateScale(
        shouldBeScaled ? { height: imageOriginSize.width, width: imageOriginSize.height } : imageOriginSize,
        containerSize,
    )
    const newPosition = getNewImagePositionByScale({
        originSize: imageOriginSize,
        currentPosition: imagePosition,
        newScale,
        oldScale: imageOldScale,
    })
    const parameterRatioKey = getFormKey(effectType, 'Ratio')

    return [
        setRotation({ type: 'image', rotation: newRotation, keepCropUnchanged: false }),
        setScale({
            type: 'image',
            scale: newScale,
        }),
        setPosition({
            type: 'image',
            position: newPosition,
        }),
        toolsStore.toolsState.actions.updateFormState({
            values: { [parameterRatioKey]: { id: shouldBeScaled ? 'transposed' : previousCrop } },
            meta: { name: parameterRatioKey, id: cropID },
        }),
    ]
}

const applyCrop = ({ state$, services: { mipl } }: EpicPayload) => {
    const getCropPayload = (state: RootState) => ({
        crop: {
            size: uiSelectors.getCropSize(state),
            position: uiSelectors.getCropPosition(state),
            ratio: getCropRatioKey(state),
        },
        image: {
            position: uiSelectors.getImagePosition(state),
            rotation: uiSelectors.getImageRotation(state),
            offset: uiSelectors.getImageOffset(state),
            scale: uiSelectors.getImageScale(state),
            flip: uiSelectors.getImageFlip(state),
        },
    })

    return of(mipl.applyCrop(getCropPayload(state$.value))).pipe(
        mergeMap(() => {
            const { setRotation } = store.uiSlice.actions
            const wrapperKey = getFormKey(cropID, 'wrapper')

            return [
                toolsStore.toolsState.actions.updateFormState({
                    values: { [wrapperKey]: false },
                    meta: { name: wrapperKey, id: cropID },
                }),
                setRotation({ type: 'image', rotation: 0 }),
            ]
        }),
    )
}

const resetCropChanges = (state: RootState) => {
    const { setPosition, setRotation, setFlip, setScale, setRotateStatus } = store.uiSlice.actions
    const { scale, position } = getDefaultImageDimensions(state)

    return [
        setRotation({ type: 'image', rotation: initialState.image.rotation }),
        setScale({ type: 'image', scale }),
        setPosition({ type: 'image', position }),
        setFlip(initialState.image.flip),
        setRotateStatus(initialState.image.rotating),
    ]
}

const initCrop = (state: RootState) => {
    const { setPosition, setSize, setRotation, setScale } = store.uiSlice.actions
    const { scale, imageSize, position } = getDefaultImageDimensions(state)

    const cropSize = adoptCropFromToRatio(
        {
            image: imageSize,
            ratio: getCropRatio(state),
        },
        CROP_FRAME_PRECISION_MARGIN,
    )

    const cropCenter = {
        x: (cropSize.width + CROP_FRAME_PRECISION_MARGIN) / 2,
        y: (cropSize.height + CROP_FRAME_PRECISION_MARGIN) / 2,
    }
    return [
        setRotation({ type: 'image', rotation: initialState.image.rotation }),
        setScale({ type: 'image', scale }),
        setPosition({ type: 'image', position }),
        setSize({ type: 'crop', size: cropSize }),
        setPosition({ type: 'crop', position: cropCenter }),
    ]
}

const getDefaultImageDimensions = (state: RootState) => {
    const imageOriginSize = uiSelectors.getImageOriginSize(state)
    const imageScale = uiSelectors.getDefaultImageScale(state)
    const newImageSize = calculateScaledSize(imageOriginSize, imageScale)

    return {
        scale: imageScale,
        imageSize: newImageSize,
        position: calculateCenter(newImageSize),
    }
}
