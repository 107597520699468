import { Size2D } from '@shared/types'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'
import { get } from 'lodash-es'
import { effectType } from '@features/toolsPanel/components/Tools/Crop/constants'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'

const selectSelf = (state: RootState) => state.toolsState
const selectRatio = (state: RootState) => state.cropState

export const getRatioOptions = createSelector(selectRatio, state => state.options)
export const getRatioOptionByKey = createSelector(
    [getRatioOptions, (_, key: string) => key],
    (options, key) => options[key] ?? null,
)
export const getCropEntity = createSelector(selectSelf, state => state.MPCropToolFilter)
export const getCropStatus = createSelector(
    getCropEntity,
    (entity): boolean => !!entity?.[getFormKey('MPCropToolFilter', 'wrapper')],
)
export const getCropRatioKey = createSelector(getCropEntity, (entity): string =>
    get(entity, [getFormKey(effectType, 'Ratio'), 'id']),
)
export const getCropRatio = createSelector(
    getCropRatioKey,
    getRatioOptions,
    (key, options): Size2D => get(options, key, { width: 0, height: 0 }),
)
