import { AppEpic } from '@store/configure-store'
import { of, merge, zip, concat, timer } from 'rxjs'
import {
    filter,
    map,
    switchMap,
    catchError,
    mergeMap,
    distinctUntilChanged,
    concatMap,
    take,
    debounceTime,
    ignoreElements,
} from 'rxjs/operators'
import { actions as rendererActions } from '@features/renderer/store'
import { toolsState } from '@features/toolsPanel/store/ui.store'
import { store, actions } from '@features/toolsPanel/store'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import { ToolId } from '@domain/filters'
import { appInit } from '@store/actions'
import { getToolById, getToolsState, isToolOpen } from '../ui.selectors'
import { cloneDeep, isEmpty as _isEmpty, isEqual as _isEqual, omit as _omit } from 'lodash-es'
import { colorPickerState } from '@features/toolsPanel/store/ui/colorPicker/store'
import { mappedTemperature, processingTint } from '@features/toolsPanel/utils/miplToTools'
import { NonNullableInterface } from '@shared/utility/type'
import { onResetEffectForm } from '../actions'
import { getWhiteBalancePayload } from '@features/renderer/store/actions.mipl'
import { WhiteBalanceOptions } from '@shared/constants/options'
import { AnyAction } from '@reduxjs/toolkit'
import { FieldValues } from 'react-hook-form'

export const toolsPanelInit: AppEpic = (actions$, _, { apiAdapter }) =>
    actions$.pipe(
        filter(appInit.match),
        take(1),
        switchMap(() => zip([apiAdapter.fetchPanel(), apiAdapter.fetchToolsLayout()])),
        mergeMap(([panel, effects]) =>
            of(
                actions.fetchToolsPanel.act(),
                store.Panel.actions.setToolsPanel(panel),
                store.Effects.actions.setEffectsInfo(effects),
            ),
        ),
        catchError((err: Error) => of(actions.fetchToolsPanel.rejected(err))),
    )

export const texturesInit: AppEpic = (actions$, _, { apiAdapter }) =>
    actions$.pipe(
        filter(actions.fetchToolsPanel.act.match),
        switchMap(() => apiAdapter.fetchTextures()),
        map(sky => actions.fetchToolsPanel.fulfilled({ texture: { sky } })),
        catchError((err: Error, caught) => merge(of(actions.fetchToolsPanel.rejected(err)), caught)),
    )

export const toolsOpen: AppEpic = actions$ =>
    actions$.pipe(
        filter(rendererActions.uploadImage.fulfilled.match),
        debounceTime(0),
        switchMap(() => {
            const { updateFormState } = toolsState.actions
            const toolId: ToolId = 'AIEnhanceAdjustmentLayer'
            const name = getFormKey(toolId, 'wrapper')

            return [
                updateFormState({
                    values: { [name]: true },
                    meta: { name, id: toolId },
                }),
            ]
        }),
    )

type ReturnedType = ReturnType<typeof toolsState.actions.updateFormState>
export const makeAnAccordionEpic: AppEpic = (actions$, state$) => {
    const { updateFormState } = toolsState.actions
    const isOpenedTool = ({ payload }: ReturnedType): boolean => {
        const { meta, values } = payload
        const id = meta.id
        const wrapperKey = getFormKey(id, 'wrapper')

        return !!(id && meta.name === wrapperKey && values?.[wrapperKey] === true)
    }

    const getAlreadyOpenedTools = ({ payload }: ReturnedType) => {
        const { meta } = payload
        const id = meta.id as ToolId
        const tools = _omit(getToolsState(state$.value), [id])
        return Object.keys(tools).reduce<AnyAction[]>((acc, _key) => {
            const key = _key as unknown as ToolId
            const wrapperKey = getFormKey(key, 'wrapper')
            const isOpen = (tools as any)[key]?.[wrapperKey] as boolean
            const createPayload = () => [onResetEffectForm(key)]

            return !!isOpen ? [...acc, ...createPayload()] : acc
        }, [])
    }

    return actions$.pipe(
        filter(updateFormState.match),
        distinctUntilChanged<ReturnedType>(({ payload: prevPayload }, { payload: nextPayload }) =>
            _isEqual(prevPayload.values, nextPayload.values),
        ),
        filter(isOpenedTool),
        map(getAlreadyOpenedTools),
        filter(openedTools => openedTools.length > 0),
        concatMap(openedTools => openedTools),
    )
}

export const listenWhiteBalance: AppEpic = (actions$, state$) => {
    const toolId: ToolId = 'ExtendedColorAdjustmentLayer'
    const miplId = 'MIPLDevelopCommonEffectID'
    const wrapperParameter = getFormKey(toolId, 'wrapper')
    const temperatureParameter = getFormKey(miplId, 'Temperature')
    const tintParameter = getFormKey(miplId, 'Tint')
    const whiteBalanceParameter = getFormKey(miplId, 'whiteBalance')

    const listenToWhiteBalanceChanges$ = actions$.pipe(
        filter(toolsState.actions.updateFormState.match),
        filter(
            ({ payload: { meta, values } }) =>
                meta.name === whiteBalanceParameter &&
                !!values &&
                values[whiteBalanceParameter] === WhiteBalanceOptions[0].id,
        ),
        filter(() => isToolOpen(toolId)(state$.value)),
        map(() => getToolById(state$.value, toolId)),
        filter((toolState): toolState is FieldValues => !!toolState),
        map(toolState => {
            const remainedParameters = _omit(cloneDeep(toolState), [
                wrapperParameter,
                temperatureParameter,
                tintParameter,
                whiteBalanceParameter,
            ])

            return _isEmpty(remainedParameters)
        }),
        switchMap(resetTool =>
            resetTool
                ? concat(
                      of(onResetEffectForm(toolId)),
                      timer(100).pipe(
                          map(() =>
                              toolsState.actions.updateFormState({
                                  values: { [wrapperParameter]: true },
                                  meta: { name: wrapperParameter, id: toolId },
                              }),
                          ),
                      ),
                  )
                : [
                      toolsState.actions.updateFormState({
                          values: { [temperatureParameter]: 0 },
                          meta: { name: temperatureParameter, id: toolId },
                      }),
                      toolsState.actions.updateFormState({
                          values: { [tintParameter]: 0 },
                          meta: { name: tintParameter, id: toolId },
                      }),
                  ],
        ),
    )

    const listenTintAndTemperatureChanges$ = actions$.pipe(
        filter(toolsState.actions.updateFormState.match),
        filter(() => isToolOpen(toolId)(state$.value)),
        filter(
            ({ payload: { meta, values } }) =>
                !!values &&
                ((meta.name === temperatureParameter &&
                    Number.isInteger(values[temperatureParameter]) &&
                    values[temperatureParameter] !== 0) ||
                    (meta.name === tintParameter &&
                        Number.isInteger(values[tintParameter]) &&
                        values[tintParameter] !== 0)),
        ),
        map(() =>
            toolsState.actions.updateFormState({
                values: { [whiteBalanceParameter]: WhiteBalanceOptions[1].id },
                meta: { name: whiteBalanceParameter, id: toolId },
            }),
        ),
    )

    return merge(listenToWhiteBalanceChanges$, listenTintAndTemperatureChanges$)
}

export const addTool: AppEpic = (actions$, state$, { mipl, miplChannels }) =>
    actions$.pipe(
        filter(colorPickerState.actions.setColorPickerCoordinate.match),
        filter((payload): payload is NonNullableInterface<typeof payload> => !!payload.payload),
        switchMap(({ payload }) =>
            zip(
                miplChannels.miplChannel.pipe(filter(getWhiteBalancePayload.match)),
                mipl.getWhiteBalanceForPickedPoint(payload),
            ).pipe(take(1)),
        ),
        concatMap(([{ payload }]) => {
            const { temperature, tint } = payload
            const toolId: ToolId = 'ExtendedColorAdjustmentLayer'
            const { updateFormState } = toolsState.actions
            const nameTemp = getFormKey('MIPLDevelopCommonEffectID', 'Temperature')
            const nameTint = getFormKey('MIPLDevelopCommonEffectID', 'Tint')

            return [
                updateFormState({
                    values: { [nameTint]: processingTint(tint) },
                    meta: { name: nameTint, id: toolId },
                }),
                updateFormState({
                    values: { [nameTemp]: mappedTemperature(temperature) },
                    meta: { name: nameTemp, id: toolId },
                }),
            ]
        }),
    )

export const onSwitchTab: AppEpic = (actions$, state$, { analytics }) =>
    merge(
        actions$.pipe(
            filter(store.panelUISlice.actions.switchTab.match),
            filter(data => data.payload === 'edits'),
            concatMap(() => {
                const tools = getToolsState(state$.value)
                return Object.keys(tools).reduce<AnyAction[]>((acc, _key) => {
                    const key = _key as unknown as ToolId
                    const wrapperKey = getFormKey(key, 'wrapper')
                    const isOpen = (tools as any)[key]?.[wrapperKey] as boolean
                    const createPayload = () => [onResetEffectForm(key)]

                    return !!isOpen ? [...acc, ...createPayload()] : acc
                }, [])
            }),
        ),
        actions$.pipe(
            filter(store.panelUISlice.actions.switchTab.match),
            filter(data => data.payload === 'edits'),
            switchMap(() => analytics.editsUsage('click')),
            ignoreElements(),
        ),
    )
