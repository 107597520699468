import { selectors as authSelectors } from '@features/auth/store'
import { AppEpic, RootState } from '@store/configure-store'
import { exhaustMap, filter, map, mergeMap, switchMap, tap, concatMap } from 'rxjs/operators'
import { handleExport } from './actions'
import { store as authStore } from '@features/auth/store'
import { store as exportStore } from '@features/export/store'
import { dataToImage } from '@features/renderer/utilities'
import { selectors } from '@features/renderer/store'
import { AnyAction, CombinedState } from '@reduxjs/toolkit'
import { getExportType } from './selectors'
import { initiateDownloadImage } from '../utilities'
import { uploadPopupStatus } from '@features/upload/store/selectors'

export const runExportEpic: AppEpic = (actions$, state$, { mipl, analytics }) =>
    actions$.pipe(
        filter(handleExport.match),
        map(() => requiredSelector(state$.value)),
        filter(({ logged, isVerified }) => logged && isVerified),
        exhaustMap(() => mipl.generateDataForExport()),
        switchMap(imageData => dataToImage(imageData as ImageData)),
        filter(
            (data): data is { element: HTMLCanvasElement } =>
                !!data.element && data.element instanceof HTMLCanvasElement,
        ),
        tap(({ element }) => {
            const canvas = element
            const name = selectors.getCurrentImageName(state$.value)
            const exportType = getExportType(state$.value) ?? undefined

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                const isUploadPopupOpen = uploadPopupStatus(state$.value)
                if (exportType) {
                    if (isUploadPopupOpen) {
                        analytics.exportImageFromPopupUsage(exportType)
                    } else {
                        analytics.exportImageUsage(exportType)
                    }
                }
            }

            return initiateDownloadImage(canvas, { name, type: exportType })
        }),
        concatMap(() => {
            const { toggleStatus, setDesiredType, setLoading } = exportStore.slice.actions
            return [toggleStatus('idle'), setDesiredType(null), setLoading(false)]
        }),
    )

export const authGuardEpic: AppEpic = (actions$, state$, { analytics }) =>
    actions$.pipe(
        filter(handleExport.match),
        map(() => requiredSelector(state$.value)),
        mergeMap(({ logged, isVerified }) => {
            const { toggleStatus } = exportStore.slice.actions
            const { setOpen, changeAuth } = authStore.authUIState.actions
            const initAuthActions: AnyAction[] = [toggleStatus('pending'), setOpen(true)]

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                const exportType = getExportType(state$.value) ?? undefined
                if (exportType && !(logged && isVerified)) {
                    analytics.exportImageSignUpUsage(exportType)
                }
            }

            return logged
                ? isVerified
                    ? []
                    : [...initAuthActions, changeAuth('verification')]
                : [...initAuthActions, changeAuth('registrationChoose')]
        }),
    )

const requiredSelector = (state: CombinedState<RootState>) => ({
    logged: authSelectors.isLogged(state),
    isVerified: authSelectors.isUserVerified(state),
})
