import { Size2D } from '@shared/types'

export const formRatioKey = (width: number | string, height: number | string): string => `ratio${width}x${height}`

export const extractSizeFromKey = (ratio: string): Size2D | null => {
    const result = ratio.replace(/ratio/, '').replace('x', ' ').split(' ')
    if (result.length === 2) {
        return {
            width: Number(result[0]),
            height: Number(result[1]),
        }
    }

    return null
}
export const transposeRatioKey = (ratio: string): string => {
    const result = extractSizeFromKey(ratio)
    if (!!result) {
        return formRatioKey(result.height, result.width)
    }
    return ratio
}
