import type * as Analytics from '@shared/services'
import { uniqueId } from 'lodash-es'
import { CropPayload } from './store/actions'
import { ScaleIds as ScaleId } from './types'
import { formRatioKey } from './utils/formRatioKey'

export const OptionTypes = {
    original: { width: 0, height: 0 },
    free: { width: 0, height: 0 },
    transposed: { width: 0, height: 0 },
    custom: { width: 0, height: 0 },
    // static ratios
    square: { width: 1, height: 1 },
    facebookCover: { width: 205, height: 78 },
    facebookFeed: { width: 236, height: 197 },
    ratio2x3: { width: 2, height: 3 },
    ratio3x4: { width: 3, height: 4 },
    ratio4x5: { width: 4, height: 5 },
    ratio13x12: { width: 13, height: 12 },
    ratio5x7: { width: 5, height: 7 },
    'ratio8.5x11': { width: 8.5, height: 11 },
    ratio16x9: { width: 16, height: 9 },
    ratio16x10: { width: 16, height: 10 },
    ratio21x9: { width: 21, height: 9 },
}

export const CropAnalytics: Record<Exclude<CropPayload['element'], 'halignment'>, Analytics.Crop> &
    Record<'halignment', null> = {
    hflip: 'flip_horisontal',
    rotate: 'rotate',
    vflip: 'flip_vertical',
    submit: 'apply',
    halignment: null,
}

export const ScaleOptionsList: Record<ScaleId, { title: string }> = {
    '0': { title: 'Fit to screen' },
    '25': { title: '25%' },
    '50': { title: '50%' },
    '100': { title: '100%' },
    '200': { title: '200%' },
    '300': { title: '300%' },
    '600': { title: '600%' },
    '1200': { title: '1200%' },
    '2400': { title: '2400%' },
    '3000': { title: '3000%' },
}
export const ScaleOptions = Object.keys(ScaleOptionsList).map(id => ({
    id: Number(id) as ScaleId,
    ...ScaleOptionsList[id as unknown as keyof typeof ScaleOptionsList],
}))

export const RatioOptions = [
    {
        id: 'free',
        title: 'Free',
    },
    {
        id: uniqueId(),
        divider: true,
    },
    {
        id: 'original',
        title: 'Original',
    },
    {
        id: 'transposed',
        title: 'Transposed',
    },
    {
        id: uniqueId(),
        divider: true,
    },
    {
        id: 'square',
        title: '1:1 (Square)',
    },
    {
        id: uniqueId(),
        divider: true,
    },
    {
        id: formRatioKey(4, 5),
        title: '4:5',
    },
    {
        id: formRatioKey(8.5, 11),
        title: '8.5:11',
    },
    {
        id: formRatioKey(5, 7),
        title: '5:7',
    },
    {
        id: formRatioKey(2, 3),
        title: '2:3',
    },
    {
        id: uniqueId(),
        divider: true,
    },
    {
        id: formRatioKey(3, 4),
        title: '3:4',
    },
    {
        id: formRatioKey(21, 9),
        title: '21:9',
    },
    {
        id: formRatioKey(16, 9),
        title: '16:9',
    },
    {
        id: formRatioKey(16, 10),
        title: '16:10',
    },
    {
        id: uniqueId(),
        divider: true,
    },
    {
        id: 'facebookFeed',
        title: 'Facebook Feed',
    },
    {
        id: 'facebookCover',
        title: 'Facebook Cover',
    },
    {
        id: uniqueId(),
        divider: true,
    },
    {
        id: 'custom',
        title: 'Enter Custom',
    },
]
