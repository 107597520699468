import { CropPayload } from '@features/toolsPanel/store/actions'
import { EffectsLayoutInner } from '@shared/server/dto'

export const effectType = 'WEBCropEffect'
const parameterType = 'parameter'

export const Layout: EffectsLayoutInner[] = [
    {
        id: 'cropId',
        title: undefined,
        expandable: false,
        items: [
            [
                {
                    type: parameterType,
                    effect: effectType,
                    parameter: 'Ratio',
                },
                // {
                //     type: parameterType,
                //     effect: effectType,
                //     parameter: 'Composition',
                // },
            ],
            // [
            //     {
            //         type: parameterType,
            //         effect: effectType,
            //         parameter: 'Horizon alignment',
            //     },
            // ],
            [
                {
                    type: parameterType,
                    effect: effectType,
                    parameter: 'RotateFlip',
                },
            ],
            [
                {
                    type: parameterType,
                    effect: effectType,
                    parameter: 'Submit',
                },
            ],
        ],
    },
]

export const EffectInfo: { [effectType]: { [key: string]: EffectInfoInner } } = {
    [effectType]: {
        Ratio: {
            name: 'Ratio',
            type: 'select',
        },
        Composition: {
            name: 'Composition <sup>AI</sup>',
            type: 'button',
            innerLabel: true,
        },
        'Horizon alignment': {
            name: 'Horizon Alignment',
            type: 'button',
            iconName: 'alignment',
        },
        RotateFlip: {
            name: 'Rotate & Flip',
            type: 'buttongroup',
        },
        Submit: {
            name: 'Apply',
            type: 'button',
            innerLabel: true,
        },
    },
}

export type EffectInfoInner = {
    name?: string
    type: string
    iconName?: string
    innerLabel?: boolean
}

export const PayloadMapping: Record<string, CropPayload['element']> = {
    'Horizon Alignment': 'halignment',
    rotate: 'rotate',
    hflip: 'hflip',
    vflip: 'vflip',
    Apply: 'submit',
}
