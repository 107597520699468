import { createSelector } from '@reduxjs/toolkit'
import { editsAdapter } from './store'
import { RootState } from '@store/configure-store'

const storeSelect = (state: RootState) => state.panelEdits
const selfSelectors = editsAdapter.getSelectors(storeSelect)

export const getCount = selfSelectors.selectTotal
export const getEdits = selfSelectors.selectAll
export const getEditIds = createSelector(selfSelectors.selectIds, ids => ids as string[])
export const getCurrentId = createSelector(storeSelect, state => state.current)
export const getEditById = selfSelectors.selectById

export const isEditsExists = createSelector(getCount, total => total > 0)
export const getCurrentEdit = createSelector([getCurrentId, selfSelectors.selectEntities], (id, entities) =>
    id ? entities[id] : undefined,
)
