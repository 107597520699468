import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
    showLoaderLuminar: boolean
    timeAnimationLoaderLuminarProd: number
    timeAnimationLoaderLuminarDev: number
}
const initialState: State = {
    showLoaderLuminar: true,
    timeAnimationLoaderLuminarProd: 0.7,
    timeAnimationLoaderLuminarDev: 0.3,
}

export const store = createSlice({
    name: 'loaderLuminar',
    initialState,
    reducers: {
        hideLoaderLuminar(state, { payload }: PayloadAction<boolean>) {
            state.showLoaderLuminar = payload
        },
        setTimeAnimationLoaderLuminarProd(state, { payload }: PayloadAction<number>) {
            state.timeAnimationLoaderLuminarProd = payload
        },
        setTimeAnimationLoaderLuminarDev(state, { payload }: PayloadAction<number>) {
            state.timeAnimationLoaderLuminarDev = payload
        },
    },
})
