import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'

const getSelf = (state: RootState) => state.uploadStore
const getNotif = (state: RootState) => state.notifications

export const uploadPopupStatus = createSelector(getSelf, state => state.openDialog)
export const getPopupType = createSelector(getSelf, state => state.type)

export const isNotificationOpen = createSelector(getNotif, state => state.open)
export const getNotificationType = createSelector(getNotif, state => state.type)
