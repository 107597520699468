import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStates } from '@shared/utility/redux'
import { NonNullableInterface } from '@shared/utility/type'
import { omit } from 'lodash-es'
import { AuthType } from '../types'
import {
    login,
    fetchUser,
    register,
    logout,
    checkEmail,
    confirmEmail,
    resendCode,
    resetPassword,
    resetCode,
    updatePassword,
} from './actions'
import { Social } from '@shared/services'

type ActionTypes =
    | 'login'
    | 'fetchUser'
    | 'register'
    | 'logout'
    | 'checkEmail'
    | 'confirmEmail'
    | 'resendCode'
    | 'resetPassword'
    | 'resetCode'
    | 'updatePassword'

type PartialState = {
    user: Record<string, unknown> | null
    loading: Partial<Record<ActionTypes, FetchStates>>
    error: Partial<Record<ActionTypes, unknown>>
    socOrEmailType: Social
}

const initialState: PartialState = {
    user: null,
    loading: {},
    error: {},
    socOrEmailType: 'email',
}

export const authState = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset() {
            return initialState
        },
        resetActions(state, { payload }: PayloadAction<ActionTypes | ActionTypes[]>) {
            const cleanState = (type: ActionTypes) => {
                state.loading[type] = 'idle'
                omit(state.error, type)

                return state
            }

            Array.isArray(payload) ? payload.map(cleanState) : cleanState(payload)
        },
        changeSocOrEmailType(state, { payload }: PayloadAction<Social>) {
            state.socOrEmailType = payload
        },
    },
    extraReducers: builder => {
        builder.addCase(login.act, state => {
            state.loading['login'] = 'pending'
            state.error = omit(state.error, 'login')
        })
        builder.addCase(login.fulfilled, state => {
            state.loading['login'] = 'succeeded'
            state.error = omit(state.error, 'login')
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading['login'] = 'failed'
            state.user = null
            state.error['login'] = action.payload
        })

        builder.addCase(register.act, state => {
            state.loading['register'] = 'pending'
            state.error = omit(state.error, 'register')
        })
        builder.addCase(register.fulfilled, state => {
            state.loading['register'] = 'succeeded'
            state.error = omit(state.error, 'register')
        })
        builder.addCase(register.rejected, (state, action) => {
            state.loading['register'] = 'failed'
            state.user = null
            state.error['register'] = action.payload
        })

        builder.addCase(checkEmail.act, state => {
            state.loading['checkEmail'] = 'pending'
            state.error = omit(state.error, 'checkEmail')
        })
        builder.addCase(checkEmail.fulfilled, state => {
            state.loading['checkEmail'] = 'succeeded'
            state.error = omit(state.error, 'checkEmail')
        })
        builder.addCase(checkEmail.rejected, (state, action) => {
            state.loading['checkEmail'] = 'failed'
            state.error['checkEmail'] = action.payload
        })

        builder.addCase(logout.act, state => {
            state.loading['logout'] = 'pending'
            state.error = omit(state.error, 'logout')
        })
        builder.addCase(logout.fulfilled, state => {
            state.loading['logout'] = 'succeeded'
            state.user = null
            state.error = omit(state.error, 'logout')
        })
        builder.addCase(logout.rejected, (state, action) => {
            state.loading['logout'] = 'failed'
            state.error['logout'] = action.payload
        })

        builder.addCase(fetchUser.act, state => {
            state.loading['fetchUser'] = 'pending'
            state.error = omit(state.error, 'fetchUser')
        })
        builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
            state.loading['fetchUser'] = 'succeeded'
            state.error = omit(state.error, 'fetchUser')
            state.user = payload
        })
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading['fetchUser'] = 'failed'
            state.user = null
            state.error['fetchUser'] = action.payload
        })

        builder.addCase(confirmEmail.act, state => {
            state.loading['confirmEmail'] = 'pending'
            state.error = omit(state.error, 'confirmEmail')
        })
        builder.addCase(confirmEmail.fulfilled, state => {
            state.loading['confirmEmail'] = 'succeeded'
            state.error = omit(state.error, 'confirmEmail')
        })
        builder.addCase(confirmEmail.rejected, (state, action) => {
            state.loading['confirmEmail'] = 'failed'
            state.error['confirmEmail'] = action.payload
        })

        builder.addCase(resendCode.act, state => {
            state.loading['resendCode'] = 'pending'
            state.error = omit(state.error, 'resendCode')
        })
        builder.addCase(resendCode.fulfilled, state => {
            state.loading['resendCode'] = 'succeeded'
            state.error = omit(state.error, 'resendCode')
        })
        builder.addCase(resendCode.rejected, (state, action) => {
            state.loading['resendCode'] = 'failed'
            state.error['resendCode'] = action.payload
        })

        builder.addCase(resetPassword.act, state => {
            state.loading['resetPassword'] = 'pending'
            state.error = omit(state.error, 'resetPassword')
        })
        builder.addCase(resetPassword.fulfilled, state => {
            state.loading['resetPassword'] = 'succeeded'
            state.error = omit(state.error, 'resetPassword')
        })
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.loading['resetPassword'] = 'failed'
            state.error['resetPassword'] = action.payload
        })

        builder.addCase(resetCode.act, state => {
            state.loading['resetCode'] = 'pending'
            state.error = omit(state.error, 'resetCode')
        })
        builder.addCase(resetCode.fulfilled, state => {
            state.loading['resetCode'] = 'succeeded'
            state.error = omit(state.error, 'resetCode')
        })
        builder.addCase(resetCode.rejected, (state, action) => {
            state.loading['resetCode'] = 'failed'
            state.error['resetCode'] = action.payload
        })

        builder.addCase(updatePassword.act, state => {
            state.loading['updatePassword'] = 'pending'
            state.error = omit(state.error, 'updatePassword')
        })
        builder.addCase(updatePassword.fulfilled, state => {
            state.loading['updatePassword'] = 'succeeded'
            state.error = omit(state.error, 'updatePassword')
        })
        builder.addCase(updatePassword.rejected, (state, action) => {
            state.loading['updatePassword'] = 'failed'
            state.error['updatePassword'] = action.payload
        })
    },
})

type UIState = {
    open: boolean
    type: AuthType
    startSocProcess: boolean
}

const UIInitialState: UIState = {
    open: false,
    type: 'login',
    startSocProcess: false,
}

export const authUIState = createSlice({
    name: 'authUI',
    initialState: UIInitialState,
    reducers: {
        setOpen(state, { payload }: PayloadAction<boolean | undefined>) {
            state.open = typeof payload === 'undefined' ? !state.open : payload
        },
        changeAuth(state, { payload }: PayloadAction<AuthType>) {
            state.type = payload
        },
        setStartSocProcess(state, { payload }: PayloadAction<boolean>) {
            state.startSocProcess = payload
        },
        reset() {
            return UIInitialState
        },
    },
})

type ForgotPasswordState = {
    email: string | null
    pin: string | null
}

const ForgotPasswordState: ForgotPasswordState = {
    email: null,
    pin: null,
}

export const forgotPassword = createSlice({
    name: 'forgotPassword',
    initialState: ForgotPasswordState,
    reducers: {
        setInfo(state, { payload }: PayloadAction<Partial<NonNullableInterface<ForgotPasswordState>>>) {
            state.email = payload.email ? payload.email : state.email
            state.pin = payload.pin ? payload.pin : state.pin
        },
        reset() {
            return ForgotPasswordState
        },
    },
})
