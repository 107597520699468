import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Edits as EditsType, Edit } from '@shared/types'

type State = {
    current: string | null
}

export const editsAdapter = createEntityAdapter<Edit>({
    selectId: data => data.identifier,
    sortComparer: () => -1,
})

const initialState: State = {
    current: null,
}

export const Edits = createSlice({
    name: 'panelEdits',
    initialState: editsAdapter.getInitialState(initialState),
    reducers: {
        setEdits(state, { payload }: PayloadAction<EditsType>) {
            editsAdapter.setAll(state, payload)
        },
        setCurrent(state, { payload }: PayloadAction<string | null>) {
            state.current = payload
        },
    },
})
