export const WhiteBalanceOptions = [
    {
        id: 0,
        title: 'As Shot',
    },
    {
        id: 1,
        title: 'Custom',
        hidden: true,
    },
]
