import { Image } from '@domain/image'
import { createAction } from '@reduxjs/toolkit'
import { Vector2D } from '@shared/types'

const fetch_string = 'renderer/upload/image'

export const uploadImage = {
    act: createAction<File[]>(`${fetch_string}/pending`),
    fulfilled: createAction<Image>(`${fetch_string}/fulfilled`),
    rejected: createAction<string>(`${fetch_string}/rejected`),
}

export const onCropChanged = createAction('renderer/crop/changed')

export const onZoomByHand = createAction<{ position: Vector2D; scale: number }>('renderer/zoom/hand')

export const onSubmitByKey = createAction('renderer/submit/by/key')

export const keepCropInCenter = createAction('renderer/center/crop')
