import { Size2D, Vector2D } from '@shared/types'
import { cloneDeep } from 'lodash'
import { ParametersMap } from './MiplAdapter'

export type CropRect = { size: Size2D; position: Vector2D; ratio: string }
export type ImageRect = { position: Vector2D; offset: Vector2D; rotation: number; scale: number; flip: Vector2D }
type InitialState = {
    currentEdit: string | null
    currentImage: VectorUint8 | null
    currentLayer: VectorUint8 | null
    edits: Record<string, VectorUint8>
    parameters: Record<string, ParametersMap>
    ratio: string | null
    crop: CropRect | null
    image: ImageRect | null
}

const inititalState: InitialState = {
    currentImage: null,
    currentEdit: null,
    currentLayer: null,
    edits: {},
    parameters: {},
    ratio: null,
    crop: null,
    image: null,
}
export class InternalStore {
    private store: InitialState = cloneDeep(inititalState)

    setImage(id: VectorUint8) {
        this.store.currentImage = id

        return this
    }

    setLayer(id: VectorUint8) {
        this.store.currentLayer = id

        return this
    }

    setEdits(toolName?: string, editId?: VectorUint8) {
        if (typeof toolName === 'undefined' || typeof editId === 'undefined') {
            this.store.currentEdit = null
            this.store.edits = {}
            return this
        }
        this.store.currentEdit = toolName
        this.store.edits = { [toolName]: editId }

        return this
    }

    setParameters(id: string, parameters: ParametersMap) {
        this.store.parameters[id] = parameters

        return this
    }

    setCrop(crop: CropRect, image: ImageRect, ratio: string) {
        this.store.ratio = ratio
        this.store.crop = crop
        this.store.image = image

        return this
    }

    resetCrop() {
        this.store.ratio = null
        this.store.crop = null
        this.store.image = null
    }

    getRatio() {
        return this.store.ratio
    }

    getCropInfo() {
        return this.store.crop
    }

    getImageInfo() {
        return this.store.image
    }

    getCurrentLayer() {
        return this.store.currentLayer
    }

    getImageId() {
        return this.store.currentImage
    }

    getCurrentRawEditId() {
        return this.store.currentEdit ? this.store.edits[this.store.currentEdit] : null
    }

    getCurrentEditId() {
        return this.store.currentEdit
    }

    getCurrentParameters() {
        return this.store.currentEdit ? this.store.parameters[this.store.currentEdit] : null
    }

    reset() {
        this.store = cloneDeep(inititalState)
    }
}
