import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
    pickerEnabled: boolean
    pickerCoordinate?: {
        x: number
        y: number
    } | null
}

const initialState: State = {
    pickerEnabled: false,
    pickerCoordinate: null,
}

export const colorPickerState = createSlice({
    name: 'colorPicker',
    initialState,
    reducers: {
        setColorPickerStatus(state, { payload }: PayloadAction<boolean>) {
            state.pickerEnabled = payload
        },
        setColorPickerCoordinate(state, { payload }: PayloadAction<{ x: number; y: number } | null>) {
            state.pickerCoordinate = payload
        },
    },
})
