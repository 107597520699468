import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OptionTypes } from '@features/toolsPanel/constants'

type CropState = {
    options: Record<string, { width: number; height: number }>
}

const cropInitialState: CropState = {
    options: OptionTypes,
}

export const cropState = createSlice({
    name: 'cropState',
    initialState: cropInitialState,
    reducers: {
        updateSetRatio: (
            state,
            { payload }: PayloadAction<{ key: string; ratio: typeof OptionTypes[keyof typeof OptionTypes] }>,
        ) => {
            state.options[payload.key] = payload.ratio
        },
    },
})
