import { ToolId } from '@domain/filters'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { FieldValues } from 'react-hook-form'
import { FormStatePayload, Tabs } from '../types'
import { onResetEffectInUI } from './actions'

type PresenceKeys = 'hasSky' | 'hasFaces' | 'hasSkin' | 'portrait' | 'bigPortrait' | 'bokehPortrait'

type ToolsState = {
    [key in ToolId]?: FieldValues
} & Record<'detection', Record<PresenceKeys, boolean>>

const initialState: ToolsState = {
    detection: {
        hasSky: true,
        hasFaces: true,
        hasSkin: true,
        portrait: true,
        bigPortrait: true,
        bokehPortrait: true,
    },
}

const DEFAULT_PARAMETER_VALUE = undefined

export const toolsState = createSlice({
    name: 'toolsState',
    initialState,
    reducers: {
        /**
         * Updates only already existing parameters within effect
         */
        updateFormState: (state, { payload: { values, meta } }: PayloadAction<FormStatePayload>) => {
            const toolId = meta.id
            const effect = values
            if (!effect || !toolId) return state
            const newToolState = {
                ...(state[toolId] ?? {}),
            }
            for (const parameterName in effect) {
                if (!Object.prototype.hasOwnProperty.call(effect, parameterName)) continue
                const newParameter = effect[parameterName]
                const currentParameter = get(state, [toolId, parameterName])
                if (newParameter === currentParameter) continue
                newToolState[parameterName] = newParameter
            }
            state[toolId] = newToolState
        },
        updateDetection: (state, { payload }: PayloadAction<Partial<Record<PresenceKeys, boolean>>>) => {
            state.detection = {
                ...state.detection,
                ...payload,
            }
        },
    },
    extraReducers: builder =>
        builder.addCase(onResetEffectInUI, (state, { payload }) => {
            const currentTool = state[payload]
            if (!currentTool) return state

            const keysToUpdate = Object.keys(currentTool || {})

            for (const key of keysToUpdate) {
                currentTool[key] = DEFAULT_PARAMETER_VALUE
            }
        }),
})

type PanelUI = {
    tab: Tabs
}
const panelState: PanelUI = {
    tab: 'tools',
}
export const panelUISlice = createSlice({
    name: 'panelUI',
    initialState: panelState,
    reducers: {
        switchTab(state, { payload }: PayloadAction<Tabs>) {
            state.tab = payload
        },
    },
})
