import { merge, uniqBy } from 'lodash-es'
import { getCommonEffects } from './commonEffectsMethods'
import { effects, defaultEffectsMap, getCurves } from './defaultEffects'
import { extendedColorEffectsMap } from './extendedColorAdjustmentLayer'
import { bwChannels_v1_EffectMap } from './bwChannels_v1_Effect'
import { EnhanceAIMap } from './AIEnhanceAdjustmentLayer'

export const getEffectsRule = (props: { getMipl: () => MiplRawModule; name: string }) => {
    switch (props.name) {
        case 'DefaultEffects':
            return merge(getCurves(props), getCommonEffects(props, defaultEffectsMap))
        case 'ExtendedColorAdjustmentLayer':
            return getCommonEffects(props, extendedColorEffectsMap)
        case 'BWChannels_v1_Effect':
            return getCommonEffects(props, bwChannels_v1_EffectMap)
        case 'AIEnhanceAdjustmentLayer':
            return getCommonEffects(props, EnhanceAIMap)
        default:
            throw Error('Have not implemented yet')
    }
}

export const getToolNames = (name = '') => {
    switch (name) {
        case 'DefaultEffects':
            return uniqBy(effects, 'effectId').map(({ effectId }) => effectId)
        case 'ExtendedColorAdjustmentLayer':
            return uniqBy(extendedColorEffectsMap, 'effectId').map(({ effectId }) => effectId)
        case 'BWChannels_v1_Effect':
            return uniqBy(bwChannels_v1_EffectMap, 'effectId').map(({ effectId }) => effectId)
        case 'AIEnhanceAdjustmentLayer':
            return uniqBy(EnhanceAIMap, 'effectId').map(({ effectId }) => effectId)
        default:
            throw Error('Have not implemented yet')
    }
}
