import { AppEpic } from '@store/configure-store'
import { filter, switchMap } from 'rxjs/operators'
import { store } from './index'
import { actions, selectors } from '@features/renderer/store'

export const runUploadEpic: AppEpic = (actions$, state$) =>
    actions$.pipe(
        filter(actions.uploadImage.rejected.match),
        switchMap(() => [
            store.toastSlice.actions.toggleOpen(true),
            store.toastSlice.actions.toggleType(
                selectors.isImageInfoFetched(state$.value) ? 'uploadError' : 'uploadErrorFirst',
            ),
        ]),
    )
