import { EffectsInfo, VISUAL_DEFAULT_RANGE } from '@shared/constants/effectsInfo'

export type ParametersMap = Record<string, Record<string, MParameter>>

type ValuesRange = [number, number]

type ParameterValue = {
    effect: string
    parameter: {
        name: string
        value: number
    } & Record<string, unknown>
}

type CallFunc = ({ value }: { value: unknown }, deps: { parameters: ParametersMap }) => { parameter: ParameterValue }

type InitialProps = { getMipl: () => MiplRawModule }

export const getCommonEffects = (
    { getMipl }: InitialProps,
    commonEffectsMap: { effectId: string; paramaterId: string }[],
) =>
    commonEffectsMap.reduce<Record<string, Record<string, CallFunc>>>(
        (acc, { effectId, paramaterId }) => ({
            ...acc,
            [effectId]: {
                ...acc[effectId],
                [paramaterId]: ({ value }, deps) => {
                    const val = Number(value)
                    const mipl = getMipl()

                    return {
                        parameter: prepareCommonParameters({ effectId, paramaterId, value: val }, deps, {
                            optionalData: new mipl.OptionalData(),
                        }),
                    }
                },
            },
        }),
        {},
    )

export const prepareCommonParameters = (
    { effectId, paramaterId, value }: { effectId: string; paramaterId: string; value: string | number },
    deps: { parameters: ParametersMap },
    optionalData: Record<string, unknown>,
): ParameterValue => {
    const currentParameter = deps.parameters[effectId][paramaterId]
    const { visualValues } = EffectsInfo[effectId][paramaterId]
    const uiInfo = visualValues ?? VISUAL_DEFAULT_RANGE
    if (!visualValues) console.warn(`Visual values range doesn't exist for ${effectId} ${paramaterId}`)

    const slope = getSlope(
        [currentParameter?.minValue() ?? uiInfo.visualMinValue, currentParameter?.maxValue() ?? uiInfo.visualMaxValue],
        [uiInfo.visualMinValue, uiInfo.visualMaxValue],
    )

    return {
        effect: effectId,
        parameter: {
            name: paramaterId,
            value: Number(value) * slope,
            ...optionalData,
        },
    }
}

const getSlope = (miplRange: ValuesRange, uiRange: ValuesRange): number =>
    (miplRange[0] - miplRange[1]) / (uiRange[0] - uiRange[1])
