import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'
import { rendererAdapter } from './store'

const getRootStore = (state: RootState) => state
const getStore = (state: RootState) => state.renderer

const currentSelectors = rendererAdapter.getSelectors<RootState>(getStore)

export const getCurrentId = createSelector(getStore, renderer => renderer?.current ?? '')

export const isImageInfoFetched = createSelector(getCurrentId, id => !!id)

export const getCurrentImage = createSelector(getRootStore, getCurrentId, (state, id) =>
    currentSelectors.selectById(state, id ?? ''),
)

export const getCurrentImageName = createSelector(getCurrentImage, image => image?.title)

export const getCurrentImageUrl = createSelector(getCurrentImage, image => image?.url)
export const getImageFetchError = createSelector(getStore, state => state.error)
