import { useContext, useEffect, createContext } from 'react'
import { useServices } from '@shared/components/providers/services'
import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { take, tap } from 'rxjs/operators'

type Action = AnyAction | PayloadAction
export const useActionsListener = (effect: (act: Action) => void, action: Action, options = { once: false }) => {
    const ctx = useServices()
    const { once } = options

    useEffect(() => {
        const sub = ctx.actions.pipe(once ? take(1) : tap()).subscribe(action$ => {
            if (action.type === action$.type) {
                effect(action$)
            }
        })

        return () => {
            sub.unsubscribe()
        }
    }, [ctx, action, effect, once])
}

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A extends Record<string, unknown> | null>() {
    const ctx = createContext<A | undefined>(undefined)
    function useCtx() {
        const c = useContext(ctx)
        if (c === undefined) throw new Error('useCtx must be inside a Provider with a value')
        return c
    }
    return [useCtx, ctx.Provider] as const // 'as const' makes TypeScript infer a tuple
}
