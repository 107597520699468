import { AppEpic } from '@store/configure-store'
import { merge, of, zip } from 'rxjs'
import { filter, catchError, switchMap, mergeMap, concatMap, tap, map, ignoreElements } from 'rxjs/operators'
import { resetPassword, resetCode, updatePassword } from '../actions'
import { open, getType, getEmail, getRequiredInfo } from '../selectors.ui'
import { store } from '..'

export const sendResetPasswordEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(resetPassword.act.match),
        switchMap(({ payload }) => zip([apiAdapter.resetPassword(payload), of(payload)])),
        concatMap(([_, payload]) =>
            of(resetPassword.fulfilled(payload), store.forgotPassword.actions.setInfo(payload)),
        ),
        catchError((err: Error, caught) =>
            merge(
                of(resetPassword.rejected(err?.message)).pipe(
                    tap(() => {
                        // ignore analytics event for testing
                        if (process.env.NODE_ENV !== 'test') {
                            analytics.forgotPasswordErrorUsage(err?.message)
                        }
                    }),
                ),
                caught,
            ),
        ),
    )

export const setResetCodeEpic: AppEpic = (actions$, state$, { apiAdapter }) => {
    // for some reasons combinlatest funcitionality that perfect fit here doesn't wokr
    // then I'm reimplemented it manually
    // TODO: Check it later
    return actions$.pipe(
        filter(resetCode.act.match),
        filter(() => !!getEmail(state$.value)),
        mergeMap(({ payload }) =>
            zip([apiAdapter.validatePin({ ...payload, email: getEmail(state$.value)! }), of(payload)]),
        ),
        concatMap(([_, payload]) => of(resetCode.fulfilled(payload), store.forgotPassword.actions.setInfo(payload))),
        catchError((err: Error, caught) => merge(caught, of(resetCode.rejected(err?.message)))),
    )
}

export const updatePasswordEpic: AppEpic = (actions$, state$, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(updatePassword.act.match),
        filter(() => !!getRequiredInfo(state$.value)),
        switchMap(({ payload }) =>
            zip([apiAdapter.updatePassword({ ...payload, ...getRequiredInfo(state$.value)! }), of(payload)]),
        ),
        mergeMap(([_, payload]) => of(updatePassword.fulfilled(payload), store.forgotPassword.actions.reset())),
        catchError((err: Error, caught) =>
            merge(
                of(updatePassword.rejected(err?.message)).pipe(
                    tap(() => {
                        // ignore analytics event for testing
                        if (process.env.NODE_ENV !== 'test') {
                            analytics.forgotPasswordErrorUsage(err?.message)
                        }
                    }),
                ),
                caught,
            ),
        ),
    )

export const forgotPasswordFlow: AppEpic = (actions$, $state) => {
    const { changeAuth, setOpen } = store.authUIState.actions
    const listenToForgot$ = actions$.pipe(
        filter(resetPassword.fulfilled.match),
        filter(() => open($state.value) && getType($state.value) === 'forgotPassword'),
        switchMap(() => of(setOpen(false), changeAuth('resetCode'), setOpen(true))),
    )
    const listenToResetCode$ = actions$.pipe(
        filter(resetCode.fulfilled.match),
        filter(() => open($state.value) && getType($state.value) === 'resetCode'),
        switchMap(() => of(setOpen(false), changeAuth('newPassword'), setOpen(true))),
    )
    const listenToUpdatePassword$ = actions$.pipe(
        filter(updatePassword.fulfilled.match),
        filter(() => open($state.value) && getType($state.value) === 'newPassword'),
        switchMap(() => of(setOpen(false), changeAuth('login'), setOpen(true))),
    )

    return merge(listenToForgot$, listenToResetCode$, listenToUpdatePassword$)
}

// export const sendResetPasswordAnalyticsEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
//     actions$.pipe(
//         filter(resetPassword.fulfilled.match),
//         tap(() => {
//             // ignore analytics event for testing
//             if (process.env.NODE_ENV !== 'test') {
//                 analytics.forgotPasswordSuccessUsage()
//             }
//         }),
//         ignoreElements(),
//     )
export const updatePasswordAnalyticsEpic: AppEpic = (actions$, state$, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(updatePassword.fulfilled.match),
        tap(() => {
            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.forgotPasswordSuccessUsage()
            }
        }),
        ignoreElements(),
    )
