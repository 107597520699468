import { ToolId } from '@domain/filters'
import { createAction } from '@reduxjs/toolkit'
import { SkyTexturesDTO } from '@shared/server/dto'
import { ScaleIds } from '../types'

const storeContext = 'toolsTab'
const fetch_string = `${storeContext}/config`

export const fetchToolsPanel = {
    act: createAction(`${fetch_string}/pending`),
    fulfilled: createAction<{ texture: { sky: SkyTexturesDTO } }>(`${fetch_string}/fulfilled`),
    rejected: createAction<Error>(`${fetch_string}/rejected`),
}

export type CropPayload = {
    element: 'rotate' | 'vflip' | 'hflip' | 'halignment' | 'submit'
}
export const onCropPanelClick = createAction<CropPayload>(`${storeContext}/crop/action`)

export const onSubmenuToggle = createAction<string>(`toolsState/submenu`)

/**
 * Intended for using in mipl
 */
export const onResetEffectInMipl = createAction<ToolId>(`${storeContext}/effect/mipl/reset`)
/**
 * Intended for using for store update
 */
export const onResetEffectInUI = createAction<ToolId>(`${storeContext}/effect/ui/reset`)
export const onResetEffectForm = createAction<ToolId>(`${storeContext}/effect/form/reset`)
export const onDisableCurrentEffect = createAction<{ disable: boolean; tool: ToolId }>(`${storeContext}/effect/disable`)
export const onDisableAllEdits = createAction<boolean>(`${storeContext}/layer/disable`)

const editsContext = 'editsTab'
export const setCurrentEdit = createAction<string>(`${editsContext}/set/current/edit`)
export const disableCurrentEdit = createAction<{ id: string; disable: boolean }>(`${editsContext}/disable/current/edit`)
export const removeCurrentEdit = createAction<string>(`${editsContext}/remove/current/edit`)
export const removeAllEdits = createAction(`${editsContext}/remove/all/edits`)

export const zoomImage = createAction<ScaleIds>(`${editsContext}/zoom`)
