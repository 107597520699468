import { AppEpic, RootState } from '@store/configure-store'
import { Observable, of, merge } from 'rxjs'
import { filter, map, ignoreElements, mergeMap, distinctUntilChanged, concatMap, switchMap, take } from 'rxjs/operators'
import { store, uiSelectors, actions } from '..'
import { AnyAction, CombinedState, PayloadAction } from '@reduxjs/toolkit'
import { getCropRatio, getCropRatioKey } from '@features/toolsPanel/store/ui/crop/selectors'
import { adoptCropFromToRatio } from '@features/renderer/utilities'
import {
    getIntersections,
    getMaximumIntersectedDifference,
    shiftPointOnSegment,
} from '@features/renderer/services/Points'
import { getRectVertices } from '@features/renderer/services/Shapes'
import { CROP_FRAME_PRECISION_MARGIN } from '@features/renderer/constants'
import { Size2D } from '@shared/types'
import { onResetEffectInMipl } from '@features/toolsPanel/store/actions'
import { calculateCenter, calculateScale, calculateScaledSize } from '@features/renderer/utilities/image'

export const cropHandling: AppEpic = (actions$, $state) =>
    actions$.pipe(
        filter(store.uiSlice.actions.setSize.match),
        distinctUntilChanged(),
        mergeMap(
            ({ payload }) =>
                strategies[payload.type]?.(payload, $state.value) || of(null).pipe(take(1), ignoreElements()),
        ),
    )

export const ratioChangeEpic: AppEpic = (actions$, $state) =>
    merge(
        actions$.pipe(
            filter(onResetEffectInMipl.match),
            filter(({ payload }) => payload === 'MPCropToolFilter'),
        ),
        $state.pipe(
            filter(state => !!getCropRatio(state) && !!getCropRatioKey($state.value)),
            map(state => getCropRatioKey(state)),
            distinctUntilChanged(),
        ),
    ).pipe(
        map(() => getCropRatioKey($state.value)),
        map(toolCropKey => ({ ratio: getCropRatio($state.value), toolCropKey })),
        switchMap(({ ratio, toolCropKey }) => {
            const { setSize, setRatioStatus, setPosition } = store.uiSlice.actions
            const scaledImageSize = uiSelectors.getImageSize($state.value)
            const imageRotationAngle = uiSelectors.getImageRotation($state.value)
            const imageCenter = uiSelectors.getImageOffset($state.value)
            const newCropSize = adoptCropFromToRatio(
                {
                    image: scaledImageSize,
                    ratio:
                        toolCropKey === 'transposed'
                            ? { width: scaledImageSize.height, height: scaledImageSize.width }
                            : ratio,
                },
                CROP_FRAME_PRECISION_MARGIN,
            )

            const newCropVertices = getRectVertices(imageCenter, newCropSize)
            const imagePoints = getRectVertices(imageCenter, scaledImageSize, imageRotationAngle)

            const intersectionsData = getIntersections([imageCenter.x, imageCenter.y], newCropVertices, imagePoints)
            const intersection = getMaximumIntersectedDifference(intersectionsData)
            const scale = intersection
                ? (intersection.distances[0] - (intersection.distances[0] * 2) / 100) / intersection.distances[1]
                : 1

            return of(
                setPosition({ type: 'image', position: imageCenter }),
                setPosition({ type: 'crop', position: imageCenter }),
                setSize({
                    type: 'crop',
                    size: {
                        width: newCropSize.width * scale,
                        height: newCropSize.height * scale,
                    },
                }),
                setRatioStatus(toolCropKey !== 'free'),
            )
        }),
    )

export const rotateCanvasEpic: AppEpic = (actions$, $state) =>
    actions$.pipe(
        filter(store.uiSlice.actions.setRotation.match),
        filter(({ payload }) => !payload.keepCropUnchanged),
        filter(({ payload }) => !payload.type || payload.type === 'image'),
        concatMap(() => {
            const { setSize, setPosition } = store.uiSlice.actions
            const cropCenter = uiSelectors.getCropCenter($state.value)
            const cropSize = uiSelectors.getCropSize($state.value)
            const cropPoints = uiSelectors.getCropVertices($state.value)

            const imagePoints = uiSelectors.getImageVertices($state.value)
            const imageSize = uiSelectors.getImageSize($state.value)
            const imagePosition = uiSelectors.getImagePosition($state.value)

            const intersectionsData = getIntersections([cropCenter.x, cropCenter.y], cropPoints, imagePoints)
            const intersection = getMaximumIntersectedDifference(intersectionsData)
            const acts: AnyAction[] = []

            if (intersection) {
                const scale = intersection.distances[0] / intersection.distances[1]
                const scaledCropSize = {
                    width: cropSize.width * scale,
                    height: cropSize.height * scale,
                }

                if (cropIsTooSmall(imageSize, scaledCropSize)) {
                    const distance = Math.abs(intersection.distances[0] - intersection.distances[1])
                    const newPosition = shiftPointOnSegment(cropCenter, imagePosition, distance)

                    acts.push(
                        setPosition({
                            type: 'image',
                            position: newPosition,
                        }),
                    )
                } else {
                    acts.push(
                        setSize({
                            type: 'crop',
                            size: scaledCropSize,
                        }),
                    )
                }
            }

            return acts
        }),
    )

export const keepCropInCenterEpic: AppEpic = (actions$, state$) =>
    actions$.pipe(
        filter(actions.keepCropInCenter.match),
        map(() => ({
            crop: {
                position: uiSelectors.getCropPosition(state$.value),
            },
            image: {
                position: uiSelectors.getImagePosition(state$.value),
                offset: uiSelectors.getImageOffset(state$.value),
            },
        })),
        mergeMap(({ crop, image }) => {
            const { setPosition } = store.uiSlice.actions
            const shiftX = crop.position.x - image.offset.x
            const shiftY = crop.position.y - image.offset.y

            return [
                setPosition({
                    type: 'crop',
                    position: {
                        x: crop.position.x - shiftX,
                        y: crop.position.y - shiftY,
                    },
                }),
                setPosition({
                    type: 'image',
                    position: {
                        x: image.position.x - shiftX,
                        y: image.position.y - shiftY,
                    },
                }),
            ]
        }),
    )

const cropIsTooSmall = (imageSize: Size2D, cropSize: Size2D, relRate = 5): boolean => {
    const smallestImageSide = Math.min(imageSize.height, imageSize.width)
    const smallestCropSide = Math.min(cropSize.height, cropSize.width)

    return smallestImageSide / smallestCropSide > relRate
}

const strategies: Partial<{
    [key in ReturnType<typeof store.uiSlice.actions.setSize>['payload']['type']]: (
        prop: ReturnType<typeof store.uiSlice.actions.setSize>['payload'],
        state: CombinedState<RootState>,
    ) => Observable<PayloadAction<unknown>> | PayloadAction<unknown>[]
}> = {
    // Assume with this action starts init of renderer
    image: ({ size }, state) => {
        const { setScale, setPosition } = store.uiSlice.actions
        const containerSize = uiSelectors.getContainerSize(state)
        const scale = calculateScale(size, containerSize)
        const scaledImageSize = calculateScaledSize(size, scale)
        const position = calculateCenter(scaledImageSize)

        return [setScale({ type: 'image', scale }), setPosition({ type: 'image', position })]
    },
}
