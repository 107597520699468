import * as E from 'fp-ts/Either'
import { failure } from 'io-ts/lib/PathReporter'
import type { Validation } from 'io-ts'
import { pipe } from 'fp-ts/pipeable'
import {
    PresetCategoriesPayload,
    CollectionPayload,
    PresetPayload,
    BinPayload,
    PanelPayload,
    EffectPayload,
    SkyTexturePayload,
    UserPayload,
} from './schemas'
import { Image } from '@domain/image'
import { dataToImage } from '@features/renderer/utilities'

export const validateCategoriesPayload = (raw: unknown) => validate(PresetCategoriesPayload.decode(raw))

export const validateCollectionsPayload = (raw: unknown) => validate(CollectionPayload.decode(raw))

export const validatePresetPayload = (raw: unknown) => validate(PresetPayload.decode(raw))

export const validateBinPayload = (raw: unknown) => validate(BinPayload.decode(raw))

export const validatePanelPayload = (raw: unknown) => validate(PanelPayload.decode(raw))

export const validateEffects = (raw: unknown) => validate(EffectPayload.decode(raw))

export const validateSkyTexture = (raw: unknown) => validate(SkyTexturePayload.decode(raw))

export const validateUser = (raw: unknown) => validate(UserPayload.decode(raw))

export const validate = <T>(schema: Validation<T>): T =>
    pipe(
        schema,
        E.getOrElseW(errors => {
            throw new Error(failure(errors).join('\n'))
        }),
    )

const MIPL_IMAGE_LIMIT = 30 * 1000000
export const validateImage = (data: Image): Promise<Image> =>
    dataToImage(data.url)
        .then(
            ({ data }) =>
                data &&
                data.data.length / 4 > MIPL_IMAGE_LIMIT &&
                Promise.reject(new Error('Too big image, please upload another one')),
        )
        .then(() => data)
