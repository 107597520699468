import { RectangleVertices, Size2D, Vector2D } from '@shared/types'

export const toRadians = (angle: number): number => {
    return angle * (Math.PI / 180)
}

export function toDegrees(angle: number): number {
    return angle * (180 / Math.PI)
}

/**
 *
 * @param center Should be always a center of shape
 * @param shapeSize 2D size representation
 * @param angleDeg Only degrees
 * @returns Coordinates for each shape vertices
 */
export const getRectVertices = (center: Vector2D, { width, height }: Size2D, angleDeg = 0): RectangleVertices => {
    const angle = toRadians(angleDeg)
    const widthPart = (dim = 'x') => (dim === 'x' ? (width / 2) * Math.cos(angle) : (width / 2) * Math.sin(angle))
    const heightPart = (dim = 'x') => (dim === 'x' ? (height / 2) * Math.sin(angle) : (height / 2) * Math.cos(angle))

    return [
        [center.x - widthPart() + heightPart(), center.y - widthPart('y') - heightPart('y')],
        [center.x + widthPart() + heightPart(), center.y + widthPart('y') - heightPart('y')],
        [center.x + widthPart() - heightPart(), center.y + widthPart('y') + heightPart('y')],
        [center.x - widthPart() - heightPart(), center.y - widthPart('y') + heightPart('y')],
    ]
}

export const getNonRotatedRectVertices = ({ x, y }: Vector2D, { width, height }: Size2D): RectangleVertices => {
    return [
        [x, y],
        [x + width, y],
        [x + width, y + height],
        [x, y + height],
    ]
}
