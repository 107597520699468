import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Size2D, Vector2D } from '@shared/types'

type CanvasState = 'idle' | 'animate-in'
type State = {
    container: Size2D
    canvas: Size2D
    image: {
        originSize: Size2D
        size: Size2D
        rotation: number
        position: Vector2D
        scale: number
        flip: Vector2D
        draggable: boolean
        rotating: boolean
    }
    crop: {
        size: Size2D
        ratio: Size2D
        position: Vector2D
        offset: Vector2D
        enabled: boolean
        scale: number
        rotation: number
        keepRatio: boolean
    }
    canvasState: CanvasState
}

const defaultSize: Size2D = { width: 0, height: 0 }
const defaultPosition: Vector2D = { x: 0, y: 0 }

export const initialState: State = {
    container: defaultSize,
    canvas: defaultSize,
    image: {
        originSize: defaultSize,
        size: defaultSize,
        rotation: 0,
        position: defaultPosition,
        scale: 0,
        flip: { x: 1, y: 1 },
        draggable: false,
        rotating: false,
    },
    crop: {
        size: defaultSize,
        ratio: { width: 1, height: 1 },
        position: defaultPosition,
        offset: defaultPosition,
        enabled: false,
        scale: 1,
        rotation: 0,
        keepRatio: true,
    },
    canvasState: 'idle',
}

export const uiSlice = createSlice({
    name: 'uiRenderer',
    initialState,
    reducers: {
        setSize(
            state,
            {
                payload,
            }: PayloadAction<{ type: 'container' | 'canvas' | 'crop' | 'image' | 'imageScaled'; size: Size2D }>,
        ) {
            if (payload.type === 'crop') {
                state[payload.type].size = payload.size
                return
            }
            if (payload.type === 'image') {
                state[payload.type].originSize = payload.size
                return
            }
            if (payload.type === 'imageScaled') {
                state['image'].size = payload.size
                return
            }
            state[payload.type] = payload.size
        },
        setScale(state, { payload }: PayloadAction<{ type: 'crop' | 'image'; scale: number }>) {
            if (payload.type === 'crop') {
                state[payload.type].scale = payload.scale
                return
            }
            if (payload.type === 'image') {
                state[payload.type].scale = payload.scale
                return
            }
        },
        setRotation(
            state,
            {
                payload: { type = 'image', rotation },
            }: PayloadAction<{ type?: 'crop' | 'image'; rotation: number; keepCropUnchanged?: boolean }>,
        ) {
            if (type === 'crop') {
                state[type].rotation = rotation
                return
            }
            if (type === 'image') {
                state[type].rotation = rotation
                return
            }
        },
        /**
         * In case of image it always should be calculated according to center
         */
        setPosition(
            state,
            { payload: { type = 'crop', position } }: PayloadAction<{ type?: 'crop' | 'image'; position: Vector2D }>,
        ) {
            if (type === 'crop') {
                state[type].position = position
                return
            }
            if (type === 'image') {
                state[type].position = position
                return
            }
        },
        setCropStatus(state, { payload }: PayloadAction<boolean>) {
            state.crop.enabled = payload
        },
        setFlip(state, { payload }: PayloadAction<Vector2D>) {
            state.image.flip = payload
        },
        setDragStatus(state, { payload }: PayloadAction<boolean>) {
            state.image.draggable = payload
        },
        setRotateStatus(state, { payload }: PayloadAction<boolean>) {
            state.image.rotating = payload
        },
        setRatioStatus(state, { payload }: PayloadAction<boolean>) {
            state.crop.keepRatio = payload
        },
        toggleCanvasStatus(state, { payload }: PayloadAction<CanvasState>) {
            state.canvasState = payload
        },
        reset() {
            return initialState
        },
    },
})
