import { RectangleVertices, Size2D, Vector2D, Vector2DTuple } from '@shared/types'

type DiagonalVertices = [Vector2DTuple, Vector2DTuple]
const getXShift = ([[leftX], [rightX]]: DiagonalVertices, cSize: Size2D): number => {
    const isUngluedLeftX = leftX > 0
    const isUngluedRightX = rightX < cSize.width
    const imageWidthIsLessThanContainer = rightX - leftX < cSize.width
    if (imageWidthIsLessThanContainer) {
        return (cSize.width - rightX - leftX) / 2
    }

    if (isUngluedLeftX && !isUngluedRightX) {
        return -leftX
    }
    if (!isUngluedLeftX && isUngluedRightX) {
        return cSize.width - rightX
    }

    return 0
}
const getYShift = ([[leftX, topY], [rightX, bottomY]]: DiagonalVertices, cSize: Size2D): number => {
    const isUngluedTopY = topY > 0
    const isUngluedBottomY = bottomY < cSize.height
    const imageHeightIsLessThanContainer = bottomY - topY < cSize.height
    if (imageHeightIsLessThanContainer) {
        return (cSize.height - bottomY - topY) / 2
    }

    if (isUngluedTopY && !isUngluedBottomY) {
        return -topY
    }
    if (!isUngluedTopY && isUngluedBottomY) {
        return cSize.height - bottomY
    }

    return 0
}
export const getShiftCoordinates = (
    [[leftX, topY], _rightTop, [rightX, bottomY]]: RectangleVertices,
    cSize: Size2D,
): Vector2D => {
    const diagVertices: DiagonalVertices = [
        [leftX, topY],
        [rightX, bottomY],
    ]
    return {
        x: getXShift(diagVertices, cSize),
        y: getYShift(diagVertices, cSize),
    }
}
