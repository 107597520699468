import { ToolId } from '@domain/filters'
import { store as toolsStore } from '@features/toolsPanel/store'
import { Size2D, Vector2D } from '@shared/types'
import { getEffectsRule, getToolNames } from './rules'
import { Edits } from '@shared/types'
import { toRadians } from '@features/renderer/services/Shapes'
import Konva from 'konva'
import { CropRect, ImageRect } from './store'

type Pay = {
    parameter: Record<string, any>
}

type ToolPay = { tool: { toolDescription: ToolDescription } }

export type ReturnPreparePayload = {
    parameter: (deps: any) => Pay
    tool: (deps?: unknown) => ToolPay
    id: string
}

export type ParametersMap = Record<string, Record<string, MParameter>>

type EffectsMap = Record<string, Record<string, CallableFunction>>
type ToolDescriptionReturn = Record<'getTool', (deps?: unknown) => ToolPay>
type RulesMap = Record<string, EffectsMap | ToolDescriptionReturn>

const toolNames: ToolId[] = [
    'DefaultEffects',
    'ExtendedColorAdjustmentLayer',
    'BWChannels_v1_Effect',
    'AIEnhanceAdjustmentLayer',
]

export class MiplAdapter {
    private rules: RulesMap = toolNames.reduce((acc, name) => {
        return {
            ...acc,
            [name]: {
                getTool: (_deps?: unknown) => ({
                    tool: {
                        toolDescription: new this.mipl.ToolDescription(
                            this.createStringVector(getToolNames(name)),
                            name,
                        ),
                    },
                }),
                ...getEffectsRule({ getMipl: () => this.mipl, name }),
            },
        }
    }, {})

    private _instance: MiplRawModule | null = null

    get mipl(): MiplRawModule {
        if (!this._instance) {
            throw new Error('Mipl core not yet initialized!')
        }

        return this._instance
    }

    set mipl(mod: MiplRawModule) {
        this._instance = mod
    }

    constructor(mipl: MiplRawModule) {
        this._instance = mipl
    }

    preparePayload = ({
        meta,
        values,
    }: ReturnType<typeof toolsStore.toolsState.actions.updateFormState>['payload']): ReturnPreparePayload | null => {
        if (!meta?.id) {
            return null
        }
        if (!meta?.name) {
            return null
        }
        const [effectId, parameterId] = meta.name.split('-')
        if (!this.rules?.[meta.id]) {
            return null
        }
        const effect = this.rules[meta.id] as EffectsMap
        if (!effect[effectId] || (!!effect?.[effectId] && !effect[effectId]?.[parameterId])) {
            return null
        }
        return {
            parameter: (deps: any) => effect[effectId][parameterId]({ value: values?.[meta.name ?? ''] }, deps),
            tool: (effect as unknown as ToolDescriptionReturn).getTool,
            id: meta.id,
        }
    }

    convertEditParameters = (payload: MapStringParameters): ParametersMap => {
        const parameters: ParametersMap = this.vectorToArray<string>(payload.keys()).reduce(
            (acc, key) => ({
                ...acc,
                [key]: this.vectorToArray<MParameter>(payload.get(key)._parameters).reduce(
                    (acc, parameter) => ({ ...acc, [parameter.name()]: parameter }),
                    {},
                ),
            }),
            {},
        )

        return parameters
    }

    convertEdits = (payload: MEdits): Edits => {
        type ReducerType = { edits: Edits; beforeCurrent: boolean }
        return vectorToArray(payload)
            .map(({ prettyNames: _prettyNames, identifier, tool_name, ...rest }) => ({
                ...rest,
                toolName: tool_name as ToolId,
                identifier: vectorToArray(identifier).join('-'),
            }))
            .reduce<ReducerType>(
                ({ beforeCurrent, edits }, edit) => {
                    return {
                        edits: [...edits, { ...edit, active: beforeCurrent }],
                        beforeCurrent: edit.isCurrent ? false : beforeCurrent,
                    }
                },
                { edits: [], beforeCurrent: true },
            ).edits
    }

    vectorToArray = vectorToArray

    arrayToVector = arrayToVector

    createWebImageData(rawImageData: Uint8ClampedArray, { width, height }: Size2D): ImageData {
        return new ImageData(rawImageData, width, height)
    }

    createStringVector(args: string[]): StringList {
        const stringList = new this.mipl.StringList()
        for (let index = 0; index < args.length; index++) {
            stringList.push_back(args[index])
        }
        return stringList
    }
}

const vectorToArray = <T>(vector: MVector<T>): Array<T> => {
    const acc = []
    for (let index = 0; index < vector.size(); index++) {
        acc.push(vector.get(index))
    }

    return acc
}

export const createVectorFromData = (target: VectorUint8, donor: Uint8ClampedArray): VectorUint8 => {
    for (let i = 0; i < donor.length; i++) {
        target.push_back(donor[i])
    }

    return target
}

export const arrayToVector = <T>(donor: Array<T>, target: MVector<T>): MVector<T> => {
    for (let i = 0; i < donor.length; i++) {
        target.push_back(donor[i])
    }

    return target
}

export const calculateAbsoluteCropData = (
    crop: CropRect,
    image: ImageRect,
): { size: Size2D; position: Vector2D; rotation: number; flip: { x: boolean; y: boolean } } => {
    const descale = (num: number): number => num / image.scale
    const m = new Konva.Transform()
    m.reset()
    const radAngle = toRadians(image.rotation)
    const x = image.position.x,
        y = image.position.y,
        rotation = radAngle,
        scaleX = 1, // we're providing already scaled image size
        scaleY = 1, // we're providing already scaled image size
        skewX = 0,
        skewY = 0,
        offsetX = image.offset.x,
        offsetY = image.offset.y

    if (x !== 0 || y !== 0) {
        m.translate(x, y)
    }
    if (rotation !== 0) {
        m.rotate(rotation)
    }
    if (skewX !== 0 || skewY !== 0) {
        m.skew(skewX, skewY)
    }
    if (scaleX !== 1 || scaleY !== 1) {
        m.scale(scaleX, scaleY)
    }
    if (offsetX !== 0 || offsetY !== 0) {
        m.translate(-1 * offsetX, -1 * offsetY)
    }

    m.dirty = false
    m.invert()
    const relativeCenter: Vector2D = m.point(crop.position)
    const leftTopCorner: Vector2D = {
        x: descale(relativeCenter.x - crop.size.width / 2),
        y: descale(relativeCenter.y - crop.size.height / 2),
    }
    return {
        size: {
            width: descale(crop.size.width),
            height: descale(crop.size.height),
        },
        position: leftTopCorner,
        rotation: radAngle,
        flip: swapFlip(image.flip),
    }
}

type SwapFlip = {
    (flip: Vector2D): { x: boolean; y: boolean }
    (flip: { x: boolean; y: boolean }): Vector2D
}
export const swapFlip: SwapFlip = (flip: any): any => {
    if (typeof flip.x === 'boolean') {
        return {
            x: flip.x ? -1 : 1,
            y: flip.y ? -1 : 1,
        }
    }

    return {
        x: flip.x === -1,
        y: flip.y === -1,
    }
}
