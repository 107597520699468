import { Image } from '@domain/image'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { FetchStates } from '@shared/utility/redux'
import { uploadImage } from './actions'

type PartialState = {
    current: Image['id'] | null
    loading: FetchStates
    error: string | null
}

export const rendererAdapter = createEntityAdapter<Image>({ selectId: image => image.id })

const initialState: PartialState = {
    current: null,
    loading: 'idle',
    error: null,
}

export const rendererSlice = createSlice({
    name: 'renderer',
    initialState: rendererAdapter.getInitialState(initialState),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(uploadImage.act, state => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(uploadImage.fulfilled, (state, { payload }) => {
            rendererAdapter.addOne(state, payload)
            state.loading = 'succeeded'
            state.error = null
            state.current = payload.id
        })
        builder.addCase(uploadImage.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.payload
        })
    },
})
