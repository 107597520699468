import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'

const getStore = (state: RootState) => state.auth
const getErrors = createSelector(getStore, store => store.error)
export const getLoading = createSelector(getStore, store => store.loading)
export const getSocOrEmailType = createSelector(getStore, store => store.socOrEmailType)

export const getUser = createSelector(getStore, auth => auth.user)
export const getUserMail = createSelector(getUser, user => (user?.email as string) ?? '')
export const isUserVerified = createSelector(getUser, user => !!user?.verified as boolean)
export const isLogged = createSelector(getUser, user => !!user)

const isLoginFetching = createSelector(getLoading, state => state['login'] === 'pending')
const isUserFetching = createSelector(getLoading, state => state['fetchUser'] === 'pending')
const isRegisterFetching = createSelector(getLoading, state => state['register'] === 'pending')
const isConfirmEmailFetching = createSelector(getLoading, state => state['confirmEmail'] === 'pending')
const isResendFetching = createSelector(getLoading, state => state['resendCode'] === 'pending')
const isResetPasswordFetching = createSelector(getLoading, state => state['resetPassword'] === 'pending')
const isResetCodeFetching = createSelector(getLoading, state => state['resetCode'] === 'pending')
const isUpdatePasswordFetching = createSelector(getLoading, state => state['updatePassword'] === 'pending')

export const isLoginProcessing = createSelector(isLoginFetching, isUserFetching, (login, user) => login || user)
export const isRegisteringProcessing = createSelector(
    isLoginFetching,
    isUserFetching,
    isRegisterFetching,
    (login, user, register) => login || user || register,
)
export const isConfirmationEmailProcessing = createSelector(
    isUserFetching,
    isConfirmEmailFetching,
    (user, confirm) => user || confirm,
)
export const isResendProcessing = createSelector(isResendFetching, loading => loading)
export const isResetPasswordProcessing = createSelector(isResetPasswordFetching, loading => loading)
export const isResetCodeProcessing = createSelector(isResetCodeFetching, loading => loading)
export const isUpdatePasswordProcessing = createSelector(isUpdatePasswordFetching, loading => loading)

export const isLoginError = createSelector(getLoading, loading => loading['login'] === 'failed')
export const isRegisterError = createSelector(getLoading, loading => loading['register'] === 'failed')
export const isEmailError = createSelector(getLoading, loading => loading['checkEmail'] === 'failed')
export const isUserUnathorized = createSelector(getLoading, loading => loading['fetchUser'] === 'failed')
export const isConfirmEmailError = createSelector(getLoading, loading => loading['confirmEmail'] === 'failed')
export const isResetPasswordError = createSelector(getLoading, loading => loading['resetPassword'] === 'failed')
export const isResetCodeError = createSelector(getLoading, loading => loading['resetCode'] === 'failed')
export const isUpdatePasswordError = createSelector(getLoading, loading => loading['updatePassword'] === 'failed')

export const getLoginErrors = createSelector(getErrors, error => error['login'])
export const getUserErrors = createSelector(getErrors, error => error['fetchUser'])
export const getRegisterErrorType = createSelector(getErrors, (error): 'user_exists' | 'unknown' => {
    const code = (error['register'] as Record<string, any>)?.['code']
    return Number.isInteger(code) && code !== 408 && code < 500 ? 'user_exists' : 'unknown'
})
