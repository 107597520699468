export * as actions from './actions'
export * as epics from './epics'
export * as selectors from './data/tools/selectors'
export * as editsSelectors from './data/edits/selectors'
export * as uiSelectors from './ui.selectors'
export * as uiSelectorsColorPicker from './ui/colorPicker/selectors'
export * as uiSelectorsCrop from './ui/crop/selectors'

import * as dataStore from './data/tools/store'
import * as dataEditsStore from './data/edits/store'
import * as uiStore from './ui.store'
import * as uiStoreColorPicker from './ui/colorPicker/store'
import * as uiStoreCrop from './ui/crop/store'

export const store = { ...dataStore, ...uiStore, ...uiStoreColorPicker, ...uiStoreCrop, ...dataEditsStore }
