import { Size2D } from '@shared/types'

export function dataToImage(
    imagePayload?: ImageData | string,
): Promise<{ element: HTMLImageElement | HTMLCanvasElement | undefined; data?: ImageData }> {
    return new Promise((resolve, reject) => {
        if (typeof imagePayload === 'undefined') {
            return resolve({ element: undefined })
        }
        const cnv = document.createElement('canvas')
        const ctx = cnv.getContext('2d', { willReadFrequently: true })
        if (!ctx) {
            throw new Error('Context is not created')
        }

        if (imagePayload instanceof ImageData) {
            setCanvasSize(cnv, imagePayload)
            ctx.putImageData(imagePayload, 0, 0)

            resolve({ element: cnv })
            return
        }

        const image = new Image()
        image.onerror = reject

        image.onload = () => {
            setCanvasSize(cnv, image)
            ctx.drawImage(image, 0, 0)
            resolve({ element: cnv, data: ctx.getImageData(0, 0, image.width, image.height) })
        }

        image.src = imagePayload
        return
    })
}

const setCanvasSize = (cnv: HTMLCanvasElement, size: Size2D) => {
    cnv.setAttribute('width', size.width.toString())
    cnv.setAttribute('height', size.height.toString())
}
