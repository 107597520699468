import { createReducer, Draft } from '@reduxjs/toolkit'
import { appInit, observablesInit, resetStore } from './actions'
import { Reducer } from '@reduxjs/toolkit'
import { RootState, AppThunk, AppReducers } from './configure-store'

type AppStore = {
    init: DateTimeString | null
    rxjs: DateTimeString | null
}

const initialState: AppStore = {
    init: null,
    rxjs: null,
}

export const appReducer = createReducer(initialState, builder => {
    builder.addCase(appInit, state => {
        state.init = new Date().toISOString()
    })
    builder.addCase(observablesInit, state => {
        state.rxjs = new Date().toISOString()
    })
})

export const RootReducer =
    (combinedReducers: AppReducers): Reducer =>
    (state: Draft<RootState>, action: AppThunk) => {
        if (action.type === resetStore.type) {
            const { payload } = action as ReturnType<typeof resetStore>
            ;(state as any)[payload] = undefined
        }
        return combinedReducers(state, action)
    }
