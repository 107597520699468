import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import localforage from 'localforage'
import { ISetupCache, setupCache } from 'axios-cache-adapter'
import {
    validateBinPayload,
    validateCategoriesPayload,
    validateCollectionsPayload,
    validateImage,
    validatePanelPayload,
    validatePresetPayload,
    validateSkyTexture,
    validateUser,
} from './validation/payload'
import {
    BinDescription,
    BinDTO,
    EffectsLayoutDTO,
    ModelNames,
    PresetDTO,
    PresetsCategoryDTO,
    PresetsCollectionDTO,
    ToolsPanelDTO,
    UserDTO,
} from '@shared/server/dto'
import { getId } from '@shared/utility/getId'
import { Image } from '@domain/image'
import { isEqual as _isEqual } from 'lodash-es'
import { AnalyticEvent } from '@shared/types'

const API_PATH = '/api/v1'
const fakeUser: UserDTO = { email: 'test@test.com', verified: false }

type ModelsReturn = Record<ModelNames, ArrayBuffer>

const excludeFromCache = ['user', 'bin', 'models']

export class APIService {
    private static cacheKeys = {
        model: (name: string) => `ml-${name}-model`,
        modelsDTO: 'ml-models-dto',
    } as const
    private static baseUrl = process.env.NEXT_PUBLIC_HOST
        ? new URL(API_PATH, process.env.NEXT_PUBLIC_HOST).href
        : API_PATH
    private readonly fetch: AxiosInstance
    private readonly cache: ISetupCache
    private readonly forageStore: LocalForage

    constructor(config: AxiosRequestConfig | undefined = {}) {
        this.forageStore = localforage.createInstance({
            driver: [localforage.INDEXEDDB],
            name: 'network-cache',
        })
        this.cache = setupCache({
            maxAge: process.env.ENVIRONMENT === 'development' ? 1000 : 60 * 1000,
            exclude: {
                paths: excludeFromCache.map(str => new RegExp(str)),
            },
            store: this.forageStore,
        })
        this.fetch = axios.create({
            baseURL: APIService.baseUrl,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            timeout: 60 * 1000,
            adapter: this.cache.adapter,
            ...config,
        })
    }

    public uploadImage = (files: File[]): Promise<Image> => {
        if (!files?.[0]) return Promise.reject()
        const file = files[0]

        return localUpload(file)
            .then(
                (url): Image => ({
                    id: getId(),
                    url,
                    title: file.name.replace(/.[^.]+$/, ''),
                    category: ['unmarked'],
                    albumId: undefined,
                    appliedFilters: undefined,
                    createdAt: new Date().toUTCString(),
                }),
            )
            .then(validateImage)
    }

    public login = (payload: { email: string; password: string }): Promise<object> =>
        this.fetch.post<object>('/auth/login', payload).then(({ data }) => data)

    public signUp = (payload: { email: string; password: string }): Promise<object> =>
        this.fetch.post<object>('/auth/register', payload).then(({ data }) => data)

    public checkUser = (payload: { email: string }): Promise<void> =>
        this.fetch.post<void>('/auth/check-email', payload).then(({ data }) => data)

    public confirmUserEmail = (payload: { email: string; code: string }): Promise<void> =>
        this.fetch.post<void>('/auth/confirm-email', payload).then(({ data }) => data)

    public resendCode = (payload: { email: string }): Promise<void> =>
        this.fetch.post<void>('/auth/resend-confirm', payload).then(({ data }) => data)

    public resetPassword = (payload: { email: string }): Promise<void> =>
        this.fetch.post<void>('/auth/send-forgot-mail', payload).then(({ data }) => data)

    public validatePin = (payload: { email: string; pin: string }): Promise<void> =>
        this.fetch.post<void>('/auth/validate-pin', payload).then(({ data }) => data)

    public updatePassword = (payload: { email: string; pin: string; password: string }): Promise<void> =>
        this.fetch.post<void>('/auth/update-password', payload).then(({ data }) => data)

    public logout = (): Promise<void> => this.fetch.post('/auth/logout').then(({ data }) => data)

    public fetchUser = (): Promise<UserDTO> => {
        return disabledAuth()
            ? Promise.resolve(fakeUser)
            : this.fetch.get<UserDTO>('/user').then(({ data }) => validateUser(data))
    }

    public fetchPreset = ({ presetId, collectionId }: { collectionId: string; presetId: string }) =>
        this.fetch
            .get<PresetDTO>(`/presets/${presetId}`, {
                params: { collectionId },
            })
            .then(({ data }) => validatePresetPayload(data))

    public fetchCategories = () =>
        this.fetch.get<PresetsCategoryDTO>(`/presets/categories`).then(({ data }) => validateCategoriesPayload(data))

    public fetchCollections = ({ categoryId }: { categoryId: string }) =>
        this.fetch
            .get<PresetsCollectionDTO>(`/presets/collections/${categoryId}`)
            .then(({ data }) => validateCollectionsPayload(data))

    public fetchPanel = () =>
        this.fetch.get<ToolsPanelDTO>(`/tools/panel-layout`).then(({ data }) => validatePanelPayload(data))

    public fetchToolsLayout = () => this.fetch.get<EffectsLayoutDTO>(`/tools/tools-layout`).then(({ data }) => data)

    public fetchTextures = () =>
        this.fetch({ baseURL: '', method: 'get', url: '/resources/SkyTextures/config.json' }).then(({ data }) =>
            validateSkyTexture(data),
        )

    public fetchModels = async (): Promise<ModelsReturn> => {
        const cachedModelInfo = await this.forageStore.getItem<BinDTO>(APIService.cacheKeys.modelsDTO)

        return this.fetch
            .get<BinDTO>('/models')
            .then(({ data }) => validateBinPayload(data))
            .then(async modelsInfo => {
                !cachedModelInfo && (await this.forageStore.setItem<BinDTO>(APIService.cacheKeys.modelsDTO, modelsInfo))

                return modelsInfo.libs.reduce(
                    async (acc, lib, index) => ({
                        ...acc,
                        [lib.name]:
                            cachedModelInfo && isCachedFits(lib, cachedModelInfo.libs[index])
                                ? await this.forageStore.getItem<ArrayBuffer>(APIService.cacheKeys.model(lib.name))
                                : await this.fetchBinary(lib.url).then(model =>
                                      this.forageStore.setItem(APIService.cacheKeys.model(lib.name), model),
                                  ),
                    }),
                    {},
                ) as ModelsReturn
            })
    }

    public postEvent = (data: AnalyticEvent) =>
        this.fetch.post('https://stats-api.skylum.com/site-events/events', data).then(({ data }) => data)

    public postPageView = (data: AnalyticEvent) =>
        this.fetch.post('https://stats-api.skylum.com/site-events/page-views/save', data).then(({ data }) => data)

    private fetchBinary = (url: string) =>
        this.fetch
            .get<ArrayBuffer>(url, {
                baseURL: '/',
                responseType: 'arraybuffer',
            })
            .then(({ data }) => data)
}

const localUpload = (file: File): Promise<string> =>
    file.arrayBuffer().then(arrayBuffer => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type })
        return window.URL.createObjectURL(blob)
    })

const disabledAuth = () =>
    (process.env.ENVIRONMENT === 'development' || process.env.ENVIRONMENT === 'test') &&
    process.env.NEXT_PUBLIC_DISABLE_AUTH === 'true'
const isCachedFits = (a: BinDescription, b?: BinDescription) => _isEqual(a, b)

export const apiAdapter = new APIService()
