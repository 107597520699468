import { Tool } from '@domain/filters'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EffectsLayoutDTO, ToolsPanelDTO, ToolCategoryDTO, SkyTexturesDTO } from '@shared/server/dto'
import { FetchStates } from '@shared/utility/redux'
import { schema, normalize } from 'normalizr'
import { fetchToolsPanel } from '../../actions'
import { Tools } from '../../../models'

const idKey = 'identifier'
const idItemsList = 'items'

const tools = new schema.Entity<Tool>('tools', {}, { idAttribute: idKey })
const categories = new schema.Entity<ToolCategoryDTO>('categories', { [idItemsList]: [tools] }, { idAttribute: idKey })

type State = {
    tools: Tools
    loading: FetchStates
    error: unknown
}
const initialState: State = {
    loading: 'idle',
    error: null,
    tools: { entities: {}, result: [] },
}

export const Panel = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        setToolsPanel(state, { payload }: PayloadAction<ToolsPanelDTO>) {
            state.tools = normalize(payload[0].items || [], [categories]) as Tools
        },
    },
})

type EffectsState = {
    items: EffectsLayoutDTO | Record<string, never>
    loading: FetchStates
    error: unknown
}

const effectsInitialState: EffectsState = {
    loading: 'idle',
    error: null,
    items: {},
}

export const Effects = createSlice({
    name: 'effects',
    initialState: effectsInitialState,
    reducers: {
        setEffectsInfo(state, { payload }: PayloadAction<EffectsLayoutDTO>) {
            state.items = payload
        },
    },
})

type TexturesState = {
    sky: { payload: SkyTexturesDTO; currentOption: string | null; currentCategory: number | null }
    loading: FetchStates
    error: unknown
}

const texturesInitialState: TexturesState = {
    loading: 'idle',
    error: null,
    sky: {
        payload: {},
        currentOption: null,
        currentCategory: null,
    },
}

export const Textures = createSlice({
    name: 'textures',
    initialState: texturesInitialState,
    reducers: {
        setCurrentOption(state, { payload }: PayloadAction<string | null>) {
            state.sky.currentOption = payload
        },
        setCurrentCategory(state, { payload }: PayloadAction<number | null>) {
            state.sky.currentCategory = payload
        },
    },
    extraReducers: builder =>
        builder
            .addCase(fetchToolsPanel.act, state => {
                state.loading = 'pending'
            })
            .addCase(fetchToolsPanel.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded'
                state.sky.payload = payload.texture.sky
            })
            .addCase(fetchToolsPanel.rejected, (state, { payload }) => {
                state.loading = 'failed'
                state.error = payload
            }),
})
