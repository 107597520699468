import { actions as exportActions, selectors as exportSelectors } from '@features/export/store'
import { appInit } from '@store/actions'
import { AppEpic } from '@store/configure-store'
import { merge, of, zip } from 'rxjs'
import { filter, map, catchError, switchMap, mergeMap, take, tap, ignoreElements } from 'rxjs/operators'
import { login, fetchUser, register, logout, checkEmail, confirmEmail, resendCode } from '../actions'
import { isUserVerified } from '../selectors'
import { getType, open } from '../selectors.ui'
import { store } from '..'
import { AnyAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

export * from './forgotPassword'

export const retrieveUserEpic: AppEpic = actions$ =>
    actions$.pipe(
        filter(appInit.match),
        take(1),
        map(() => fetchUser.act()),
    )

export const loginEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(login.act.match),
        switchMap(({ payload }) => apiAdapter.login(payload)),
        mergeMap(() => of(login.fulfilled(), fetchUser.act())),
        catchError((err: Error, caught) =>
            merge(
                of(login.rejected(err?.message)).pipe(
                    tap(() => {
                        // ignore analytics event for testing
                        if (process.env.NODE_ENV !== 'test') {
                            analytics.signInErrorUsage(err?.message)
                        }
                    }),
                ),
                caught,
            ),
        ),
    )

export const registrationEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(register.act.match),
        switchMap(({ payload }) => zip(apiAdapter.signUp(payload), of(payload))),
        mergeMap(([_, payload]) => of(login.act(payload), register.fulfilled())),
        catchError((err: AxiosError, caught) =>
            merge(
                of(register.rejected({ ...err.response?.data, code: err.response?.status })).pipe(
                    tap(() => {
                        // ignore analytics event for testing
                        if (process.env.NODE_ENV !== 'test') {
                            analytics.signUpErrorUsage(err?.message)
                        }
                    }),
                ),
                caught,
            ),
        ),
    )

export const checkEmailEpic: AppEpic = (actions$, $state, { apiAdapter }) =>
    actions$.pipe(
        filter(checkEmail.act.match),
        switchMap(({ payload }) => apiAdapter.checkUser(payload)),
        map(() => checkEmail.fulfilled()),
        catchError((err: Error, caught) => merge(of(checkEmail.rejected(err?.message)), caught)),
    )

export const logoutEpic: AppEpic = (actions$, $state, { apiAdapter }) =>
    actions$.pipe(
        filter(logout.act.match),
        switchMap(() => apiAdapter.logout()),
        map(() => logout.fulfilled()),
        catchError((err: Error, caught) => merge(of(logout.rejected(err?.message)), caught)),
    )

export const userEpic: AppEpic = (actions$, $state, { apiAdapter }) =>
    actions$.pipe(
        filter(fetchUser.act.match),
        switchMap(() => apiAdapter.fetchUser()),
        map(result => fetchUser.fulfilled(result)),
        catchError((err: Error, caught) => merge(of(fetchUser.rejected(err?.message)), caught)),
    )

export const confirmEmailEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(confirmEmail.act.match),
        switchMap(({ payload }) => apiAdapter.confirmUserEmail(payload)),
        mergeMap(() => of(confirmEmail.fulfilled(), fetchUser.act())),
        catchError((err: Error, caught) =>
            merge(
                of(confirmEmail.rejected(err?.message)).pipe(
                    tap(() => {
                        // ignore analytics event for testing
                        if (process.env.NODE_ENV !== 'test') {
                            analytics.verificationErrorUsage(err?.message)
                        }
                    }),
                ),
                caught,
            ),
        ),
    )

export const resendCodeEpic: AppEpic = (actions$, $state, { apiAdapter }) =>
    actions$.pipe(
        filter(resendCode.act.match),
        switchMap(({ payload }) => apiAdapter.resendCode(payload)),
        map(() => resendCode.fulfilled()),
        catchError((err: Error, caught) => merge(of(resendCode.rejected(err?.message)), caught)),
    )

export const authFlowEpic: AppEpic = (actions$, $state) =>
    actions$.pipe(
        filter(fetchUser.fulfilled.match),
        filter(() => open($state.value)),
        mergeMap(() => {
            // const authPopup = getType($state.value)
            const userVerified = isUserVerified($state.value)
            const exportIsWaiting = exportSelectors.exportPopupIsWaiting($state.value)
            const actions: AnyAction[] = [store.authUIState.actions.setOpen(false)]

            if (exportIsWaiting && userVerified) {
                actions.push(exportActions.handleExport())
            }

            if (!userVerified) {
                actions.push(store.authUIState.actions.changeAuth('verification'))
                actions.push(store.authUIState.actions.setOpen(true))
            }

            return of(...actions)
        }),
    )

export const loginAnalyticsEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(login.fulfilled.match),
        tap(() => {
            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                const authPopupType = getType($state.value)
                if (authPopupType === 'login') {
                    analytics.signInSuccessUsage()
                }
            }
        }),
        ignoreElements(),
    )
export const registrationAnalyticsEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(register.fulfilled.match),
        tap(() => {
            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                const authPopupType = getType($state.value)
                if (authPopupType === 'registration') {
                    analytics.signUpSuccessUsage()
                }
            }
        }),
        ignoreElements(),
    )
export const confirmEmailAnalyticsEpic: AppEpic = (actions$, $state, { apiAdapter, analytics }) =>
    actions$.pipe(
        filter(confirmEmail.fulfilled.match),
        tap(() => {
            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.verificationSuccessUsage()
            }
        }),
        ignoreElements(),
    )
