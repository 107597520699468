import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'
import { getRectVertices } from '../services/Shapes'
import { RectangleVertices, Vector2D } from '@shared/types'
import { calculateCenter, calculateScale, calculateScaledSize } from '../utilities/image'
import { initialState } from './store.ui'

const getStore = (state: RootState) => state.uiRenderer

export const getImage = createSelector(getStore, state => state.image)
export const getImageOriginSize = createSelector(getImage, image => image.originSize)
export const getImageScale = createSelector(getImage, image => image.scale)
export const getContainerSize = createSelector(getStore, state => state.container)
export const getImageSize = createSelector([getImageOriginSize, getImageScale], calculateScaledSize)
export const getImageFlip = createSelector(getImage, image => image.flip)
export const getImagePosition = createSelector(getImage, image => image.position)
export const getImageRotation = createSelector(getImage, image => image.rotation)
export const getImageDragStatus = createSelector(getImage, image => image.draggable)
export const getImageRotatingStatus = createSelector(getImage, image => image.rotating)
export const getImageVertices = createSelector(
    getImageSize,
    getImagePosition,
    getImageRotation,
    (size, rotationPoint, rotationDeg): RectangleVertices => getRectVertices(rotationPoint, size, rotationDeg),
)
export const getImageOffset = createSelector(getImageSize, calculateCenter)

export const getDefaultImageScale = createSelector([getImageOriginSize, getContainerSize], (size, containerSize) => {
    const scale = calculateScale(size, containerSize)

    return Number.isNaN(scale) ? initialState.image.scale : scale
})

export const getCanvasSize = createSelector(getStore, state => state.canvas)

export const getContainerOffset = createSelector(getContainerSize, getImageSize, (container, imageSize): Vector2D => {
    const containerCenter = calculateCenter(container)
    const imageCenter = calculateCenter(imageSize)

    return {
        x: imageCenter.x - containerCenter.x,
        y: imageCenter.y - containerCenter.y,
    }
})

/**
 * Crop selectors
 */
export const getCrop = createSelector(getStore, state => state.crop)
export const cropIsEnabled = createSelector(getCrop, crop => crop.enabled)
export const getCropScale = createSelector(getCrop, crop => crop.scale)
export const getCropRatioStatus = createSelector(getCrop, crop => crop.keepRatio)
export const getCropSize = createSelector(getCrop, crop => crop.size)
export const getCropPosition = createSelector(getCrop, crop => crop.position)
export const getCropOffset = createSelector(getCropSize, calculateCenter)
export const getCropCenter = createSelector(
    getCropPosition,
    (position): Vector2D => ({
        x: position.x,
        y: position.y,
    }),
)

export const getCropScaledSize = createSelector(getCropSize, getCropScale, calculateScaledSize)
export const getCanvasAnimationState = createSelector(getStore, store => store.canvasState)

export const getCropVertices = (state: RootState) => getRectVertices(getCropCenter(state), getCropSize(state), 0)
