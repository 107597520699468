import { Vector2D } from '@shared/types'
import { arrayToVector } from '../MiplAdapter'

export const defaultEffectsMap = [
    { effectId: 'MIPLDevelopCommonEffectID', paramaterId: 'Exposure' },
    { effectId: 'MIPLDevelopCommonEffectID', paramaterId: 'Contrast' },
    { effectId: 'MIPLDevelopCommonEffectID', paramaterId: 'Highlights' },
    { effectId: 'MIPLDevelopCommonEffectID', paramaterId: 'Shadows' },
    { effectId: 'MIPLBlackWhiteEffect', paramaterId: 'Whites' },
    { effectId: 'MIPLBlackWhiteEffect', paramaterId: 'Blacks' },
]

const curvesEffectsMap = [
    { effectId: 'MIPLCurveEffect', paramaterId: 'RGB' },
    { effectId: 'MIPLCurveEffect', paramaterId: 'R' },
    { effectId: 'MIPLCurveEffect', paramaterId: 'G' },
    { effectId: 'MIPLCurveEffect', paramaterId: 'B' },
]

export type ParametersMap = Record<string, Record<string, MParameter>>

type ParameterValue = {
    effect: string
    parameter: {
        name: string
        value: number
    } & Record<string, unknown>
}

type CallFunc = ({ value }: { value: unknown }, deps: { parameters: ParametersMap }) => { parameter: ParameterValue }

type InitialProps = { getMipl: () => MiplRawModule }

export const effects = [...defaultEffectsMap, ...curvesEffectsMap]

export const getCurves = ({ getMipl }: InitialProps) =>
    curvesEffectsMap.reduce<Record<string, Record<string, CallFunc>>>(
        (acc, { effectId, paramaterId }) => ({
            ...acc,
            [effectId]: {
                ...acc[effectId],
                [paramaterId]: ({ value }) => {
                    if (!Array.isArray(value)) {
                        throw Error(`Wrong args ${value}`)
                    }
                    const val = value as Vector2D[]
                    const mipl = getMipl()

                    return {
                        parameter: prepareCurveParameters({ effectId, paramaterId, value: val }, { mipl }),
                    }
                },
            },
        }),
        {},
    )

export const prepareCurveParameters = (
    {
        effectId,
        paramaterId,
        value,
    }: {
        effectId: string
        paramaterId: string
        value: Vector2D[]
    },
    { mipl }: { mipl: MiplRawModule },
): ParameterValue => {
    const optionalData = new mipl.OptionalData()
    const dt = arrayToVector<number>(
        value.reduce((acc, item) => [...acc, item.x, item.y], [0.5]),
        new mipl.VectorFloat(),
    )
    optionalData.VectorFloat = dt

    return {
        effect: effectId,
        parameter: {
            name: paramaterId,
            value: 50,
            optionalData,
        },
    }
}
