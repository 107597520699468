import { waitFor } from '@shared/utility/epics'
import { of, timer } from 'rxjs'
import { take, tap, ignoreElements, filter, switchMap, takeUntil } from 'rxjs/operators'
import { appInit, observablesInit } from './actions'
import { AppEpic } from './configure-store'

export const rxInit: AppEpic = () => of(observablesInit()).pipe(take(1))

export const appleHandle: AppEpic = () =>
    waitFor(() => !!global.window).pipe(
        switchMap(() =>
            waitFor(() => !!global.document && !!global.document.referrer, { interval: 500 }).pipe(
                takeUntil(timer(20 * 1000)),
                filter(() => global.document.referrer === 'https://appleid.apple.com/'),
                tap(() => window.close()),
                ignoreElements(),
            ),
        ),
    )

export const proxyEpic: AppEpic = (actions$, state$, { actions }) =>
    actions$.pipe(
        tap(action => actions.next(action)),
        ignoreElements(),
    )

export const initAnalitics: AppEpic = (actions$, _state$, { analytics }) =>
    actions$.pipe(
        filter(appInit.match),
        take(1),
        switchMap(() => waitFor(() => analytics.isReady(), { interval: 200 })),
        tap(() => analytics.init()),
        ignoreElements(),
    )
