import { Size2D, Vector2D } from '@shared/types'

export const calculateScaledSize = (iSize: Size2D, scale: number): Size2D => ({
    width: iSize.width * scale,
    height: iSize.height * scale,
})

export const calculateCenter = (iSize: Size2D): Vector2D => ({
    x: iSize.width / 2,
    y: iSize.height / 2,
})

export const calculateScale = (imageSize: Size2D, containerSize: Size2D): number => {
    const imageW = imageSize.width || containerSize.width
    const imageH = imageSize.height || containerSize.height
    const scale = Math.min(containerSize.width / imageW, containerSize.height / imageH)

    return scale
}

type Props = {
    originSize: Size2D
    currentPosition: Vector2D
    newScale: number
    oldScale: number
}
export const getNewImagePositionByScale = ({ originSize, newScale, oldScale, currentPosition }: Props): Vector2D => {
    const scaledImageSize = calculateScaledSize(originSize, newScale)
    const currentScaledSize = calculateScaledSize(originSize, oldScale)

    const diff: Size2D = {
        width: scaledImageSize.width - currentScaledSize.width,
        height: scaledImageSize.height - currentScaledSize.height,
    }
    const scalePosition: Vector2D = {
        x: currentPosition.x + diff.width / 2,
        y: currentPosition.y + diff.height / 2,
    }

    return scalePosition
}
