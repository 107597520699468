import { createAction } from '@reduxjs/toolkit'
import { UserDTO } from '@shared/server/dto'

const fetch_string = 'auth/login'
const registerUser = 'auth/register'
const logoutUser = 'auth/logout'
const fetch_user = 'auth/user'
const check_email = 'auth/checkEmail'
const confirm_email = 'auth/confirmEmail'
const resend_code = 'auth/resendCode'
const reset_password = 'auth/sendResetPassword'
const reset_code = 'auth/resetCode'
const update_password = 'auth/updatePassword'

export const login = {
    act: createAction<{ email: string; password: string }>(`${fetch_string}/pending`),
    fulfilled: createAction(`${fetch_string}/fulfilled`),
    rejected: createAction<unknown>(`${fetch_string}/rejected`),
}

export const checkEmail = {
    act: createAction<{ email: string }>(`${check_email}/pending`),
    fulfilled: createAction(`${check_email}/fulfilled`),
    rejected: createAction<unknown>(`${check_email}/rejected`),
}

export const logout = {
    act: createAction(`${logoutUser}/pending`),
    fulfilled: createAction(`${logoutUser}/fulfilled`),
    rejected: createAction<unknown>(`${logoutUser}/rejected`),
}

export const register = {
    act: createAction<{ email: string; password: string }>(`${registerUser}/pending`),
    fulfilled: createAction(`${registerUser}/fulfilled`),
    rejected: createAction<unknown>(`${registerUser}/rejected`),
}

export const fetchUser = {
    act: createAction(`${fetch_user}/pending`),
    fulfilled: createAction<UserDTO>(`${fetch_user}/fulfilled`),
    rejected: createAction<unknown>(`${fetch_user}/rejected`),
}

export const confirmEmail = {
    act: createAction<{ email: string; code: string }>(`${confirm_email}/pending`),
    fulfilled: createAction(`${confirm_email}/fulfilled`),
    rejected: createAction<unknown>(`${confirm_email}/rejected`),
}

export const resendCode = {
    act: createAction<{ email: string }>(`${resend_code}/pending`),
    fulfilled: createAction(`${resend_code}/fulfilled`),
    rejected: createAction<unknown>(`${resend_code}/rejected`),
}

export const resetPassword = {
    act: createAction<{ email: string }>(`${reset_password}/pending`),
    fulfilled: createAction<{ email: string }>(`${reset_password}/fulfilled`),
    rejected: createAction<unknown>(`${reset_password}/rejected`),
}

export const resetCode = {
    act: createAction<{ pin: string }>(`${reset_code}/pending`),
    fulfilled: createAction<{ pin: string }>(`${reset_code}/fulfilled`),
    rejected: createAction<unknown>(`${reset_code}/rejected`),
}

export const updatePassword = {
    act: createAction<{ password: string }>(`${update_password}/pending`),
    fulfilled: createAction<{ password: string }>(`${update_password}/fulfilled`),
    rejected: createAction<unknown>(`${update_password}/rejected`),
}
