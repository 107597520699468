import { RectangleVertices, Vector2D, Vector2DTuple } from '@shared/types'
import { convertTupleToVector, convertVectorToTuple } from '../utilities'

type IntersectionItem = { coordinates: Vector2DTuple | null; distances: number[] | null; cropVerticeIndex: number }
type IntersectionReturn = IntersectionItem[]
export const getIntersections = (
    center: Vector2DTuple,
    cropVertices: RectangleVertices,
    imageVertices: RectangleVertices,
): IntersectionReturn => {
    return cropVertices
        .map((cropVertice, idx) =>
            imageVertices.map((_, imageIndex) => {
                const result = getCrossPoint(
                    center,
                    cropVertice,
                    imageVertices[imageIndex],
                    imageVertices[(imageIndex + 1) % imageVertices.length],
                )

                return {
                    coordinates: result?.point ?? null,
                    distances: result?.lengths ?? null,
                    cropVerticeIndex: idx,
                    cropInfo: {
                        cropVertice,
                        imageIndex,
                        nextIndex: (imageIndex + 1) % imageVertices.length,
                        imageVertices: [
                            imageVertices[imageIndex],
                            imageVertices[(imageIndex + 1) % imageVertices.length],
                        ],
                    },
                }
            }),
        )
        .reduce((acc, item) => [...acc, ...item], [])
}

/**
 * To get know if any of crop frame vertices are out of image bounds
 */
export const pointsIsInRectangle = (cropVertices: RectangleVertices, imageVertices: RectangleVertices): boolean => {
    let result = true
    for (let index = 0; index < cropVertices.length; index++) {
        const point = cropVertices[index]
        result = pointIsInRectangle(imageVertices, point)
        if (!result) {
            break
        }
    }

    return result
}

/**
 * Implementation based on theroy that sum of areas of triangles formed with two rectangle vertices
 * and point within it is the same as area of rectangle as well
 * {@link https://martin-thoma.com/how-to-check-if-a-point-is-inside-a-rectangle/ explanation}
 */
const pointIsInRectangle = ([B, C, D, A]: RectangleVertices, [xP, yP]: Vector2DTuple): boolean => {
    const [xA, yA] = A
    const [xB, yB] = B
    const [xC, yC] = C
    const [xD, yD] = D
    const getHalf = (a: number) => 0.5 * Math.abs(a)
    // Find rectangle area
    const ABCD = getHalf((yA - yC) * (xD - xB) + (yB - yD) * (xA - xC))
    // Find triangle areas
    const ABP = getHalf(xA * (yB - yP) + xB * (yP - yA) + xP * (yA - yB))
    const BCP = getHalf(xB * (yC - yP) + xC * (yP - yB) + xP * (yB - yC))
    const CDP = getHalf(xC * (yD - yP) + xD * (yP - yC) + xP * (yC - yD))
    const DAP = getHalf(xD * (yA - yP) + xA * (yP - yD) + xP * (yD - yA))
    return !(ABCD < ABP + BCP + CDP + DAP)
}

export type MaximumIntersectionReturn = { coordinates: Vector2DTuple; distances: number[]; cropVerticeIndex: number }

export const getMaximumIntersectedDifference = (
    intersections: IntersectionReturn,
): MaximumIntersectionReturn | null => {
    const initialValue: MaximumIntersectionReturn = { distances: [0, 0], cropVerticeIndex: -1, coordinates: [0, 0] }
    const result = intersections.reduce((acc: MaximumIntersectionReturn, item) => {
        if (!item.distances || !item.coordinates) return acc
        return acc.distances[1] - acc.distances[0] < item.distances[1] - item.distances[0]
            ? {
                  cropVerticeIndex: item.cropVerticeIndex,
                  distances: item.distances,
                  coordinates: item.coordinates,
              }
            : acc
    }, initialValue)

    return result.cropVerticeIndex === initialValue.cropVerticeIndex ? null : result
}

export function getCrossPoint(
    line1Start: Vector2DTuple,
    line1End: Vector2DTuple,
    line2Start: Vector2DTuple,
    line2End: Vector2DTuple,
): { point: Vector2DTuple; lengths: number[] } | null {
    const [x1, y1] = line1Start
    const [x2, y2] = line1End
    const [x3, y3] = line2Start
    const [x4, y4] = line2End

    const isSegmentIsOn = checkLineSegment(line1Start, line1End, line2Start, line2End)
    if (!isSegmentIsOn) return null

    const dot: Vector2DTuple = [0, 0]

    let n
    if (y2 - y1 != 0) {
        // a(y)
        const q = (x2 - x1) / (y1 - y2)
        const sn = x3 - x4 + (y3 - y4) * q
        if (!sn) {
            return null
        } // c(x) + c(y)*q
        const fn = x3 - x1 + (y3 - y1) * q // b(x) + b(y)*q
        n = fn / sn
    } else {
        if (!(y3 - y4)) {
            return null
        } // b(y)
        n = (y3 - y1) / (y3 - y4) // c(y)/b(y)
    }
    dot[0] = x3 + (x4 - x3) * n // x3 + (-b(x))*n
    dot[1] = y3 + (y4 - y3) * n // y3 +(-b(y))*n

    const originDistance = calculateLineLength(line1Start, line1End)
    const originDistance2 = calculateLineLength(line1Start, dot)

    if (originDistance <= originDistance2 || originDistance - originDistance2 < 0.005) {
        return null
    }
    return {
        point: dot,
        lengths: [originDistance2, originDistance],
    }
}

const checkLineSegment = (
    line1Start: Vector2DTuple,
    line1End: Vector2DTuple,
    line2Start: Vector2DTuple,
    line2End: Vector2DTuple,
): boolean => {
    const [x1, y1] = line1Start
    const [x2, y2] = line1End
    const [x3, y3] = line2Start
    const [x4, y4] = line2End

    const denominator = (y4 - y3) * (x1 - x2) - (x4 - x3) * (y1 - y2)
    if (denominator === 0) return false

    const numerator_a = (x4 - x2) * (y4 - y3) - (x4 - x3) * (y4 - y2)
    const numerator_b = (x1 - x2) * (y4 - y2) - (x4 - x2) * (y1 - y2)
    const Ua = numerator_a / denominator
    const Ub = numerator_b / denominator

    if (!(Ua >= 0 && Ua <= 1 && Ub >= 0 && Ub <= 1)) {
        return false
    }

    return true
}

/**
 * Uses mostly to find the new on of {@link https://math.stackexchange.com/questions/436767/move-point-a-along-a-line segment}
 */
export const shiftPointOnSegment = (point1: Vector2D, point2: Vector2D, shiftDistance: number): Vector2D => {
    const point1Tuple = convertVectorToTuple(point1)
    const point2Tuple = convertVectorToTuple(point2)
    const koeff = shiftDistance / calculateLineLength(point1Tuple, point2Tuple)
    const newPoints = sumOfVectors(
        [point2Tuple[0] * (1 - koeff), point2Tuple[1] * (1 - koeff)],
        [point1Tuple[0] * koeff, point1Tuple[1] * koeff],
    )

    return convertTupleToVector(newPoints)
}

export const calculateLineLength = (point1: Vector2DTuple, point2: Vector2DTuple): number => {
    const xDist = point1[0] - point2[0]
    const yDist = point1[1] - point2[1]

    return Math.sqrt(xDist * xDist + yDist * yDist)
}

export const sumOfVectors = (point1: Vector2DTuple, point2: Vector2DTuple): Vector2DTuple => {
    const xDist = point1[0] + point2[0]
    const yDist = point1[1] + point2[1]

    return [xDist, yDist]
}
