import * as t from 'io-ts'
import {
    BinDTO,
    PresetDTO,
    PresetsCategoryDTO,
    PresetsCollectionDTO,
    ToolsPanelDTO,
    EffectsLayoutInner,
    SkyTexturesDTO,
    UserDTO,
} from '@shared/server/dto'

export const PresetCategoriesPayload: t.Type<PresetsCategoryDTO> = t.type({
    version: t.number,
    categories: t.array(
        t.type({
            id: t.string,
            name: t.string,
            updatedAt: t.string,
            description: t.string,
            version: t.number,
            tags: t.array(t.string),
            collections: t.array(t.string),
        }),
    ),
})

export const CollectionPayload: t.Type<PresetsCollectionDTO> = t.type({
    id: t.string,
    name: t.string,
    version: t.number,
    createdAt: t.string,
    tags: t.array(t.string),
    presets: t.array(t.string),
    description: t.string,
    icon: t.partial({ big: t.string, small: t.string }),
    details: t.string,
})

export const PresetPayload: t.Type<PresetDTO> = t.type({
    id: t.string,
    name: t.string,
    creationInfo: t.record(t.string, t.unknown),
    icon: t.partial({
        small: t.string,
    }),
    layers: t.array(t.record(t.string, t.unknown)),
})

export const BinPayload: t.Type<BinDTO> = t.type({
    version: t.number,
    libs: t.array(
        t.type({
            name: t.literal('enhance'),
            // t.union([t.literal('enhance'), t.literal('sky')]),
            url: t.string,
            sum: t.string,
        }),
    ),
})

//TODO: Have some union of string literals that don't want to describe here
// To find the workaround(non-strict???)
export const PanelPayload = t.array(
    t.type({
        imageName: t.string,
        hint: t.string,
        title: t.string,
        type: t.string,
        identifier: t.string,
        items: t.array(
            t.type({
                identifier: t.string,
                title: t.string,
                hint: t.string,
                type: t.string,
                items: t.array(
                    t.type({
                        type: t.string,
                        identifier: t.string,
                    }),
                ),
            }),
        ),
    }),
) as t.Type<ToolsPanelDTO>

// TODO: Much more variations, use it when handled all use cases("type" variations)
export const EffectPayload = t.record(
    t.string,
    t.intersection([
        t.type({
            id: t.string,
            items: t.array(
                t.array(
                    t.type({
                        type: t.string,
                        effect: t.string,
                        parameter: t.string,
                    }),
                ),
            ),
        }),
        t.partial({
            title: t.string,
            expanded: t.boolean,
            expandable: t.boolean,
        }),
    ]),
) as t.Type<Record<string, EffectsLayoutInner>>

export const SkyTexturePayload: t.Type<SkyTexturesDTO> = t.record(t.string, t.array(t.string))

export const UserPayload: t.Type<UserDTO> = t.type({
    email: t.string,
    verified: t.boolean,
})
