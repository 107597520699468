export { dataToImage } from './dataToImage'
export * from './rectInRect'
import { Size2D, Vector2D, Vector2DTuple } from '@shared/types'

/**
 *
 * @param payload Already scaled size of image and applied ratio of crop rectangle
 * @returns `Size2d`
 */
export const adoptCropFromToRatio = (
    { image: rawImage, ratio }: { image: Size2D; ratio: Size2D | undefined },
    buffer = 2,
): Size2D => {
    const image: Size2D = {
        width: rawImage.width - buffer,
        height: rawImage.height - buffer,
    }
    const defaultReturn = {
        ...image,
    }
    if (!ratio) return defaultReturn
    if (ratio.height === 0 && ratio.width === 0) return defaultReturn

    const fraction = (image.width + image.height) / (ratio.width + ratio.height)
    const newWidth = ratio.width * fraction
    const newHeight = ratio.height * fraction
    const scale = Math.min(image.width / (newWidth || image.width), image.height / (newHeight || image.height))
    const scaledW = newWidth * scale
    const scaledH = newHeight * scale

    return {
        width: scaledW,
        height: scaledH,
    }
}

/**
 *
 * @param n The target float
 * @param d number after dot to round up, to decide the required precision of floats
 * @returns `roundOne(.9915, 3) --> 0.992`
 */
export const roundOne = (n: number, d = 0): number => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)

export const convertVectorToTuple = (point: Vector2D): Vector2DTuple => [point.x, point.y]

export const convertTupleToVector = (point: Vector2DTuple): Vector2D => ({ x: point[0], y: point[1] })
