import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExportTypes } from '../types'

type State = {
    status: 'idle' | 'pending'
    type: ExportTypes | null
    isLoading: boolean
}
const initialState: State = {
    status: 'idle',
    type: null,
    isLoading: false,
}

export const slice = createSlice({
    name: 'exportStore',
    initialState: initialState,
    reducers: {
        toggleStatus(state, { payload }: PayloadAction<State['status']>) {
            state.status = payload
        },
        setDesiredType(state, { payload }: PayloadAction<ExportTypes | null>) {
            state.type = payload
        },
        setLoading(state, { payload }: PayloadAction<boolean>) {
            state.isLoading = payload
        },
        reset() {
            return initialState
        },
    },
})
