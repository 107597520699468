import { ExportTypes } from './types'

export function initiateDownloadImage(
    canvas: HTMLCanvasElement,
    { type = 'png', name = 'default' }: { type?: ExportTypes; name?: string } = {},
): void {
    const link = document.createElement('a')
    link.download = `${name}-skylum.${type}`
    link.href = canvas.toDataURL(CanvasMimeTypes[type])
    link.click()
    link.remove()
}

const CanvasMimeTypes: Record<ExportTypes, string> = {
    jpeg: 'image/jpeg',
    png: 'image/png',
    jpg: 'image/jpeg',
}
