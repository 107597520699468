import { AppEpic } from '@store/configure-store'
import { of, concat, merge } from 'rxjs'
import {
    filter,
    map,
    switchMap,
    catchError,
    mergeMap,
    distinctUntilChanged,
    takeUntil,
    ignoreElements,
} from 'rxjs/operators'
import { store, actions, editsSelectors, uiSelectors } from '@features/toolsPanel/store'
import { find as _find, last as _last } from 'lodash-es'
import { getEdits } from '@features/renderer/store/actions.mipl'

export const editsEpic: AppEpic = (_actions$, _store$, { miplChannels }) => {
    return miplChannels.miplChannel.pipe(
        filter(getEdits.match),
        mergeMap(({ payload }) => {
            const { setEdits, setCurrent } = store.Edits.actions
            const current = _find(payload, data => data.isCurrent)

            return concat(
                of(setEdits(payload)),
                payload.length > 0
                    ? of(
                          !!current
                              ? setCurrent(current.identifier)
                              : actions.setCurrentEdit(_last(payload)!.identifier),
                      )
                    : of(setCurrent(null)),
            )
        }),
    )
}

export const currentEditEpic: AppEpic = (actions$, _store$, { mipl }) => {
    return actions$.pipe(
        filter(actions.setCurrentEdit.match),
        map(({ payload }) => mipl.setEdit(payload)),
        ignoreElements(),
    )
}

export const disableEditEpic: AppEpic = (actions$, store$, { mipl, analytics }) => {
    return merge(
        actions$.pipe(
            filter(actions.disableCurrentEdit.match),
            mergeMap(({ payload }) => mipl.disableCurrentEffect(payload)),
            ignoreElements(),
        ),
        actions$.pipe(
            filter(actions.disableCurrentEdit.match),
            filter(action => action.payload.disable),
            switchMap(() => analytics.editsUsage('eye_icon_click')),
            ignoreElements(),
        ),
    )
}

export const removeEditEpic: AppEpic = (actions$, store$, { mipl, analytics }) => {
    return merge(
        actions$.pipe(
            filter(actions.removeCurrentEdit.match),
            mergeMap(({ payload }) => mipl.removeCurrentEdit(payload)),
            ignoreElements(),
        ),
        actions$.pipe(
            filter(actions.removeCurrentEdit.match),
            switchMap(() => analytics.editsUsage('delete_separate_edit')),
            ignoreElements(),
        ),
    )
}

export const removeAllEditsEpic: AppEpic = (actions$, store$, { mipl, analytics }) => {
    return merge(
        actions$.pipe(
            filter(actions.removeAllEdits.match),
            map(() => uiSelectors.getOpenedTools(store$.value)),
            mergeMap(toolIds => toolIds.map(actions.onResetEffectInUI)),
        ),
        actions$.pipe(
            filter(actions.removeAllEdits.match),
            switchMap(() => mipl.removeAllEdits()),
            ignoreElements(),
        ),
        actions$.pipe(
            filter(actions.removeAllEdits.match),
            switchMap(() => analytics.editsUsage('delete_all')),
            ignoreElements(),
        ),
    )
}

export const resetImageEdits: AppEpic = (actions$, _, { mipl }) =>
    actions$.pipe(
        filter(actions.onDisableAllEdits.match),
        mergeMap(({ payload }) => mipl.disableAllEdits({ disable: payload })),
        ignoreElements(),
    )
