import { CategoryId, ToolId } from '@domain/filters'
import { effectType } from '@features/toolsPanel/components/Tools/Crop/constants'
import { createSelector, createDraftSafeSelector, CombinedState } from '@reduxjs/toolkit'
import { EditsInfo } from '@shared/constants/editsInfo'
import { RootState } from '@store/configure-store'
import { RatioOptions } from '../../../constants'
import { EntityCategories, EntityTools } from '../../../models'
import { getFormKey } from '../../../utils/getFormKey'

const selectSelf = (state: RootState) => state.tools
const getCategoriesEnt = createDraftSafeSelector(selectSelf, panel => panel.tools.entities.categories)
const getCategoriesIds = createDraftSafeSelector(selectSelf, panel => panel.tools.result)

export const getCategories = createDraftSafeSelector(
    getCategoriesEnt,
    getCategoriesIds,
    (entities, ids) => ids.map((id: CategoryId) => entities[id]) as EntityCategories[],
)

export const getToolEntities = createDraftSafeSelector(
    selectSelf,
    panel => panel.tools.entities.tools as unknown as EntityTools,
)

export const getToolById = (id: ToolId) =>
    createSelector(getToolEntities, (tools): EntityTools['AIBokehEffect'] & { title?: React.ReactNode } => ({
        ...tools[id],
        ...(EditsInfo as any)[id],
    }))

// effects
const selectSelfEffects = (state: RootState) => state.effects

export const getEffectById = (id: ToolId) => createSelector(selectSelfEffects, ({ items }) => items[id] ?? null)

export const getEffectDefaultValue = (id: ToolId): Record<string, any> | undefined => {
    return defaultValues[id]
}

const defaultValues: Partial<Record<ToolId, Record<string, any>>> = {
    MPCropToolFilter: {
        [getFormKey(effectType, 'Ratio')]: {
            id: RatioOptions[2].id,
        },
    },
    ExtendedColorAdjustmentLayer: {
        'MIPLDevelopCommonEffectID-whiteBalance': 0,
    },
}
