import createCache, { EmotionCache } from '@emotion/cache'
import { prefixer } from 'stylis'
// prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.

// In other words!!!
// We assume, what user making directly in component have much more priority that in css/scss files
export function createStyleCache(): EmotionCache {
    return createCache({ key: 'css', prepend: true, stylisPlugins: [prefixer] })
}
