// TODO: Should be extracted in separate service like MiplAdapter
const nonlinearMap = (value: number, min: number, max: number, def: number, reverse: boolean): number => {
    let result
    const deltaMax = max - def
    const deltaMin = def - min
    if (reverse) {
        result =
            (100 * Math.log(((value - def) * (deltaMax - deltaMin)) / (deltaMax * deltaMin) + 1)) /
            Math.log(deltaMax / deltaMin)
    } else {
        result =
            ((deltaMax * deltaMin) / (deltaMax - deltaMin)) * (Math.pow(deltaMax / deltaMin, value / 100) - 1) + def
    }

    return Math.round(result)
}

export const mappedTemperature = (temperature: number): number => {
    const maxVal = 50000
    const minVal = 2000
    const defVal = 6500
    return nonlinearMap(temperature, minVal, maxVal, defVal, true)
}

export const processingTint = (tint: number): number => {
    return Math.max(Math.min(tint, 100), -100)
}
