export const extendedColorEffectsMap = [
    { effectId: 'MIPLDevelopCommonEffectID', paramaterId: 'Temperature' },
    { effectId: 'MIPLDevelopCommonEffectID', paramaterId: 'Tint' },
    { effectId: 'MIPLSaturationEffect', paramaterId: 'Saturation' },
    { effectId: 'MIPLVibranceEffect', paramaterId: 'Vibrance' },
    { effectId: 'MIPLRemoveColorCastEffect', paramaterId: 'Amount' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hRed' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hOrange' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hYellow' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hGreen' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hAqua' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hBlue' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hPurple' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'hMagenta' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sRed' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sOrange' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sYellow' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sGreen' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sAqua' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sBlue' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sPurple' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'sMagenta' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lRed' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lOrange' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lYellow' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lGreen' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lAqua' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lBlue' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lPurple' },
    { effectId: 'MIPLChannelsEffect', paramaterId: 'lMagenta' },
    { effectId: 'MIPLHueShiftEffect', paramaterId: 'Hue' },
]
