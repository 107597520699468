import { appInit } from '@store/actions'
import { AppEpic, EpicPayload } from '@store/configure-store'
import { of, ignoreElements, take, merge, zip, from, concat, timer } from 'rxjs'
import {
    filter,
    map,
    switchMap,
    catchError,
    distinctUntilChanged,
    debounceTime,
    mergeMap,
    tap,
    takeUntil,
} from 'rxjs/operators'
import { actions, store } from '..'
import { getCurrentImage } from '../selectors'
import { getContainerSize, getImageOriginSize, getDefaultImageScale, getContainerOffset } from '../selectors.ui'
import { store as toolsStore } from '@features/toolsPanel/store'
import { isEqual } from 'lodash-es'
import { dataToImage, getShiftCoordinates } from '@features/renderer/utilities'
import { onDisableCurrentEffect, onResetEffectInMipl, zoomImage } from '@features/toolsPanel/store/actions'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import { ModelNames } from '@shared/server/dto'
import { setRenderData } from '@features/renderer/store/actions.mipl'
import { IMAGE_DATA_CACHE_KEY } from '@shared/constants'
import { waitFor } from '@shared/utility/epics'
import { Size2D, Vector2D } from '@shared/types'
import { ScaleIds } from '@features/toolsPanel/types'
import { calculateCenter, calculateScaledSize } from '@features/renderer/utilities/image'
import { onZoomByHand } from '../actions'
import { selectPayload } from '@shared/utility/redux'
import { getNonRotatedRectVertices } from '@features/renderer/services/Shapes'

type UpdateStateReturn = ReturnType<typeof toolsStore.toolsState.actions.updateFormState>
// Hardcode, agreed with @Dmytro Mykhailyk
const modelToNames: Record<ModelNames, string> = {
    enhance: 'modified_exp125_new_v2_opencv_inter_topsort.onnx',
}
// time qty for desired FPS(60)
const DEBOUNCE_TIME = 16.6
const ANIMATION_TIME = 300

export const uploadImageEpic: AppEpic = (actions$, _, { apiAdapter, analytics, miplChannels }) =>
    merge(
        actions$.pipe(
            filter(actions.uploadImage.act.match),
            switchMap(({ payload }) => zip(apiAdapter.uploadImage(payload), timer(ANIMATION_TIME))),
            map(([image]) => actions.uploadImage.fulfilled(image)),
            catchError((err: Error, caught) => merge(of(actions.uploadImage.rejected(err.message)), caught)),
        ),
        actions$.pipe(
            filter(actions.uploadImage.act.match),
            tap(() => {
                analytics.photoUsage('add_to_album')
            }),
            map(() => store.uiSlice.actions.toggleCanvasStatus('animate-in')),
        ),
        actions$.pipe(
            filter(actions.uploadImage.rejected.match),
            map(() => store.uiSlice.actions.toggleCanvasStatus('idle')),
        ),
        actions$.pipe(
            filter(actions.uploadImage.fulfilled.match),
            tap(() => {
                analytics.photoUsage('successfully_loaded')
            }),
            switchMap(() =>
                miplChannels.miplChannel.pipe(
                    takeUntil(timer(10 * 1000)),
                    filter(setRenderData.match),
                    take(1),
                    map(() => store.uiSlice.actions.toggleCanvasStatus('idle')),
                ),
            ),
        ),
    )

export const initMiplEpic: AppEpic = (actions$, state$, { mipl, apiAdapter }) =>
    actions$.pipe(
        filter(appInit.match),
        take(1),
        mergeMap(() =>
            zip(
                apiAdapter.fetchModels(),
                waitFor(() => !!(window as any).Luminar),
            ),
        ),
        switchMap(([model]) =>
            from(mipl.init()).pipe(
                map(() => Object.keys(modelToNames) as ModelNames[]),
                mergeMap(modelKeys => zip(modelKeys.map(key => mipl.loadModel(model[key], modelToNames[key])))),
            ),
        ),
        catchError((err: Error) => of(console.log(err))),
        ignoreElements(),
    )

export const processImageEpic: AppEpic = (actions$, state$, { mipl, ...services }) => {
    const composedPayload: EpicPayload = { actions$, state$, services: { mipl, ...services } }
    return actions$.pipe(
        filter(actions.uploadImage.fulfilled.match),
        switchMap(() =>
            concat(
                of(zoomImage(0)),
                waitFor(() => mipl.initialized).pipe(
                    mergeMap(() =>
                        state$.pipe(
                            filter(state => getImageOriginSize(state).height > 0),
                            distinctUntilChanged(),
                            take(1),
                        ),
                    ),
                    mergeMap(() => (mipl.imageId ? reUpload(composedPayload) : of(null))),
                    mergeMap(() => dataToImage(getCurrentImage(state$.value)?.url)),
                    filter((payload): payload is Required<typeof payload> => !!payload.data),
                    mergeMap(({ data }) => mipl.loadImage(data)),
                    switchMap(data =>
                        merge(
                            onFormUpdate(composedPayload)(data),
                            onResetEdit(composedPayload),
                            onDisableEdit(composedPayload),
                        ),
                    ),
                    catchError((err: Error) => of(console.log(err))),
                    ignoreElements(),
                ),
            ),
        ),
    )
}

export const interceptMiplDataEpic: AppEpic = (actions$, state$, { miplChannels, localStorage }) =>
    miplChannels.miplChannel.pipe(
        filter(setRenderData.match),
        // Sync with the store has a lower priority, we might allow increased throttling time
        debounceTime(1500),
        switchMap(({ payload }) => localStorage.setItem<ImageData>(IMAGE_DATA_CACHE_KEY, payload)),
        ignoreElements(),
    )

const reUpload = ({ services: { mipl } }: EpicPayload) =>
    of(null).pipe(
        mergeMap(() => of(mipl.shutDown())),
        take(1),
    )
const onResetEdit = ({ actions$, services: { mipl } }: EpicPayload) =>
    actions$.pipe(
        filter(onResetEffectInMipl.match),
        filter(({ payload }) => payload !== 'MPCropToolFilter'),
        debounceTime(DEBOUNCE_TIME / 2),
        switchMap(({ payload }) => mipl.resetCurrentEdit(payload)),
    )
const onDisableEdit = ({ actions$, services: { mipl } }: EpicPayload) =>
    actions$.pipe(
        filter(onDisableCurrentEffect.match),
        switchMap(({ payload }) => mipl.disableCurrentEffect({ id: payload.tool, disable: payload.disable })),
    )

const onFormUpdate =
    ({ actions$, services: { mipl } }: EpicPayload) =>
    (data: Awaited<ReturnType<typeof mipl['loadImage']>>) =>
        actions$.pipe(
            filter(toolsStore.toolsState.actions.updateFormState.match),
            distinctUntilChanged<UpdateStateReturn>(({ payload: prevPayload }, { payload: nextPayload }) =>
                isEqual(prevPayload.values, nextPayload.values),
            ),
            map(({ payload }) => {
                const { meta, values } = payload
                const id = meta.id
                const wrapperKey = getFormKey(id, 'wrapper')

                return { payload, toolIsClosing: id && meta.name === wrapperKey && values?.[wrapperKey] === false }
            }),
            switchMap(({ payload, toolIsClosing }) => {
                if (toolIsClosing) return mipl.saveCurrentEdit(payload.meta.id)

                return mipl.applyEffect(data, mipl.prepareEffect(payload))
            }),
        )

export const performZoomEpic: AppEpic = (actions$, state$, services) => {
    const onZoomOptions = onZoomByOptionsHandler({ actions$, state$, services })
    const onZoomPointer = onZoomByPointerHandler({ actions$, state$, services })

    return merge(
        actions$.pipe(
            filter(zoomImage.match),
            map(all => selectPayload<ScaleIds>(all)),
            switchMap(onZoomOptions),
        ),
        actions$.pipe(
            filter(onZoomByHand.match),
            map(all => selectPayload<ReturnType<typeof onZoomByHand>['payload']>(all)),
            switchMap(({ position, scale }) => onZoomPointer(position, scale)),
        ),
    )
}

const MIN_ZOOM_VALUE: ScaleIds = 25
const MAX_ZOOM_VALUE: ScaleIds = 3000

const getImagePositionInBounds = (
    cSize: Size2D,
    iSize: Size2D,
    imagePosition: Vector2D,
    cOffset: Vector2D,
): Vector2D => {
    const shiftX = cOffset.x + iSize.width / 2
    const shiftY = cOffset.y + iSize.height / 2
    const leftTopCorner: Vector2D = {
        x: imagePosition.x - shiftX,
        y: imagePosition.y - shiftY,
    }
    const newVertices = getNonRotatedRectVertices(leftTopCorner, iSize)
    const shift = getShiftCoordinates(newVertices, cSize)

    return {
        x: imagePosition.x + shift.x,
        y: imagePosition.y + shift.y,
    }
}
const onZoomByPointerHandler =
    ({ state$ }: EpicPayload) =>
    (position: Vector2D, scale: number) => {
        const { setScale, setPosition, setDragStatus } = store.uiSlice.actions
        const boundScale = Math.min(Math.max(scale, MIN_ZOOM_VALUE / 100), MAX_ZOOM_VALUE / 100)
        const reachedTheBounds = boundScale === MIN_ZOOM_VALUE / 100 || boundScale === MAX_ZOOM_VALUE / 100
        const defaultScale = getDefaultImageScale(state$.value)
        const imageIsLessThanCanvas = defaultScale > boundScale
        const originImageSize = getImageOriginSize(state$.value)
        const newSize = calculateScaledSize(originImageSize, boundScale)
        const correctedPosition = imageIsLessThanCanvas ? calculateCenter(newSize) : position
        const containerSize = getContainerSize(state$.value)
        const containerOffset = getContainerOffset(state$.value)
        const newPos = imageIsLessThanCanvas
            ? correctedPosition
            : getImagePositionInBounds(containerSize, newSize, correctedPosition, containerOffset)

        return [
            setScale({ type: 'image', scale: boundScale }),
            ...(reachedTheBounds
                ? []
                : [
                      setPosition({
                          type: 'image',
                          position: newPos,
                      }),
                  ]),
            setDragStatus(!imageIsLessThanCanvas),
        ]
    }

const onZoomByOptionsHandler =
    ({ state$ }: EpicPayload) =>
    (scaleId: ScaleIds) => {
        const getNewPosition = (iSize: Size2D): Vector2D => {
            return {
                x: iSize.width / 2,
                y: iSize.height / 2,
            }
        }
        const getNewScale = (value: ScaleIds): number => {
            const containerSize = getContainerSize(state$.value)
            const imageSize = getImageOriginSize(state$.value)
            return value === 0 ? getImageDefaultScale(imageSize, containerSize) : value / 100
        }

        const { setScale, setPosition, setDragStatus } = store.uiSlice.actions
        const scale = getNewScale(scaleId)
        const imageSize = getImageOriginSize(state$.value)
        const newImageSize = calculateScaledSize(imageSize, scale)

        return [
            setScale({ type: 'image', scale }),
            setPosition({ type: 'image', position: getNewPosition(newImageSize) }),
            setDragStatus(scaleId !== 0),
        ]
    }

const getImageDefaultScale = (iSize: Size2D, cSize: Size2D): number =>
    Math.min(cSize.width / iSize.width, cSize.height / iSize.height)
