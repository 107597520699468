import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'
import { isEmpty, omit } from 'lodash-es'
import { ToolId } from '@domain/filters'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import jsonLogic, { AdditionalOperation, RulesLogic } from 'json-logic-js'

const applyRule = jsonLogic.apply.bind(jsonLogic)

const selectSelf = (state: RootState) => state.toolsState

export const isToolOpen = (id: ToolId) =>
    createSelector(selectSelf, (state): boolean => (!isEmpty(state) ? !!state[id]?.[getFormKey(id, 'wrapper')] : false))

export const getToolsState = createSelector(selectSelf, state => omit(state, ['detection']))

export const isDisabledParameter = (disableCondition?: { rule: RulesLogic<AdditionalOperation> }) =>
    createSelector(selectSelf, (state): boolean => {
        if (!disableCondition) return false
        const { rule } = disableCondition
        return applyRule(rule, state) as boolean
    })

export const getToolById = createSelector([getToolsState, (_, id: ToolId) => id], (state, id) => state[id])

export const getParameterState = (id: ToolId, parameterId: string) =>
    createSelector(getToolsState, (state): undefined | unknown => {
        const parameters = state[id]
        if (!parameters) {
            return null
        }
        const value = parameters[parameterId]

        return typeof value === 'undefined' ? undefined : value
    })

export const getOpenedTools = createSelector([getToolsState, (state: RootState) => state], (state, store) =>
    (Object.keys(state) as ToolId[]).filter(id => isToolOpen(id)(store)),
)

/** Panel */

const selectPanel = (state: RootState) => state.panelUI

export const getCurrentTab = createSelector(selectPanel, state => state.tab)
