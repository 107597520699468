import { SubjectLike, Subscription } from 'rxjs'
import { calculateAbsoluteCropData, createVectorFromData, MiplAdapter } from './MiplAdapter'
import { store as toolsStore } from '@features/toolsPanel/store'
import { AnyAction } from '@reduxjs/toolkit'
import { CropRect, ImageRect, InternalStore } from './store'
import { getEdits, getWhiteBalancePayload, setRenderData } from '@features/renderer/store/actions.mipl'

type Props = {
    listen: SubjectLike<AnyAction>['subscribe']
    dispatch: SubjectLike<AnyAction>['next']
}

type Adapter = typeof MiplAdapter['prototype']
export class Mipl {
    private luminar: any = null
    private _instance: MiplRawModule | null = null
    private _composer: Composer | null = null
    private _adapter: Adapter | null = null
    private _channels: Props
    private store = new InternalStore()
    private timerSub: Subscription | null = null
    private imageSize: MSize | null = null
    private scale = 1
    initialized = false

    constructor(channels: Props) {
        this._channels = { ...channels }
    }

    get dispatch() {
        return this._channels.dispatch
    }

    get mipl(): MiplRawModule {
        if (!this.initialized || !this._instance) {
            throw new Error('Mipl core not yet initialized!')
        }

        return this._instance
    }

    set mipl(mod: MiplRawModule) {
        this.initialized = true
        this._instance = mod
    }

    get composer(): Composer {
        if (!this._composer) {
            throw new Error('Composer is not defined')
        }

        return this._composer
    }

    set composer(mod: Composer) {
        this._composer = mod
    }

    get adapter(): Adapter {
        if (!this._adapter) {
            throw new Error('Adapter is not defined')
        }

        return this._adapter
    }

    set adapter(mod: Adapter) {
        this._adapter = mod
    }

    get imageId() {
        return this.store.getImageId()
    }

    init(): Promise<unknown> {
        this.luminar = (global as any).window.Luminar.bind((global as any).window.Luminar)
        ;(global as any).window.core = this
        return this.luminar().then((mod: MiplRawModule) => {
            this.mipl = mod
            this.adapter = new MiplAdapter(mod)
            return this.mipl
        })
    }

    loadModel(model: ArrayBuffer, name: string): Promise<void> {
        const modelArray = new Uint8Array(model)
        this.mipl.FS_createPath('/home', 'models/', true, true)
        this.mipl.FS_createDataFile('/home/models/', name, modelArray, true, true)

        return Promise.resolve()
    }

    loadImage(image: ImageData): Promise<{ imageId: VectorUint8; layerId: VectorUint8 }> {
        return new Promise(async res => {
            const inst = this.mipl

            this.imageSize = new inst.Size(image.width, image.height)
            const imageId = this._loadImageData(image, this.imageSize) // load img to mipl

            this.composer = new inst.Composer(this.imageSize)

            const resolution = image.width * image.height
            const max_resolution = 1000 * 1000
            if (resolution > max_resolution) {
                this.scale = Math.sqrt(max_resolution / resolution)
                const scaleSize = new inst.Size(image.width * this.scale, image.height * this.scale)
                const scaleRoi = new inst.Rect(0, 0, image.width, image.height)
                const context = {
                    size: scaleSize,
                    roi: scaleRoi,
                }
                this.composer.setContext(context)
            }

            this.composer.setCallback(this.onComposerChange)
            this.composer.setWhiteBalanceCallback(this.onWhiteBalanceChange)

            const layerId = this.composer.createLayer(imageId, {
                opacity: 1.0,
                blend: 0,
            })

            this.store.setImage(imageId).setLayer(layerId)

            res({ imageId, layerId })
        })
    }

    disableCurrentEffect({ id, disable }: { id?: string; disable: boolean }): Promise<boolean> {
        return new Promise(res => {
            const layerId = this.store.getCurrentLayer()
            const allRequiredDataExists = !!(layerId && this.store.getCurrentEditId() === id)
            if (allRequiredDataExists) {
                disable ? this.composer.disableCurrentEdit(layerId) : this.composer.enableCurrentEdit(layerId)
            }
            res(allRequiredDataExists)
        })
    }

    resetCurrentEdit(id?: string): Promise<boolean> {
        return new Promise(res => {
            const layerId = this.store.getCurrentLayer()
            const allRequiredDataExists = !!layerId
            if (allRequiredDataExists) {
                this.composer.resetCurrentEdit(layerId)
            }
            res(allRequiredDataExists)
        })
    }

    removeCurrentEdit(id?: string): Promise<boolean> {
        return new Promise(res => {
            const layerId = this.store.getCurrentLayer()
            const allRequiredDataExists = !!layerId
            if (allRequiredDataExists) {
                this.composer.removeCurrentEdit(layerId)
            }
            res(allRequiredDataExists)
        })
    }

    removeAllEdits(): Promise<boolean> {
        return new Promise(res => {
            const layerId = this.store.getCurrentLayer()
            const allRequiredDataExists = !!layerId
            if (allRequiredDataExists) {
                this.composer.resetLayer(layerId)
                this.composer.removeInactiveEdits(layerId)
            }
            res(allRequiredDataExists)
        })
    }

    disableAllEdits({ disable }: { disable: boolean }): Promise<void> {
        return new Promise(res => {
            disable ? this.composer.quickPreviewOn() : this.composer.quickPreviewOff()
            res()
        })
    }

    prepareEffect(payload: ReturnType<typeof toolsStore.toolsState.actions.updateFormState>['payload']) {
        return this.adapter.preparePayload(payload)
    }

    saveCurrentEdit(editId?: string): Promise<void> {
        return new Promise(res => {
            const isEditExist = this.store.getCurrentEditId() === editId
            isEditExist && this.store.setEdits()
            res()
        })
    }

    fetchUncroppedImage(): Promise<void> {
        return new Promise(res => {
            if (!this.imageSize) throw new Error('imageSize does not exist')
            const size = new this.mipl.Size(this.imageSize.width * this.scale, this.imageSize.height * this.scale)
            this.composer.generateDataWithoutCrop(size, ({ data }) => {
                this.onComposerChange({ data })
                res()
            })
        })
    }

    generateDataForExport(): Promise<ImageData> {
        return new Promise(res => {
            if (!this.imageSize) throw new Error('imageSize does not exist')
            // const resolution = this.imageSize.width * this.imageSize.height
            // const max_resolution = 4000 * 4000
            // const scale = resolution > max_resolution ? Math.sqrt(max_resolution / resolution) : 1

            // const size = new this.mipl.Size(this.imageSize.width * scale, this.imageSize.height * scale)

            // this.composer.generateDataForExport(size, ({ data }) => {
            //     res(this.getImageData(data))
            // })
            this.composer.generateDataForExport(this.imageSize, ({ data }) => {
                res(this.getImageData(data))
            })
        })
    }

    applyCrop({ crop, image }: { crop: CropRect; image: ImageRect }): Promise<void> {
        return new Promise(res => {
            const inst = this.mipl
            const { rotation, flip, position, size } = calculateAbsoluteCropData(crop, image)
            const cropDescription = new inst.CropDescription(
                rotation,
                new inst.Rect(
                    position.x / this.scale,
                    position.y / this.scale,
                    size.width / this.scale,
                    size.height / this.scale,
                ),
                new inst.SizeF(0, 0),
                flip.x,
                flip.y,
            )

            this.composer.startSetup()
            this.composer.setCropDescription(cropDescription)

            const scaleSize = new inst.Size(size.width, size.height)
            const scaleRoi = new inst.Rect(0, 0, size.width / this.scale, size.height / this.scale)
            const context = {
                size: scaleSize,
                roi: scaleRoi,
            }

            this.composer.setContext(context)

            this.composer.finishSetup()
            this.store.setCrop(crop, image, crop.ratio)

            res()
        })
    }

    resetCrop(): Promise<void> {
        return new Promise(res => {
            const inst = this.mipl
            const cropDescription = new inst.CropDescription()
            this.composer.startSetup()
            this.composer.setCropDescription(cropDescription)

            if (this.imageSize) {
                const scaleSize = new inst.Size(this.imageSize.width * this.scale, this.imageSize.height * this.scale)
                const scaleRoi = new inst.Rect(0, 0, this.imageSize.width, this.imageSize.height)
                const context = {
                    size: scaleSize,
                    roi: scaleRoi,
                }
                this.composer.setContext(context)
            }

            this.composer.finishSetup()
            this.store.resetCrop()
            res()
        })
    }

    isCropApplied(): boolean {
        const cropRect = this.composer.getCropDescription().getCropRect()

        return (
            (this.store.getRatio() !== null && cropRect.x !== 0) ||
            cropRect.y !== 0 ||
            cropRect.width !== 0 ||
            cropRect.height !== 0
        )
    }

    getCropDescription(): { crop: CropRect | null; image: ImageRect | null } {
        return {
            crop: this.store.getCropInfo(),
            image: this.store.getImageInfo(),
        }
    }
    // Creation of edit should be single per applying multiple parameters within current "Effect/Tool"
    async applyEffect(
        { layerId }: { imageId: VectorUint8; layerId: VectorUint8 },
        payload: ReturnType<Adapter['preparePayload']>,
    ): Promise<{ editId: VectorUint8 | null }> {
        return new Promise(async resolve => {
            if (!payload) {
                console.warn(' Current options have not handling yet ')
                return resolve({ editId: null })
            }
            const { tool, parameter, id } = payload
            const editIsExists = this.store.getCurrentEditId() === id

            if (!editIsExists) {
                const editId = await this.addEdit(layerId, tool().tool)
                this.store
                    .setEdits(id, editId)
                    .setParameters(
                        id,
                        this.adapter.convertEditParameters(this.composer.getEditParameters(layerId, editId)),
                    )
            }

            this.setParameter(layerId, parameter({ parameters: this.store.getCurrentParameters() }).parameter)

            resolve({ editId: this.store.getCurrentRawEditId() })
        })
    }

    onComposerChange = ({ data }: MRenderData) => {
        this.dispatch(setRenderData(this.getImageData(data)))
        this.notificateEdits()
        this.setCurrentEdit()
    }

    onWhiteBalanceChange = (data: ReturnPickedPoint) => {
        this.dispatch(getWhiteBalancePayload(data))
    }

    setEdit(editId: string) {
        const layerId = this.store.getCurrentLayer()
        if (!layerId) return

        this.composer.setCurrentEdit(
            layerId,
            this.adapter.arrayToVector<number>(
                editId.split('-').map(val => Number(val)),
                new this.mipl.VectorUint8(),
            ),
        )
    }

    notificateEdits = () =>
        new Promise(res => {
            const layerId = this.store.getCurrentLayer()
            if (!layerId) return
            const rawEdits = this.composer.getEdits(layerId)
            this.dispatch(getEdits(this.adapter.convertEdits(rawEdits)))
            res(layerId)
        })

    setCurrentEdit = () =>
        new Promise(res => {
            const layerId = this.store.getCurrentLayer()
            if (!layerId) return
            const rawEdit = this.composer.getCurrentEditInfo(layerId)
            rawEdit.isCurrent && this.store.setEdits(rawEdit.tool_name, rawEdit.identifier)
            res(rawEdit)
        })

    private addEdit(layerId: VectorUint8, payload: { toolDescription: ToolDescription }) {
        return this.composer.addEdit(layerId, payload.toolDescription)
    }

    private getImageData(data: MImageData): ImageData {
        const { width, height } = data.size()
        const uint8Array = new Uint8ClampedArray(this.mipl.HEAPU8.buffer, data.data(), 4 * width * height)
        return this.adapter.createWebImageData(uint8Array.slice(), { width, height })
    }

    private setParameter(layerId: VectorUint8, parameter: Record<string, any>) {
        return this.composer.setParameter(layerId, parameter)
    }

    private _loadImageData(image: ImageData, size: MSize): VectorUint8 {
        const heapSpace = this.mipl._malloc(image.data.length * image.data.BYTES_PER_ELEMENT)
        this.mipl.HEAP8.set(image.data, heapSpace) // uint8 has 1 byte
        const imageId = this.mipl.loadImage(heapSpace, size)
        this.mipl._free(heapSpace)

        return imageId
    }

    getWhiteBalanceForPickedPoint({ x, y }: { x: number; y: number }): Promise<void> {
        return new Promise(async res => {
            const layerId = this.store.getCurrentLayer()
            if (!layerId) {
                throw new Error('layer has not exist')
            }

            await this.composer.getWhiteBalanceForPickedPoint(layerId, { x, y })
            res()
        })
    }

    shutDown() {
        this.composer.shutDown()
        this.composer.deleteComposer()

        if (this.imageId) {
            this.mipl.dispose(this.imageId)
        }

        this.store.reset()
    }
}
