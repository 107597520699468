import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Types = 'uploadOnly' | 'replace'
type State = {
    openDialog: boolean
    type: Types
}
const initialState: State = {
    openDialog: false,
    type: 'replace',
}

export const slice = createSlice({
    name: 'uploadStore',
    initialState: initialState,
    reducers: {
        toggleOpen(state, { payload }: PayloadAction<boolean>) {
            state.openDialog = payload
        },
        toggleType(state, { payload }: PayloadAction<Types>) {
            state.type = payload
        },
    },
})

export type NotificationType = 'uploadError' | 'uploadErrorFirst' | null
type NotificationState = {
    open: boolean
    type: NotificationType
}
const notificationInitialState: NotificationState = {
    open: false,
    type: null,
}

export const toastSlice = createSlice({
    name: 'notifications',
    initialState: notificationInitialState,
    reducers: {
        toggleOpen(state, { payload }: PayloadAction<boolean>) {
            state.open = payload
        },
        toggleType(state, { payload }: PayloadAction<NotificationType>) {
            state.type = payload
        },
    },
})
