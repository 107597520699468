import { AppEpic } from '@store/configure-store'
import { merge } from 'rxjs'
import { filter, distinctUntilChanged, tap, ignoreElements, debounceTime } from 'rxjs/operators'
import { store, actions } from '@features/toolsPanel/store'
import { find as _find, last as _last, isEqual } from 'lodash-es'
import { FormStatePayload } from '@features/toolsPanel/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { WithRequired } from '@shared/utility/type'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import { FieldValues } from 'react-hook-form'
import { Analytics } from '@shared/services'
import { ToolId } from '@domain/filters'

type RequiredPayload = PayloadAction<{
    meta: WithRequired<FormStatePayload['meta'], 'id' | 'name'>
    values: FieldValues
}>

const handleParametersChange =
    (analytics: Analytics) =>
    ({ payload: { meta, values } }: RequiredPayload) => {
        switch (meta.name) {
            case getFormKey('MIPLDynamicContrastEffect', 'Boost'):
                analytics.enhanceUsage('accent_usage')
                break
            case getFormKey('MIPLDevelopCommonEffectID', 'Exposure'):
                analytics.lightUsage('exposure')
                break
            case getFormKey('MIPLDevelopCommonEffectID', 'Contrast'):
                analytics.lightUsage('smart_contrasts')
                break
            case getFormKey('MIPLDevelopCommonEffectID', 'Highlights'):
                analytics.lightUsage('highlights')
                break
            case getFormKey('MIPLDevelopCommonEffectID', 'Shadows'):
                analytics.lightUsage('shadows')
                break
            case getFormKey('MIPLDevelopCommonEffectID', 'whiteBalance'):
                analytics.colorsUsage('white_balance')
                break
            case getFormKey('MIPLDevelopCommonEffectID', 'Temperature'):
                analytics.colorsUsage('temperature')
                break
            case getFormKey('MIPLDevelopCommonEffectID', 'Tint'):
                analytics.colorsUsage('tint')
                break
            case getFormKey('MIPLSaturationEffect', 'Saturation'):
                analytics.colorsUsage('saturation')
                break
            case getFormKey('MIPLVibranceEffect', 'Vibrance'):
                analytics.colorsUsage('vibrance')
                break
            case getFormKey('MIPLRemoveColorCastEffect', 'Amount'):
                analytics.colorsUsage('remove_color_cast')
                break
            case getFormKey('MIPLChannelsEffect', 'hRed'):
                analytics.hlsUsage('red')
                break
            case getFormKey('MIPLChannelsEffect', 'hOrange'):
                analytics.hlsUsage('orange')
                break
            case getFormKey('MIPLChannelsEffect', 'hYellow'):
                analytics.hlsUsage('yellow')
                break
            case getFormKey('MIPLChannelsEffect', 'hMagenta'):
                analytics.hlsUsage('magenta')
                break
            case getFormKey('MIPLChannelsEffect', 'hGreen'):
                analytics.hlsUsage('green')
                break
            case getFormKey('MIPLChannelsEffect', 'hAqua'):
                analytics.hlsUsage('cyan')
                break
            case getFormKey('MIPLChannelsEffect', 'hBlue'):
                analytics.hlsUsage('blue')
                break
            case getFormKey('MIPLHueShiftEffect', 'Hue'):
                analytics.hlsUsage('hue shift')
                break
            case getFormKey('MIPLChannelsEffect', 'hPurple'):
                analytics.hlsUsage('purple')
                break
            case getFormKey('MIPLBWChannels_v1_Effect', 'Apply'):
                analytics.blackAndWhiteUsage('convert_to_black_and_white')
                break
            case getFormKey('MIPLBWChannels_v1_Effect', 'lRed'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'lYellow'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'lGreen'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'lCyan'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'lBlue'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'lViolet'):
                analytics.blackAndWhiteUsage('Luminance Usage')
                break
            case getFormKey('MIPLBWChannels_v1_Effect', 'sRed'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'sYellow'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'sGreen'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'sCyan'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'sBlue'):
            case getFormKey('MIPLBWChannels_v1_Effect', 'sViolet'):
                analytics.blackAndWhiteUsage('Saturation Usage')
                break
            case getFormKey('BWChannels_v1_Effect', 'tabs'):
                analytics.blackAndWhiteUsage(
                    values[getFormKey('BWChannels_v1_Effect', 'tabs')] === 1 ? 'Saturation Tab' : 'Liminance Tab',
                )
                break

            default:
                break
        }
    }

export const handleTools: AppEpic = (actions$, store$, { analytics }) => {
    const toolWrapperKey = getFormKey('AIEnhanceAdjustmentLayer', 'wrapper')
    const colorWhiteBalanceKey = getFormKey('MIPLDevelopCommonEffectID', 'whiteBalance')
    const cropToolId: ToolId = 'MPCropToolFilter'
    const excludeToggleTool = (action: RequiredPayload): boolean => action.payload.meta.name !== toolWrapperKey
    const excludeWhiteBalanceSideEffect = (action: RequiredPayload): boolean =>
        !(action.payload.meta.name === colorWhiteBalanceKey && action.payload.values[colorWhiteBalanceKey] === 1)
    const excludeCrop = (action: RequiredPayload): boolean => action.payload.meta.id !== cropToolId
    const excludeIndirectToolReset = (
        action: ReturnType<typeof store.toolsState.actions.updateFormState>,
    ): action is RequiredPayload =>
        !!action.payload.meta.id &&
        !!action.payload.meta.name &&
        typeof action.payload.values !== 'undefined' &&
        action.payload.values !== null

    return merge(
        actions$.pipe(
            filter(store.toolsState.actions.updateFormState.match),
            filter(excludeIndirectToolReset),
            filter(excludeCrop),
            filter(excludeWhiteBalanceSideEffect),
            filter(excludeToggleTool),
            distinctUntilChanged((prev, next) => isEqual(prev.payload, next.payload)),
            tap(handleParametersChange(analytics)),
        ),
        actions$.pipe(
            filter(actions.onSubmenuToggle.match),
            tap(({ payload }) => {
                switch (payload) {
                    case getFormKey('ExtendedColorAdjustmentLayer', 'submenu:1'): // HSL
                        analytics.hlsUsage('click')
                        break
                    case getFormKey('DefaultEffects', 'submenu:1'): // Black & Whites
                        analytics.lightUsage('black_and_whites')
                        break
                    case getFormKey('DefaultEffects', 'submenu:2'): // Curves
                        analytics.lightUsage('curves')
                        break
                    default:
                        break
                }
            }),
        ),
    ).pipe(ignoreElements())
}
