import { AnalyticEvent } from '@shared/types'
import localforage from 'localforage'
import { APIService } from './api'
import { getCookie, setCookies } from 'cookies-next'

export type Crop = 'flip_horisontal' | 'flip_vertical' | 'click' | 'rotate' | 'ratio' | 'apply'
export type Photo = 'add_to_album' | 'successfully_loaded'
export type Enhance = 'sky_enhancer_usage' | 'accent_usage' | 'click'
export type Light = 'exposure' | 'smart_contrasts' | 'highlights' | 'shadows' | 'black_and_whites' | 'curves' | 'click'
export type Color = 'click' | 'white_balance' | 'temperature' | 'tint' | 'saturation' | 'vibrance' | 'remove_color_cast'
export type HLS = 'click' | 'red' | 'orange' | 'blue' | 'yellow' | 'green' | 'cyan' | 'purple' | 'magenta' | 'hue shift'
export type BlackAndWhite =
    | 'click'
    | 'convert_to_black_and_white'
    | 'Liminance Tab'
    | 'Saturation Tab'
    | 'Luminance Usage'
    | 'Saturation Usage'
export type Edits = 'click' | 'delete_all' | 'delete_separate_edit' | 'eye_icon_click'
export type Banners = 'upload_photo_block' | 'tools_block'
export type ExportImageType = 'jpg' | 'jpeg' | 'png'
export type Social = 'facebook' | 'google' | 'microsoft' | 'apple' | 'email'
export type AddImage = 'fixed_bar' | 'upload_photo_block'
export type PopupTypeShow =
    | 'sign_in_popup_show'
    | 'sign_up_popup_show'
    | 'sign_up_email_popup_show'
    | 'forgot_password_popup_show'
    | 'verification_popup_show'
    | 'password_reset_code_popup_show'
    | 'set_new_password_popup_show'

export class Analytics {
    private gtm = global.window?.dataLayer
    private sourceLocator = global.window?.sbjs
    private isInit = false
    private gaValue: any = null
    api: APIService
    forageStore: LocalForage

    constructor(
        api: APIService,
        driver = localforage.createInstance({
            driver: [localforage.LOCALSTORAGE],
            name: 'ga-db',
        }),
    ) {
        this.api = api
        this.forageStore = driver
    }

    isReady() {
        const sbjs = window?.sbjs
        const dataLayer = window?.dataLayer
        this.gtm = dataLayer
        this.sourceLocator = sbjs
        return !!sbjs && !!sbjs?.init && Array.isArray(dataLayer)
    }

    init() {
        this.gaValue = getCookie('skylum_ga') ?? this.generateGaValue()
        setCookies('skylum_ga', this.gaValue, {
            domain: 'skylum.com',
            maxAge: 60 * 60 * 24 * 365 * 2,
        })

        this.postPageView({ ec: 'Site', ea: '', el: '' })
        this.sourceLocator.init({
            domain: 'skylum.com',
            session_length: 30,
        })
        window.dataLayer = window.dataLayer || []
        this.gtm.push({
            originalLocation:
                document.location.protocol +
                '//' +
                document.location.hostname +
                document.location.pathname +
                document.location.search,
        })
        this.isInit = true
    }

    async postEvent(event: AnalyticEvent) {
        if (!this.isInit) {
            return console.error('Analytics cant be initialized, try to refresh your page!')
        }
        try {
            const data = await this.formPayload(eventMapper(event))
            this.gtm.push(data)
            return await this.api.postEvent(data)
        } catch (error) {
            console.warn(`Analytics for ${event.ec}:${event.ea}:${event.el} type failed with %s!!!`, error)
        }
    }

    async postPageView(event: AnalyticEvent) {
        if (!this.isInit) {
            return console.error('Analytics cant be initialized, try to refresh your page!')
        }
        try {
            const data = await this.formPayload(eventMapper(event))
            return await this.api.postPageView(data)
        } catch (error) {
            console.warn(`Analytics for ${event.ec}:${event.ea}:${event.el} type failed with %s!!!`, error)
        }
    }

    convertTextForGA(text: string) {
        if (typeof text !== 'undefined') {
            return text.split(' ').join('_').toLowerCase()
        }

        return ''
    }

    photoUsage = (ea: Photo) => this.postEvent({ ec: 'Catalog', ea })
    cropUsage = (el: Crop, ratio = 'original') => {
        switch (el) {
            case 'click':
            case 'apply':
                return this.postEvent({ ec: 'Tools', ea: 'crop', el })
            case 'ratio':
                return this.postEvent({ ec: 'Tools', ea: 'crop', el: ratio })
            case 'flip_horisontal':
            case 'flip_vertical':
            case 'rotate':
                return this.postEvent({ ec: 'Catalog', ea: el })
            default:
                Promise.resolve(console.warn('not exists'))
        }
    }
    pageView = () => this.postPageView({ ec: '', ea: '', el: '' })
    enhanceUsage = (el: Enhance) => this.postEvent({ ec: 'Tools', ea: 'enhance', el })
    lightUsage = (el: Light) => this.postEvent({ ec: 'Tools', ea: 'lights', el })
    colorsUsage = (el: Color) => this.postEvent({ ec: 'Tools', ea: 'colors', el })
    hlsUsage = (el: HLS) => this.postEvent({ ec: 'Tools', ea: 'hls', el })
    blackAndWhiteUsage = (el: BlackAndWhite) => this.postEvent({ ec: 'Tools', ea: 'black_and_white', el })
    editsUsage = (el: Edits) => this.postEvent({ ec: 'Edits', ea: 'edits', el })
    bannersUsage = (el: Banners) => this.postEvent({ ec: 'Site', ea: 'try_luminar_neo_click', el })

    // header
    uploadNewImageUsage = () => this.postEvent({ ec: 'Site', ea: 'change_current_image_icon', el: '' })
    addImageUsage = (el: AddImage) => this.postEvent({ ec: 'Site', ea: 'add_image_click', el })
    saveCurrentImageUsage = () => this.postEvent({ ec: 'Site', ea: 'save_current_image_icon_click', el: '' })
    uploadNewImagePopupChangeImgUsage = () =>
        this.postEvent({ ec: 'Site', ea: 'change_current_image', el: 'replace_without_saving' })
    exportImageSignUpUsage = (el: ExportImageType) =>
        this.postEvent({ ec: 'Site', ea: 'sign_up_click', el: `save_${el}` })
    exportImageFromPopupUsage = (el: ExportImageType) =>
        this.postEvent({ ec: 'Site', ea: 'change_and_save_current_image', el: `save_${el}` })
    exportImageUsage = (el: ExportImageType) =>
        this.postEvent({ ec: 'Site', ea: 'save_current_image_click', el: `save_${el}` })

    // Sign In
    signInOpenPopupUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_in_click', el: 'sign_in_button' })
    signInCreateAnAccountUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_up_click', el: 'sign_in_popup' })
    signInForgotPasswordUsage = () => this.postEvent({ ec: 'Site', ea: 'forgot_password_click', el: 'sign_in_popup' })
    signInSubmitUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_in_with_click', el: 'email' })
    signInWithSocialUsage = (el: Social) => this.postEvent({ ec: 'Site', ea: 'sign_in_with_click', el })
    signInWithSocialSuccessUsage = (el: Social) => this.postEvent({ ec: 'Site', ea: 'success_user_account_login', el })
    signInSuccessUsage = () => this.postEvent({ ec: 'Site', ea: 'success_user_account_login', el: 'email' })

    signInErrorUsage = (el: string) =>
        this.postEvent({ ec: 'Site', ea: 'error_user_account_login', el: this.convertTextForGA(el) })

    // Sign Up
    signUpSwitchToLoginUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_in_click', el: 'sign_up_popup' })
    signUpSwitchToSignUpEmailUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_up_with_step1_click', el: 'email' })
    signUpWithSocialUsage = (el: Social) => this.postEvent({ ec: 'Site', ea: 'sign_up_with_click', el })
    signUpWithSocialSuccessUsage = (el: Social) =>
        this.postEvent({ ec: 'Site', ea: 'success_user_account_registration', el })
    signUpSuccessUsage = () => this.postEvent({ ec: 'Site', ea: 'success_user_account_registration', el: 'email' })
    signUpErrorUsage = (el: string) =>
        this.postEvent({ ec: 'Site', ea: 'error_user_account_registration', el: this.convertTextForGA(el) })
    signUpEmailSwitchToLoginUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_in_click', el: 'sign_up_email_popup' })
    signUpEmailSwitchToSignUpSocialUsage = () =>
        this.postEvent({ ec: 'Site', ea: 'sign_up_click', el: 'sign_up_email_popup' })
    signUpSubmitUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_up_with_click', el: 'email' })

    // Forgot password
    forgotPasswordSwitchToLoginUsage = () =>
        this.postEvent({ ec: 'Site', ea: 'sign_in_click', el: 'forgot_password_popup' })
    forgotPasswordSubmitUsage = () =>
        this.postEvent({ ec: 'Site', ea: 'reset_password_with_click', el: 'forgot_password_popup' })
    forgotPasswordSuccessUsage = () => this.postEvent({ ec: 'Site', ea: 'success_reset_password', el: '' })
    forgotPasswordErrorUsage = (el: string) =>
        this.postEvent({ ec: 'Site', ea: 'error_reset_password', el: this.convertTextForGA(el) })

    // Verification
    verificationSubmitUsage = () => this.postEvent({ ec: 'Site', ea: 'verify_account_click', el: 'verification_popup' })
    verificationResendUsage = () =>
        this.postEvent({ ec: 'Site', ea: 'resend_verification_code_click', el: 'verification_popup' })
    verificationSuccessUsage = () => this.postEvent({ ec: 'Site', ea: 'success_email_verification', el: '' })
    verificationErrorUsage = (el: string) =>
        this.postEvent({ ec: 'Site', ea: 'error_email_verification', el: this.convertTextForGA(el) })

    // Password reset code
    passwordResetCodeSubmitUsage = () =>
        this.postEvent({ ec: 'Site', ea: 'reset_click', el: 'password_reset_code_popup' })

    // Update password
    updatePasswordSubmitUsage = () =>
        this.postEvent({ ec: 'Site', ea: 'set_new_password_click', el: 'set_new_password_popup' })

    // Logout
    logoutUsage = () => this.postEvent({ ec: 'Site', ea: 'sign_out_click', el: '' })

    showPopupAuthUsage = (ea: PopupTypeShow) => this.postEvent({ ec: 'Site', ea, el: '' })

    private formPayload = (event: AnalyticEvent) => ({
        identifier: this.gaValue,
        event: 'luminar_web_event',
        url: window.location.href,
        referrer: document.referrer,
        ...event,
        utmSource: this.sourceLocator.get.current.src ?? null,
        utmMedium: this.sourceLocator.get.current.mdm ?? null,
        utmCampaign: this.sourceLocator.get.current.cmp ?? null,
        utmContent: this.sourceLocator.get.current.cnt ?? null,
        utmTerm: this.sourceLocator.get.current.trm ?? null,
    })

    private generateGaValue = () =>
        'GA1.2.' + randomIntFromInterval(1000000000, 9999999999) + '.' + Math.floor(Date.now() / 1000)

    private async getCookieLS(): Promise<string> {
        this.gaValue = this.gaValue ?? (await this.forageStore.getItem<string>('key'))
        if (!this.gaValue) {
            const newKey = this.generateGaValue()
            this.gaValue = await this.forageStore.setItem('key', newKey)
        }

        return this.gaValue
    }
}

function randomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const eventMapper = (ev: AnalyticEvent): AnalyticEvent => ({
    eventCategory: ev['ec'],
    eventAction: ev['ea'],
    eventLabel: ev['el'],
})
