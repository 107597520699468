import parse from 'html-react-parser'

export const EditsInfo = {
    AIEnhanceAdjustmentLayer: {
        title: parse('Enhance <sup>AI</sup>'),
    },
    MPCropToolFilter: {
        title: 'Crop',
    },
    MPLayerProperties: {
        title: 'Layer Properties',
    },
    ERASE: {
        title: 'Erase',
    },
    AIStructureEffect: {
        title: 'Structure',
    },
    ExtendedColorAdjustmentLayer: {
        title: 'Color',
    },
    BWChannels_v1_Effect: {
        title: 'Black & White',
    },
    DefaultEffects: {
        title: 'Light',
    },
    DefaultEffectsRAW: {
        title: 'Develop RAW',
    },
    DetailsEnhancerAdjustmentLayer: {
        title: 'Details',
    },
    DenoiseLaplacianEffect: {
        title: 'Denoise',
    },
    LandscapeEnhancerAdjustmentLayer: {
        title: 'Landscape',
    },
    VignetteAdjustmentLayer: {
        title: 'Vignette',
    },
    SkyReplacementAdjustmentLayer: {
        title: parse('Sky <sup>AI</sup>'),
    },
    AugmentedSkyEffect: {
        title: 'Augmented Sky AI',
    },
    AIFogEffect: {
        title: 'Atmosphere AI',
    },
    SunLightRaysAdjustmentLayer: {
        title: 'Sunrays',
    },
    ComplexBleachAdjustmentLayer: {
        title: 'Dramatic',
    },
    LUTMappingEffect: {
        title: 'Mood',
    },
    ColorToningAdjustmentLayer: {
        title: 'Toning',
    },
    FadeEffect: {
        title: 'Matte',
    },
    ImageRadianceAdjustmentLayer: {
        title: 'Mystical',
    },
    GlowOrtonAdjustmentLayer: {
        title: 'Glow',
    },
    GrainNewAdjustmentLayer: {
        title: 'Film Grain',
    },
    VirtualFlashLightEffect: {
        title: 'Relight AI',
    },
    AIBokehEffect: {
        title: 'Portrait Bokeh AI',
    },
    FaceEnhancer_vn0_Effect: {
        title: 'Face AI',
    },
    FaceEnhancerEffect: {
        title: 'Face AI',
    },
    SkinSoftenerNNEffect: {
        title: 'Skin AI',
    },
    SlimPeopleEffect: {
        title: 'Body AI',
    },
    SlimPeople_vn0_Effect: {
        title: 'Body AI',
    },
    HighKeyAdjustmentLayer: {
        title: 'High Key',
    },
    ProContrastAdjustmentLayer: {
        title: 'Supercontrast',
    },
    ColorEnhancerAdjustmentLayer: {
        title: 'Color Harmony',
    },
    DodgeBurnAdjustmentLayer: {
        title: 'Dodge & Burn',
    },
    MPCloneStampFilter: {
        title: 'Clone & Stamp',
    },
} as const
