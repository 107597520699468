import { createTheme, ThemeOptions } from '@mui/material'
import vars from '../../../styles/vars.module.scss'

const getTestEnv = (_target: unknown, prop: string | symbol) => (prop === 'shapeRadiusSmall' ? '4px' : '#000')
const scssVars = new Proxy(vars, {
    get: (target, prop) => (process.env.ENVIRONMENT === 'test' ? getTestEnv(target, prop) : target[prop as string]),
})

const getIconColors = (name: string): string[] => scssVars?.[name]?.split(', ')
const transformToNumber = (str: string): number => parseInt(str)

const fontSize = transformToNumber(scssVars.fontBaseSize)
const h1 = {
    fontSize: scssVars.heading1,
    fontWeight: 600,
    lineHeight: '24px',
}
const h2 = {
    fontSize: scssVars.heading2,
    fontWeight: 500,
    lineHeight: '20px',
}
const h3 = {
    fontSize: scssVars.heading3,
    fontWeight: 400,
    lineHeight: '20px',
}
const body1 = {
    fontSize: scssVars.text1,
    fontWeight: 400,
    lineHeight: '16px',
}
export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        background: {
            default: scssVars.primaryBackColor,
        },
        primary: {
            main: scssVars.primaryDefaultColor,
            dark: scssVars.primaryDarkColor,
            light: scssVars.primaryLightColor,
            contrastText: scssVars.textPrimaryColor,
        },
        secondary: {
            main: scssVars.secondaryDefaultColor,
            contrastText: scssVars.textSecondaryColor,
        },
        text: {
            primary: scssVars.textPrimaryColor,
            secondary: scssVars.textSecondaryColor,
            disabled: scssVars.textDisabledColor,
        },
        btnPrimary: {
            default: scssVars.primaryDefaultColor,
            over: scssVars.primaryLightColor,
            pressed: scssVars.primaryDarkColor,
            disabled: scssVars.primaryDisabledColor,
        },
        btnSecondary: {
            default: scssVars.secondaryBackgroundDefault,
            over: scssVars.secondaryBackgroundHover,
            pressed: scssVars.secondaryBackgroundPressed,
            disabled: scssVars.secondaryBackgroundDisabled,
        },
        accent: {
            main: scssVars.accentColorPrimary,
            secondary: scssVars.accentColorSecondary,
            third: scssVars.accentColorThird,
            fourth: scssVars.accentColorFourth,
        },
        action: {
            hover: scssVars.actionColorPrimary,
            selected: 'transparent',
            disabledOpacity: 0.4,
        },
        iconPrimary: getIconColors('iconPrimaryColors'),
        iconSecondary: getIconColors('iconSecondaryColors'),
        iconThird: getIconColors('iconThirdColors'),
        iconFourth: getIconColors('iconFourthColors'),
        iconAccent: getIconColors('iconAccentColors'),
        focusColor: scssVars.actionColorSecondary,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    overflowX: 'hidden',
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: ({ theme, ownerState }) => {
                    const small =
                        ownerState.size === 'small'
                            ? {
                                  padding: '0',
                                  width: theme.typography.body1.fontSize,
                                  height: theme.typography.body1.fontSize,
                                  '& .MuiSvgIcon-root': {
                                      fontSize: theme.typography.h3.fontSize,
                                  },
                              }
                            : {}

                    return {
                        ...small,
                        color: theme.palette.text.primary,
                        '&.Mui-checked': {
                            color: theme.palette.text.secondary,
                        },
                    }
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: scssVars.textPrimaryColor,
                    '&.Mui-selected': {
                        color: scssVars.textSecondaryColor,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                underlineAlways: {
                    ...h3,
                    color: 'rgba(255, 255, 255, 0.7)',
                    cursor: 'pointer',
                    textUnderlineOffset: '3px',
                    textDecorationColor: 'rgba(255, 255, 255, 0.3)',
                    '&:hover': {
                        color: '#fff',
                    },
                },
            },
        },

        MuiButton: {
            defaultProps: {
                disableElevation: true,
                variant: 'contained',
            },
            styleOverrides: {
                containedPrimary: ({ theme }) => ({
                    background: theme.palette.btnPrimary.default,
                    color: theme.palette.text.secondary,
                    borderRadius: transformToNumber(scssVars.shapeRadiusSmall),
                    '&:hover': {
                        background: theme.palette.btnPrimary.over,
                        color: theme.palette.text.primary,
                    },
                    '&:active': {
                        background: theme.palette.btnPrimary.pressed,
                        color: theme.palette.text.primary,
                    },
                    '&[disabled]': {
                        background: theme.palette.btnPrimary.disabled,
                        color: theme.palette.primary.light,
                    },
                }),
                containedSecondary: ({ theme }) => ({
                    background: theme.palette.btnSecondary.default,
                    color: theme.palette.text.primary,
                    borderRadius: transformToNumber(scssVars.shapeRadiusSmall),
                    '&:hover': {
                        background: theme.palette.btnSecondary.over,
                    },
                    '&:active': {
                        background: theme.palette.btnSecondary.pressed,
                    },
                    '&[disabled]': {
                        background: theme.palette.btnSecondary.disabled,
                        color: theme.palette.primary.light,
                    },
                }),
                textPrimary: ({ theme }) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                        backgroundColor: 'inherit',
                        color: theme.palette.text.primary,
                    },
                }),
                sizeLarge: {
                    ...h2,
                    padding: '10px 2px',
                    borderRadius: transformToNumber(scssVars.shapeRadiusSmall) * 2,
                },
                sizeMedium: {
                    ...h2,
                    padding: '6px 0 7px',
                },
                sizeSmall: {
                    padding: '4px 0px',
                    ...body1,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    cursor: 'pointer',
                    width: '28px',
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'text.secondary',
                    borderRadius: '6px',
                    background: theme.palette.primary.dark,
                    transition: theme.transitions.create(['background'], { duration: 300 }),
                    '.MuiSvgIcon-root path': {
                        fill: theme.palette.text.primary,
                        transition: theme.transitions.create(['fill'], { duration: 300 }),
                    },
                    '&:hover': {
                        background: theme.palette.primary.light,
                        '.MuiSvgIcon-root path': {
                            fill: theme.palette.text.secondary,
                        },
                    },
                    '&:active': {
                        background: theme.palette.primary.dark,
                        '.MuiSvgIcon-root path': {
                            fill: theme.palette.text.secondary,
                        },
                    },
                    '&:focus-visible, &:focus': {
                        background: theme.palette.primary.dark,
                        border: `1px solid ${theme.palette.focusColor}`,
                    },
                }),
            },
        },
        MuiAccordion: {
            defaultProps: { disableGutters: true },
            styleOverrides: {
                root: {
                    background: 'transparent',
                    boxShadow: 'none',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: theme.shape.borderRadius,
                }),
                input: () => ({
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: `none`,
                        WebkitBackgroundClip: 'text',
                    },
                }),
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
                MenuProps: {
                    PaperProps: {
                        style: {
                            borderRadius: scssVars.shapeRadiusSmall,
                            background: scssVars.primaryDarkColor,
                            backdropFilter: 'blur(51px)',
                            WebkitBackdropFilter: 'blur(51px)',
                            marginTop: '4px',
                        },
                    },
                    MenuListProps: {
                        style: {
                            width: '100%',
                            padding: '4px 0',
                        },
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'standard' },
                    style: {
                        borderRadius: scssVars.shapeRadiusSmall,
                        backgroundColor: scssVars.primaryDarkColor,
                        '&:hover': {
                            backgroundColor: scssVars.primaryLightColor,
                        },
                    },
                },
            ],
            styleOverrides: {
                standard: {
                    padding: '4px 2px 4px 8px',
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
    },
    shape: {
        borderRadius: transformToNumber(scssVars.shapeRadius),
    },
    typography: {
        htmlFontSize: fontSize,
        fontSize: fontSize,
        fontFamily: scssVars.fontBaseFamily,
        allVariants: {
            color: scssVars.textPrimaryColor,
        },
        button: {
            textTransform: 'none',
        },
        h1,
        h2,
        h3,
        h4: undefined,
        h5: undefined,
        h6: undefined,
        subtitle1: {
            fontSize: scssVars.subtitle1,
            textTransform: 'uppercase',
            fontWeight: 500,
            lineHeight: '20px',
        },
        subtitle2: {
            fontSize: scssVars.subtitle1,
            fontWeight: 400,
            lineHeight: '20px',
        },
        body1,
        body2: {
            fontSize: scssVars.text2, // AI 8px
            fontWeight: 600,
            lineHeight: '12px',
        },
        link: {
            ...h3,
            textDecoration: 'underline',
            cursor: 'pointer',
            textUnderlineOffset: '3px',
            '&:hover': {
                color: '#fff',
            },
        },
    },
    transitions: {
        easing: {
            easeInOut: 'cubic-bezier(.42,0,1,1)',
        },
    },
}
export const theme = createTheme(themeOptions)
