import parse from 'html-react-parser'
import scssVars from '@styles/vars.module.scss'
import { AdditionalOperation, RulesLogic } from 'json-logic-js'
import { WhiteBalanceOptions } from './options'

const whenFieldFalsy = (key: string): RulesLogic<AdditionalOperation> => ({ '!': [{ var: key }] })

const VISUAL_EXTENDED_RANGE = {
    visualMinValue: -100,
    visualMaxValue: 100,
    visualStep: 1,
}
export const VISUAL_DEFAULT_RANGE = {
    visualMinValue: 0,
    visualMaxValue: 100,
    visualStep: 1,
}

export const EffectsInfo: EffectsInfoType = {
    MIPLAdjustableGraduated_v1_Effect: {
        ContrastB: {
            historyPrefix: 'Bottom',
            name: 'Contrast',
        },
        ContrastT: {
            historyPrefix: 'Top',
            name: 'Contrast',
        },
        ExposureB: {
            historyPrefix: 'Bottom',
            name: 'Exposure',
        },
        ExposureT: {
            historyPrefix: 'Top',
            name: 'Exposure',
        },
        HighlightsBottom: {
            historyPrefix: 'Bottom',
            name: 'Highlights',
        },
        HighlightsTop: {
            historyPrefix: 'Top',
            name: 'Highlights',
        },
        ShadowsBottom: {
            historyPrefix: 'Bottom',
            name: 'Shadows',
        },
        ShadowsTop: {
            historyPrefix: 'Top',
            name: 'Shadows',
        },
        VibranceB: {
            historyPrefix: 'Bottom',
            name: 'Vibrance',
        },
        VibranceT: {
            historyPrefix: 'Top',
            name: 'Vibrance',
        },
        WarmthB: {
            historyPrefix: 'Bottom',
            name: 'Warmth',
        },
        WarmthT: {
            historyPrefix: 'Top',
            name: 'Warmth',
        },
    },
    MIPLAIBokehEffect: {
        Dehalo: {
            // disableCondition: 'Amount.value = 0',
            name: 'Edges Correction',
        },
        Exposure: {
            // disableCondition: 'Amount.value = 0',
            name: 'Brightness',
        },
        FocusPos: {
            // disableCondition: 'Amount.value = 0',
            name: 'Depth Correction',
        },
        Lightness: {
            // disableCondition: 'Amount.value = 0',
            name: 'Highlights Glow',
        },
        Warmth: {
            // disableCondition: 'Amount.value = 0',
        },
    },
    MIPLAIFogEffect: {
        Mode: {
            discreteValues: ['Fog', 'Layered Fog', 'Mist', 'Haze'],
            type: 'Combobox',
        },
    },
    MIPLAugmentedSkyEffect: {
        Amount: {
            // disableCondition: 'Texture.hasChanges = false',
        },
        FlipH: {
            // disableCondition: 'Texture.hasChanges = false',
            name: 'Flip Object',
            type: 'Checkbox',
        },
        Haze: {
            // disableCondition: 'Texture.hasChanges = false',
            name: 'Relight',
        },
        SkyBlur: {
            // disableCondition: 'Texture.hasChanges = false',
            name: 'Defocus',
        },
        SkyGlobal: {
            // disableCondition: 'Texture.hasChanges = false',
            name: 'Mask Refinement',
        },
        Temperature: {
            // disableCondition: 'Texture.hasChanges = false',

            name: 'Warmth',
        },
        Texture: {
            name: 'Object Selection',
            type: 'TextureButton',
        },
        UseCorners: {
            // disableCondition: 'Texture.hasChanges = false',
            name: 'Place Object',
        },
    },
    MIPLBlackWhiteEffect: {
        Blacks: {
            name: 'Blacks',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        Whites: {
            name: 'Whites',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
    },
    MIPLBrillianceWarmthEffect: {
        Vividness: {
            name: 'Brilliance',
        },
        Warmth: {},
    },
    MIPLBWChannels_v1_Effect: {
        Apply: {
            discreteValues: ['No', 'Yes'],
            name: 'Convert to B&W',
            type: 'BWToggleButton',
            defaultValue: 10,
        },
        Luminance: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            name: 'Luminance',
            type: 'TabButtonIcons',
        },
        Saturation: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            name: 'Saturation',
            type: 'TabButtonIcons',
        },
        lBlue: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Luminance',
            name: 'Blue',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.bwGradientLBlue}`,
        },
        lCyan: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Luminance',
            name: 'Cyan',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.bwGradientLAqua}`,
        },
        lGreen: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Luminance',
            name: 'Green',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.bwGradientLGreen}`,
        },
        lRed: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Luminance',
            name: 'Red',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.bwGradientLRed}`,
        },
        lViolet: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Luminance',
            name: 'Magenta',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.bwGradientLMagenta}`,
        },
        lYellow: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Luminance',
            name: 'Yellow',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.bwGradientLYellow}`,
        },
        sBlue: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Saturation',
            name: 'Blue',
            railGradient: `${scssVars.bwGradientSBlue}`,
            visualValues: VISUAL_DEFAULT_RANGE,
        },
        sCyan: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Saturation',
            name: 'Cyan',
            railGradient: `${scssVars.bwGradientSAqua}`,
            visualValues: VISUAL_DEFAULT_RANGE,
        },
        sGreen: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Saturation',
            name: 'Green',
            railGradient: `${scssVars.bwGradientSGreen}`,
            visualValues: VISUAL_DEFAULT_RANGE,
        },
        sRed: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Saturation',
            name: 'Red',
            railGradient: `${scssVars.bwGradientSRed}`,
            visualValues: VISUAL_DEFAULT_RANGE,
        },
        sViolet: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Saturation',
            name: 'Magenta',
            railGradient: `${scssVars.bwGradientSMagenta}`,
            visualValues: VISUAL_DEFAULT_RANGE,
        },
        sYellow: {
            disableCondition: {
                rule: whenFieldFalsy('BWChannels_v1_Effect.MIPLBWChannels_v1_Effect-Apply'),
            },
            historyPrefix: 'Saturation',
            name: 'Yellow',
            railGradient: `${scssVars.bwGradientSYellow}`,
            visualValues: VISUAL_DEFAULT_RANGE,
        },
    },
    MIPLChannelsEffect: {
        hAqua: {
            historyPrefix: 'Hue',
            name: 'Cyan',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHAqua}`,
        },
        hBlue: {
            historyPrefix: 'Hue',
            name: 'Blue',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHBlue}`,
        },
        hGreen: {
            historyPrefix: 'Hue',
            name: 'Green',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHGreen}`,
        },
        hMagenta: {
            historyPrefix: 'Hue',
            name: 'Magenta',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHMagenta}`,
        },
        hOrange: {
            historyPrefix: 'Hue',
            name: 'Orange',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHOrange}`,
        },
        hPurple: {
            historyPrefix: 'Hue',
            name: 'Purple',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHPurple}`,
        },
        hRed: {
            historyPrefix: 'Hue',
            name: 'Red',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHRed}`,
        },
        hYellow: {
            historyPrefix: 'Hue',
            name: 'Yellow',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientHYellow}`,
        },
        lAqua: {
            historyPrefix: 'Luminance',
            name: 'Cyan',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLAqua}`,
        },
        lBlue: {
            historyPrefix: 'Luminance',
            name: 'Blue',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLBlue}`,
        },
        lGreen: {
            historyPrefix: 'Luminance',
            name: 'Green',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLGreen}`,
        },
        lMagenta: {
            historyPrefix: 'Luminance',
            name: 'Magenta',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLMagenta}`,
        },
        lOrange: {
            historyPrefix: 'Luminance',
            name: 'Orange',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLOrange}`,
        },
        lPurple: {
            historyPrefix: 'Luminance',
            name: 'Purple',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLPurple}`,
        },
        lRed: {
            historyPrefix: 'Luminance',
            name: 'Red',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLRed}`,
        },
        lYellow: {
            historyPrefix: 'Luminance',
            name: 'Yellow',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientLYellow}`,
        },
        sAqua: {
            historyPrefix: 'Saturation',
            name: 'Cyan',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSAqua}`,
        },
        sBlue: {
            historyPrefix: 'Saturation',
            name: 'Blue',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSBlue}`,
        },
        sGreen: {
            historyPrefix: 'Saturation',
            name: 'Green',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSGreen}`,
        },
        sMagenta: {
            historyPrefix: 'Saturation',
            name: 'Magenta',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSMagenta}`,
        },
        sOrange: {
            historyPrefix: 'Saturation',
            name: 'Orange',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSOrange}`,
        },
        sPurple: {
            historyPrefix: 'Saturation',
            name: 'Purple',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSPurple}`,
        },
        sRed: {
            historyPrefix: 'Saturation',
            name: 'Red',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSRed}`,
        },
        sYellow: {
            historyPrefix: 'Saturation',
            name: 'Yellow',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSYellow}`,
        },
    },
    MIPLColorBalanceEffect: {
        HBlue: {
            historyPrefix: 'Highlights',
            name: 'Yellow-Blue',
        },
        HGreen: {
            historyPrefix: 'Highlights',
            name: 'Magenta-Green',
        },
        HRed: {
            historyPrefix: 'Highlights',
            name: 'Cyan-Red',
        },
        MBlue: {
            historyPrefix: 'Midtones',
            name: 'Yellow-Blue',
        },
        MGreen: {
            historyPrefix: 'Midtones',
            name: 'Magenta-Green',
        },
        MRed: {
            historyPrefix: 'Midtones',
            name: 'Cyan-Red',
        },
        SBlue: {
            historyPrefix: 'Shadows',
            name: 'Yellow-Blue',
        },
        SGreen: {
            historyPrefix: 'Shadows',
            name: 'Magenta-Green',
        },
        SRed: {
            historyPrefix: 'Shadows',
            name: 'Cyan-Red',
        },
    },
    MIPLComplexBleachEffect: {
        Brightness: {
            // disableCondition: 'Amount.value = 0',
        },
        'Local Contrast': {
            // disableCondition: 'Amount.value = 0',
        },
        Saturation: {
            // disableCondition: 'Amount.value = 0',
        },
    },
    MIPLContrastColorRangeEffect: {
        'Color Contrast': {
            name: 'Amount',
        },
        Hue: {
            // disableCondition: "SELF.'Color Contrast.value' = 0",
            visualValues: {
                visualMinValue: -180,
                visualMaxValue: 180,
                visualStep: 1,
            },
        },
    },
    MIPLCurveEffect: {
        B: {
            type: 'Curve',
            historySuffix: ': Curve Modified',
            name: 'Blue',
        },
        G: {
            type: 'Curve',
            historySuffix: ': Curve Modified',
            name: 'Green',
        },
        R: {
            type: 'Curve',
            historySuffix: ': Curve Modified',
            name: 'Red',
        },
        RGB: {
            type: 'Curve',
            historySuffix: ': Curve Modified',
            name: 'RGB',
        },
    },
    MIPLDehazeEffect: {
        Amount: {
            name: 'Dehaze',
        },
    },
    // MIPLDenoiseLaplacianEffect: {
    //     Boost: {
    //         disableCondition: 'Luminosity.value = 0',
    //         name: 'Boost',
    //     },
    //     Color: {
    //         name: 'Color',
    //     },
    //     Luminosity: {
    //         name: 'Luminosity',
    //     },
    // },
    MIPLDepthEffect: {
        'Shadows Repair': {
            // disableCondition: 'Amount.value = 0',
            name: 'Shadows',
        },
        Smoothness: {
            // disableCondition: 'Amount.value = 0',
        },
        Vividness: {
            // disableCondition: 'Amount.value = 0',
            name: 'Saturation',
        },
        Warmth: {
            // disableCondition: 'Amount.value = 0',
        },
    },
    MIPLDetailsEffect: {
        HighlightsProtection: {
            // disableCondition:
            //     '(Overall_Large_Detail.value = 0) && (Overall_Medium_Detail.value = 0) && (Overall_Small_Detail.value = 0)',
            name: 'Details Protection',
            skipUpdateLowResOnChange: true,
        },
        Masking: {
            // disableCondition:
            //     '(Overall_Large_Detail.value = 0) && (Overall_Medium_Detail.value = 0) && (Overall_Small_Detail.value = 0)',
            name: 'Details Masking',
            skipUpdateLowResOnChange: true,
        },
        Overall_Large_Detail: {
            name: 'Large Details',
            skipUpdateLowResOnChange: true,
        },
        Overall_Medium_Detail: {
            name: 'Medium Details',
            skipUpdateLowResOnChange: true,
        },
        Overall_Small_Detail: {
            name: 'Small Details',
            skipUpdateLowResOnChange: true,
        },
    },
    MIPLDevelopCommonEffectID: {
        Exposure: {
            name: 'Exposure',
            numberFormat: '0.00',
            visualValues: {
                visualMinValue: -5,
                visualMaxValue: 5,
                visualStep: 0.01,
            },
        },
        Contrast: {
            name: 'Smart Contrast',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        Highlights: {
            name: 'Highlights',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        Shadows: {
            name: 'Shadows',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        whiteBalance: {
            type: 'WhiteBalance',
            name: 'White Balance',
            select: WhiteBalanceOptions,
        },
        Temperature: {
            name: 'Temperature',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientTemperature}`,
        },
        Tint: {
            name: 'Tint',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientTint}`,
        },
    },
    MIPLDynamicContrastEffect: {
        Boost: {
            name: parse('Accent <sup>AI</sup>'),
            visualValues: VISUAL_DEFAULT_RANGE,
        },
    },
    MIPLAIStructureEffect: {
        Amount: {
            name: 'Amount',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        Boost: {
            name: 'Boost',
            visualValues: VISUAL_DEFAULT_RANGE,
        },
    },
    MIPLExposureEffect: {
        Exposure: {
            numberFormat: '0.00',
        },
    },
    MIPLFaceEnhancerEffect: {
        'Eyebrow Improve': {
            name: 'Improve Eyebrows',
        },
        'Teeth whitening': {
            name: 'Teeth Whitening',
        },
        TextureIris: {
            discreteValues: ['Original', 'Blue', 'Brown', 'Green', 'Grey', 'Hazel', 'Cat', 'Honey', 'Mint', 'Owl'],
            name: 'Iris',
            type: 'Combobox',
        },
    },
    MIPLFaceEnhancer_vn0_Effect: {
        'Eyebrow Improve': {
            name: 'Improve Eyebrows',
        },
        'Teeth whitening': {
            name: 'Teeth Whitening',
        },
        TextureIris: {
            discreteValues: ['Original', 'Blue', 'Brown', 'Green', 'Grey', 'Hazel', 'Cat', 'Honey', 'Mint', 'Owl'],
            name: 'Iris',
            type: 'Combobox',
        },
    },
    MIPLFadeEffect: {
        Contrast: {
            // disableCondition: 'Amount.value = 0',
        },
        Fade: {
            // disableCondition: 'Amount.value = 0',
        },
        Saturation: {
            // disableCondition: 'Amount.value = 0',
            name: 'Vividness',
        },
        ToningHue: {
            // disableCondition: '(Amount.value = 0) || (ToningRange.value = 0)',
            name: 'Hue',
        },
        ToningRange: {
            // disableCondition: 'Amount.value = 0',
            name: 'Range',
        },
        ToningSaturation: {
            // disableCondition: '(Amount.value = 0) || (ToningRange.value = 0)',
            name: 'Saturation',
        },
    },
    MIPLFogFilterEffect: {
        FogType: {
            discreteValues: ['Light Fog', 'Dark Fog'],
            historyName: 'Type',
            name: 'Type',
            type: 'Combobox',
        },
    },
    MIPLFoliageEnhanceEffect: {
        Amount: {
            name: 'Foliage Enhancer',
        },
        Shift: {
            // disableCondition: 'Amount.value = 0',
            name: 'Foliage Hue',
        },
    },
    MIPLGrainNewEffect: {
        Amount: {
            skipUpdateLowResOnChange: true,
        },
        Roughness: {
            // disableCondition: 'Amount.value = 0',
            skipUpdateLowResOnChange: true,
        },
        Size: {
            // disableCondition: 'Amount.value = 0',
            skipUpdateLowResOnChange: true,
        },
    },
    MIPLHighKeyEffect: {
        Blacks: {
            // disableCondition: 'Amount.value = 0',
        },
        Contrast: {
            // disableCondition: 'Amount.value = 0',
        },
        'Dynamic High Key': {
            // disableCondition: 'Amount.value = 0',
        },
        Glow: {
            // disableCondition: 'Amount.value = 0',
        },
        Saturation: {
            // disableCondition: 'Amount.value = 0',
        },
        'Standard High Key': {
            // disableCondition: 'Amount.value = 0',
        },
    },
    MIPLHighlightsEffect: {
        Highlights: {},
        Shadows: {},
    },
    MIPLHueShiftEffect: {
        Hue: {
            name: 'Hue Shift',
            visualValues: {
                visualMinValue: -180,
                visualMaxValue: 180,
            },
            railGradient: `${scssVars.colorGradientHue}`,
        },
    },
    // MIPLLensCorrectionEffect: {
    //     AutoLensCorrectionOn: {
    //         discreteValues: ['No', 'Yes'],
    //         discreteValuesMultiplier: 100,
    //         name: 'Auto Distortion Corrections',
    //         skipUpdateLowResOnChange: false,
    //         type: 'Checkbox',
    //     },
    //     ChromaticAberrationOn: {
    //         discreteValues: ['No', 'Yes'],
    //         discreteValuesMultiplier: 100,
    //         name: 'Auto Fix Chromatic Aberrations',
    //         skipUpdateLowResOnChange: true,
    //         type: 'Checkbox',
    //     },
    //     DefringeOn: {
    //         discreteValues: ['No', 'Yes'],
    //         discreteValuesMultiplier: 100,
    //         name: 'Auto Defringe',
    //         skipUpdateLowResOnChange: true,
    //         type: 'Checkbox',
    //     },
    //     Distortion: {
    //         name: 'Lens Distortion',
    //     },
    //     Vignette: {
    //         name: 'Devignette',
    //     },
    //     'Vignette Midpoint': {
    //         name: 'Devignette Midpoint',
    //     },
    //     Vertical: {
    //         name: 'Vertical',
    //     },
    //     Horizontal: {
    //         name: 'Horizontal',
    //     },
    //     Aspect: {
    //         name: 'Aspect',
    //     },
    // },
    MIPLLUTMappingEffect: {
        Texture: {
            name: 'Choose LUT',
            type: 'TextureButton',
        },
    },
    MIPLOrtonGlowEffect: {
        Type: {
            discreteValues: ['Soft Focus', 'Glow', 'Orton Effect', 'Orton Effect Soft'],
            name: 'Type',
            type: 'Combobox',
        },
        Brightness: {},
        Contrast: {
            // disableCondition: 'Amount.value = 0',
        },
        Warmth: {},
    },
    MIPLPhotoFilterEffect: {
        Hue: {
            // disableCondition: 'Amount.value = 0',
        },
        PreserveLuminosity: {
            // disableCondition: 'Amount.value = 0',
            discreteValues: ['No', 'Yes'],
            name: 'Preserve Luminosity',
            type: 'Checkbox',
        },
        Saturation: {
            // disableCondition: 'Amount.value = 0',
        },
    },
    MIPLRawDevelopEffect: {
        Contrast: {
            name: 'Smart Contrast',
        },
        Exposure: {
            numberFormat: '0.00',
            visualValues: {
                visualMinValue: -5,
                visualMaxValue: 5,
            },
        },
        Temperature: {
            nonlinearForRaw: true,
            nonlinearForRawDefaultValue: 6500,
            whiteBalanceStringValues: ['Daylight', 'Cloudy', 'Shade', 'Tungsten', 'Fluorescent', 'Flash'],
            whiteBalanceValues: [5500, 6500, 7500, 2850, 3450, 5000],
        },
        Tint: {
            whiteBalanceStringValues: ['Daylight', 'Cloudy', 'Shade', 'Tungsten', 'Fluorescent', 'Flash'],
            whiteBalanceValues: [0, 0, 0, 0, 0, 0],
        },
        Denoise_Luminosity: {
            name: 'Luminosity Denoise',
        },
        Denoise_Color: {
            name: 'Color Denoise',
        },
        Denoise_Boost: {
            name: 'Boost',
        },
    },
    MIPLRemoveColorCastEffect: {
        Amount: {
            name: 'Remove Color Cast',
            visualValues: VISUAL_DEFAULT_RANGE,
        },
    },
    MIPLSaturationEffect: {
        Saturation: {
            name: 'Saturation',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientSaturation}`,
        },
    },
    MIPLSegmentationEffect: {
        ShowTrimap: {
            name: 'ShowTrimap',
        },
        Object: {
            discreteValues: [
                'None',
                'Sky For Enhancer',
                'Sky Segmentation',
                'Sky In Water',
                'Water',
                'Trees',
                'Mountains',
                'Man Ground',
                'Nature Ground',
                'Transport',
                'Architecture',
                'Combined Segmentation',
                'Depth',
                'Saliency Map',
                'Hair',
                'Skin',
                'Defects',
                'Faces NN',
                'Eye segmentation',
                'Iris Points',

                'Human from segmentation',
                'Human',
                'Human Boxes',
                'Human Points',

                'Relight Human Sky Rep',
                'GCA Matting',
            ],
            type: 'Combobox',
        },
    },
    // MIPLSharpenEffect: {
    //     Amount: {
    //         name: 'Sharpen',
    //         skipUpdateLowResOnChange: true,
    //     },
    //     Masking: {
    //         disableCondition: 'Amount.value = 0',
    //         name: 'Masking',
    //         skipUpdateLowResOnChange: true,
    //     },
    //     Radius: {
    //         disableCondition: 'Amount.value = 0',
    //         name: 'Radius',
    //         skipUpdateLowResOnChange: true,
    //     },
    // },
    MIPLSkinDefectsEffect: {
        RemoveDefects: {
            discreteValues: ['No', 'Yes'],
            discreteValuesMultiplier: 10,
            name: 'AI Skin Defects Removal',
            type: 'Checkbox',
        },
    },
    MIPLSkinSoftenerNNEffect: {
        ShineRemoval: {
            name: 'Shine Removal',
        },
    },
    MIPLSkyEnhancerEffect: {
        Amount: {
            name: parse('Sky Enhancer <sup>AI</sup>'),
            visualValues: VISUAL_DEFAULT_RANGE,
            disableCondition: {
                rule: whenFieldFalsy('detection.hasSky'),
            },
        },
    },
    MIPLSkyReplacementEffect: {
        FixGaps: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Close Gaps',
        },
        Horizon: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Horizon Position',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        Relighting: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Relight Strength',
        },
        'Relighting Saturation': {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Relight Saturation',
        },
        'Relight Human': {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Relight Human',
        },
        Sigma: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Fix Details',
        },
        'Sky Grain': {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Grain',
            skipUpdateLowResOnChange: true,
        },
        'Sky Horizontal Offset': {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Horizontal Position',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        'Sky Vertical Offset': {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Vertical Position',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        SkyBlur: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Defocus',
        },
        SkyExposure: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Brightness',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        SkyFlip: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Flip',
            type: 'Checkbox',
        },
        SkyGlobal: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Global',
        },
        Temperature: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Warmth',
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        Texture: {
            name: 'Sky Selection',
            type: 'TextureButton',
        },
        Water: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Reflection Amount',
        },
        WaterBlur: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Water Blur',
        },
        White: {
            disableCondition: {
                rule: whenFieldFalsy('SkyReplacementAdjustmentLayer.MIPLSkyReplacementEffect-Texture'),
            },
            name: 'Atmospheric Haze',
        },
    },
    MIPLSmartContrastEffect: {
        Highlights: {
            name: 'Highlights Contrast',
        },
        MiddleH: {
            // disableCondition: 'Highlights.value = 0',
            name: 'Highlights Balance',
        },
        MiddleM: {
            // disableCondition: 'Midtones.value = 0',
            name: 'Midtones Balance',
        },
        MiddleS: {
            // disableCondition: 'Shadows.value = 0',
            name: 'Shadows Balance',
        },
        Midtones: {
            name: 'Midtones Contrast',
        },
        Shadows: {
            name: 'Shadows Contrast',
        },
    },
    MIPLSplitToningEffect: {
        Balance: {
            // disableCondition: 'Amount.value = 0',
        },
        HighlightsHue: {
            // disableCondition: '(Amount.value = 0) || (HighlightsSaturation.value = 0)',
            historyPrefix: 'Highlights',
            name: 'Hue',
        },
        HighlightsSaturation: {
            // disableCondition: 'Amount.value = 0',
            historyPrefix: 'Highlights',
            name: 'Saturation',
        },
        ShadowsHue: {
            // disableCondition: '(Amount.value = 0) || (ShadowsSaturation.value = 0)',
            historyPrefix: 'Shadows',
            name: 'Hue',
        },
        ShadowsSaturation: {
            // disableCondition: 'Amount.value = 0',
            historyPrefix: 'Shadows',
            name: 'Saturation',
        },
    },
    MIPLSplitWarmthEffect: {
        'Cool Colors': {
            name: 'Cool',
        },
        'Warm Colors': {
            name: 'Warm',
        },
    },
    MIPLSunLightRaysEffect: {
        X: {
            visualValues: VISUAL_DEFAULT_RANGE,
        },
        Y: {
            visualValues: VISUAL_DEFAULT_RANGE,
        },
        Look: {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
            name: 'Overall Look',
        },
        Randomize: {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
        },
        'Rays Length': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
            name: 'Sunrays Length',
        },
        'Rays Number': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
            name: 'Number of Sunrays',
        },
        'Rays Opacity': {
            name: 'Amount',
        },
        'Rays Warmth': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",

            name: 'Sunrays Warmth',
        },
        'Sun Glow Amount': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
        },
        'Sun Glow Radius': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
        },
        'Sun Penetration': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
            name: 'Penetration',
        },
        'Sun Radius': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
        },
        'Sun Warmth': {
            // disableCondition: "SELF.'Rays Opacity.value' = 0",
        },
    },
    MIPLTextureOverlay_v1_Effect: {
        Amount: {
            // disableCondition: 'Texture.hasChanges = false',
            name: 'Opacity',
        },
        Brightness: {
            // disableCondition: 'Texture.hasChanges = false',
        },
        Contrast: {
            // disableCondition: 'Texture.hasChanges = false',
        },
        'Hue Shift': {
            // disableCondition: 'Texture.hasChanges = false',

            name: 'Hue',
        },
        Saturation: {
            // disableCondition: 'Texture.hasChanges = false',
        },
        Texture: {
            name: 'Texture Selection',
            type: 'TextureButton',
        },
        UseCorners: {
            // disableCondition: 'Texture.hasChanges = false',
            name: 'Place Texture',
        },
    },
    MIPLVibranceEffect: {
        Vibrance: {
            name: 'Vibrance',
            visualValues: VISUAL_EXTENDED_RANGE,
            railGradient: `${scssVars.colorGradientVibrance}`,
        },
    },
    MIPLVignetteEffect: {
        'Offset X': {
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        'Offset Y': {
            visualValues: VISUAL_EXTENDED_RANGE,
        },
        CropMode: {
            // disableCondition: 'Amount.value = 0',
            discreteValues: ['Post-crop', 'Pre-crop'],
            name: 'Mode',
            type: 'Combobox',
        },
        Feather: {
            // disableCondition: 'Amount.value = 0',
        },
        'Inner Light': {
            // disableCondition: 'Amount.value = 0',
        },
        Roundness: {
            // disableCondition: 'Amount.value = 0',
        },
        'Vignette Size': {
            // disableCondition: 'Amount.value = 0',
            name: 'Size',
        },
    },
    MIPLVirtualFlashLightEffect: {
        Mask: {
            discreteValues: ['Strong', 'Normal', 'Loose', 'Weak'],
            type: 'Combobox',
        },
        AlphaMattingType: {
            discreteValues: ['FBA', 'GCA', 'Guided'],
            name: 'Mask Quality',
            type: 'Combobox',
        },
        Amount: {
            name: 'Brightness Near',
        },
        'Amount Far': {
            name: 'Brightness Far',
        },
        Warmth: {
            name: 'Warmth Near',
        },
        'Warmth Far Plane': {
            name: 'Warmth Far',
        },
    },
    MIPLWarmSkylight_v1_Effect: {
        Amount: {
            name: 'Golden Hour',
        },
    },
}

export type EffectsInfoType = { [key: string]: { [key: string]: ParameterConfig } }

export type ParameterConfig = {
    name?: string | JSX.Element | JSX.Element[]
    disableCondition?: {
        rule: RulesLogic<AdditionalOperation>
    }
    skipUpdateLowResOnChange?: boolean
    type?: string
    historyPrefix?: string
    historySuffix?: string
    discreteValues?: string[]
    numberFormat?: string
    historyName?: string
    discreteValuesMultiplier?: number
    whiteBalanceStringValues?: string[]
    visualValues?: {
        visualMinValue: number
        visualMaxValue: number
        visualStep?: number
    }
    nonlinearForRaw?: boolean
    whiteBalanceValues?: number[]
    nonlinearForRawDefaultValue?: number
    select?: object[]
    railGradient?: string
    hidden?: boolean
    defaultValue?: number
}
