export const bwChannels_v1_EffectMap = [
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'Apply' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'lRed' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'lYellow' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'lGreen' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'lCyan' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'lBlue' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'lViolet' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'sRed' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'sYellow' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'sGreen' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'sCyan' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'sBlue' },
    { effectId: 'MIPLBWChannels_v1_Effect', paramaterId: 'sViolet' },
]
