import '../styles/index.scss'
import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'
import { createStyleCache, theme } from '@shared/components/providers'
import { ThemeProvider } from '@mui/material'
import { getStore } from '@store/configure-store'
import { appInit } from '@store/actions'
import { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { ServiceProvider } from '@shared/components/providers/services'
import { SessionProvider } from 'next-auth/react'
import '../shared/styles/konva.scss'
import { useRouter } from 'next/router'

const { store, services } = getStore()
const cache = createStyleCache()

interface LumiApp extends AppProps {
    emotionCache?: EmotionCache
    pageProps: AppProps['pageProps'] & { session: any }
}

function MyApp({ Component, pageProps: { session, ...pageProps }, emotionCache = cache }: LumiApp) {
    const { pathname } = useRouter()

    useEffect(() => {
        if (pathname.includes('signin')) return
        store.dispatch(appInit())
        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
            window.store = store
            window.services = services
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <SessionProvider session={session}>
            <CacheProvider value={emotionCache}>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <ServiceProvider value={services}>
                            <CssBaseline />
                            <Component {...pageProps} />
                        </ServiceProvider>
                    </ThemeProvider>
                </Provider>
            </CacheProvider>
        </SessionProvider>
    )
}

export default MyApp
