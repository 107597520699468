import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'

const getStore = (state: RootState) => state.authUI
const getForgotStore = (state: RootState) => state.forgotPassword

export const getType = createSelector(getStore, state => state.type)
export const open = createSelector(getStore, state => state.open)
export const getStartSocProcess = createSelector(getStore, state => state.startSocProcess)

export const getEmail = createSelector(getForgotStore, state => state.email)
export const getPin = createSelector(getForgotStore, state => state.pin)
export const getRequiredInfo = createSelector(getEmail, getPin, (email, pin) =>
    !email || !pin ? null : { email, pin },
)
