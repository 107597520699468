type KnownFieldNames = 'wrapper'
// eslint-disable-next-line @typescript-eslint/ban-types
export type FieldName = KnownFieldNames | (string & {})
/**
 *
 * @param id Ussually is ToolId but not limited, it might be a Mipl identifier
 * @param type Field representation
 * @returns Composed string
 */
export const getFormKey = (id = '', type: FieldName): `${string}-${FieldName}` => `${id}-${type}`
