import {
    AnyAction,
    combineReducers,
    configureStore,
    createSerializableStateInvariantMiddleware,
    Middleware,
} from '@reduxjs/toolkit'
import localforage from 'localforage'
import { combineEpics, createEpicMiddleware, Epic, StateObservable } from 'redux-observable'
import { catchError } from 'rxjs/operators'
import { Observable, ReplaySubject } from 'rxjs'
import { createLogger } from 'redux-logger'
import { appReducer, RootReducer } from './store'
import { apiAdapter, Analytics } from '@shared/services'
import { store as rendererStore, actions as rendererActions, epics as rendererEpics } from '@features/renderer/store'
import { store as authSlice, epics as authEpics } from '@features/auth/store'
import { store as toolsStore, epics as toolsEpics } from '@features/toolsPanel/store'
import { store as exportStore, epics as exportEpics } from '@features/export/store'
import { store as uploadStore, epics as uploadEpics } from '@features/upload/store'
import { store as loaderLuminarStore } from '@store/store.ui'
import { processUnhandledErrors } from './actions'
import * as presetsEpics from '@features/presets/epics'
import * as appEpics from './epics'
import { Mipl } from '@shared/services/mipl'
import { merge, of } from 'rxjs'

const nonSerializableActions: string[] = [
    rendererActions.uploadImage.act.type,
    rendererActions.uploadImage.fulfilled.type,
    rendererActions.uploadImage.rejected.type,
]

export const getServices = () => {
    const fromMipl = new ReplaySubject<AnyAction>(1)
    const fromApp = new ReplaySubject<AnyAction>(1)
    const actions = new ReplaySubject<AnyAction>(1)
    const analytics = new Analytics(apiAdapter)
    return {
        apiAdapter,
        analytics,
        actions,
        mipl: new Mipl({
            dispatch: fromMipl.next.bind(fromMipl),
            listen: fromApp.subscribe.bind(fromApp),
        }),
        miplChannels: {
            miplChannel: fromMipl,
            appChannel: fromApp,
            dispatch: fromApp.next.bind(fromApp),
            listen: fromMipl.subscribe.bind(fromMipl),
        },
        localStorage: localforage.createInstance({
            driver: [localforage.INDEXEDDB],
            name: 'app-cache',
        }),
    }
}

const reducer = combineReducers({
    app: appReducer,
    [rendererStore.uiSlice.name]: rendererStore.uiSlice.reducer,
    [rendererStore.rendererSlice.name]: rendererStore.rendererSlice.reducer,
    [loaderLuminarStore.name]: loaderLuminarStore.reducer,
    [toolsStore.Panel.name]: toolsStore.Panel.reducer,
    [toolsStore.Effects.name]: toolsStore.Effects.reducer,
    [toolsStore.Textures.name]: toolsStore.Textures.reducer,
    [toolsStore.toolsState.name]: toolsStore.toolsState.reducer,
    [toolsStore.cropState.name]: toolsStore.cropState.reducer,
    [toolsStore.colorPickerState.name]: toolsStore.colorPickerState.reducer,
    [toolsStore.Edits.name]: toolsStore.Edits.reducer,
    [toolsStore.panelUISlice.name]: toolsStore.panelUISlice.reducer,
    [authSlice.authState.name]: authSlice.authState.reducer,
    [authSlice.authUIState.name]: authSlice.authUIState.reducer,
    [authSlice.forgotPassword.name]: authSlice.forgotPassword.reducer,
    [exportStore.slice.name]: exportStore.slice.reducer,
    [uploadStore.slice.name]: uploadStore.slice.reducer,
    [uploadStore.toastSlice.name]: uploadStore.toastSlice.reducer,
})

export type AppReducers = typeof reducer
export type RootState = ReturnType<AppReducers>
export type Services = ReturnType<typeof getServices>
export type AppThunk = AnyAction

const allEpics = Object.values({
    ...rendererEpics,
    ...appEpics,
    ...presetsEpics,
    ...toolsEpics,
    ...authEpics,
    ...exportEpics,
    ...uploadEpics,
})

export const getStore = () => {
    const services = getServices()
    const epicMiddleware = createEpicMiddleware<AppThunk, AppThunk, RootState, Services>({
        dependencies: services,
    })
    const middlewares: Middleware[] = [
        createSerializableStateInvariantMiddleware({
            ignoredActions: nonSerializableActions,
        }),
        epicMiddleware,
    ]
    const rootEpic: AppEpic = (action$, store$, dependencies) =>
        combineEpics(...allEpics)(action$, store$, dependencies).pipe(
            catchError((error, source) => {
                console.error(error)
                return merge(of(processUnhandledErrors(error)), source)
            }),
        )
    if (process.env.ENVIRONMENT === `test`) middlewares.splice(0, 1)
    if (process.env.ENVIRONMENT === `development`) {
        const logger = createLogger({
            diff: false,
        })

        middlewares.push(logger)
    }
    const store = configureStore({
        reducer: RootReducer(reducer),
        middleware: middlewares,
        devTools: true,
    })

    epicMiddleware.run(rootEpic)

    return {
        services,
        store,
    }
}

// Type declarations
export type AppStore = ReturnType<typeof getStore>['store']
export type AppDispatch = AppStore['dispatch']
export type AppEpic = Epic<AppThunk, AppThunk, RootState, Services>
export type EpicPayload = {
    actions$: Observable<AnyAction>
    state$: StateObservable<RootState>
    services: Services
}
